import React,{useState, useEffect} from 'react';
import '../CSS/style_6.css';

import FacebookImage from "../media/icons/facebook.png"
import InstagramImage from "../media/icons/instagram.png"
import SalIcon from '../media/icons/SAL.png';
import SolIcon from '../media/icons/SOL.png';
import {Link} from 'react-router-dom';

const Footer = ()=>{
    return(
        <>
         <footer class="section-6">
        <div class="section-6-item">
          <div 
            class="section-6-item-title"
            style={{
                fontFamily: "'Julius Sans One', sans-serif",
                fontSize: '1.5rem',
                fontWeight: 'bold'
            }}
            >
            <span>PERUCI NATURALE</span>
          </div>
          <div 
            style={{
                fontFamily: "'DM Sans', sans-serif",
                fontSize: '0.8rem',
                width: '70%'
            }}
            
          >
            <span>
              Peruci Naturale toate drepturile rezervate	&copy;
            </span>
          </div>
          <div
            style={{
                display: 'flex',
                padding: '15px 0px'
            }}
          >
          <a href="">
            <img src={FacebookImage}/>
          </a>
          <a href="" style={{marginLeft:'10px'}}>
            <img src={InstagramImage}/>
          </a>

          </div>

        </div>
        <div class="section-6-item">
          <div class="section-6-item-title">
            <span>Pagini</span>
          </div>
          <div
            style={{
                display:'flex',
                flexDirection: 'column'}}
          > 
          <a href="/" class="section-6-item-navigheaza">
            Acasa
          </a>
          <a href="/contact" class="section-6-item-navigheaza">
            Contact
          </a>          
          </div>
        </div>

        <div class="section-6-item">
          <div class="section-6-item-title">
            <span>Despre noi</span>
          </div>
          <div class="section-6-item-info">
            <p>Locatie:</p>
            <p>Strada Lascar Catargi, nr.42 Iasi</p>
          </div>
        </div>
       
        </footer>
        <LegalFooter />
        </>
       
    )
}

const LegalFooter = ()=>{
  const LegalLinks = [
      ['Politica de utilizare cookies','/politica-cookies'],
  ]
  const redirecToExternal = (to)=>{
      window.location = to
  }
  return(
      <div className='footer_model_1-politica'>
          <div className='footer_model_1-politica-left'>
              <span>© 2022 Toate drepturile rezervate perucinaturalero.ro . Powered by <a href="https://magnus-team.com/">Magnus</a></span>
          </div>
          <div className='footer_model_1-politica-right'>
              <div className='footer_model_1-politica-right-links'>
              {
                  LegalLinks.map((el)=>{
                      return (
                          <div className='footer_model_1-politica-right-links-item'>
                              <Link to={el[1]}>{el[0]}</Link>
                          </div>
                      )
                  })
              }
              </div>
              <div className='footer_model_1-politica-right-anpc'>
                  <img src={SalIcon} onClick={()=>redirecToExternal('https://anpc.ro/ce-este-sal/')}/>
                  <img src={SolIcon} onClick={()=>redirecToExternal('https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO')}/>
              </div>

          </div>
      </div>
  )
}

export default Footer;
