import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes ,
  Route,
  Link
} from "react-router-dom";

import Home from './Components/HomePage';
import CategoryPage from './Components/CategoryPage';
import ProductPage from './Components/ProductPage';
import PoliticaCookies from './Components/PoliticaCookies';

// Poze Produse
import P_1_1 from './produse/Photos/1_1.jpeg';
import P_1_2 from './produse/Photos/1_2.jpeg';
import P_1_3 from './produse/Photos/1_3.jpeg';
import P_1_4 from './produse/Photos/1_4.jpeg';
import P_1_5 from './produse/Photos/1_5.jpeg';

import P_2_1 from './produse/Photos/2_1.jpeg';
import P_2_2 from './produse/Photos/2_2.jpeg';
import P_2_3 from './produse/Photos/2_3.jpeg';
import P_2_4 from './produse/Photos/2_4.jpeg';
import P_2_5 from './produse/Photos/2_5.jpeg';

import P_3_1 from './produse/Photos/3_1.jpeg';
import P_3_2 from './produse/Photos/3_2.jpeg';
import P_3_3 from './produse/Photos/3_3.jpeg';

import P_4_1 from './produse/Photos/4_1.jpeg';
import P_4_2 from './produse/Photos/4_2.jpeg';
import P_4_3 from './produse/Photos/4_3.jpeg';
import P_4_4 from './produse/Photos/4_4.jpeg';
import P_4_5 from './produse/Photos/4_5.jpeg';
import P_4_6 from './produse/Photos/4_6.jpeg';
import P_4_7 from './produse/Photos/4_7.jpeg';

//Flavours
import auburnMix from './produse/Flavours/auburn-mix.jpg';
import auburnRooted from './produse/Flavours/auburn-rooted.jpg';
import aubergineMix from './produse/Flavours/aubergine-mix.jpg';
import bernsteinMix from './produse/Flavours/bernstein-mix.jpg';
import caramelMix from './produse/Flavours/caramel-mix.jpg';
import champagneRooted from './produse/Flavours/champagne-rooted.jpg';
import chocolateMix from './produse/Flavours/chocolate-mix.jpg';
import espressoMix from './produse/Flavours/espresso-mix.jpg';
import ebonyBlack from './produse/Flavours/ebony-black.jpg';
import moccaRooted from './produse/Flavours/mocca-rooted.jpg';
import saltPepperMix from './produse/Flavours/salt-pepper-mix.jpg';
import snowMix from './produse/Flavours/snow-mix.jpg';
import sandMix from './produse/Flavours/sand-mix.jpg';
import darkChocholateMix from './produse/Flavours/darkchocolate-mix.jpg';
import hotChocholateMix from './produse/Flavours/hotchocolate-mix.jpg';
import platinblondeMix from './produse/Flavours/platinblonde-mix.jpg';
import sandRooted from './produse/Flavours/sand-rooted.jpg';
import safranRooted from './produse/Flavours/safran-rooted.jpg';
import espressTipped from './produse/Flavours/espresso-tipped.jpg';
import coffebrownMix from './produse/Flavours/coffeebrown-mix.jpg';
import lightcaramelMix from './produse/Flavours/lightcaramel-mix.jpg';
import sandmultiMix from './produse/Flavours/sandmulti-mix.jpg';
import chocholateRooted from './produse/Flavours/chocolate-rooted.jpg';
import hazelnutMix from './produse/Flavours/hazelnut-mix.jpg';
import bernsteinRooted from './produse/Flavours/bernstein-rooted.jpg';
import gingerRooted from './produse/Flavours/ginger-rooted.jpg';
import lightchampagneRooted from './produse/Flavours/lightchampagne-rooted.jpg';
import lightMocaMix from './produse/Flavours/lightmocca-mix.jpg';
import lighthoneyRooted from './produse/Flavours/lighthoney-rooted.jpg';
import sandyblondeRooted from './produse/Flavours/sandyblonde-rooted.jpg';
import safranredRooted from './produse/Flavours/safranred-rooted.jpg';

import Contact from './Components/Contact';



export const produse = {
  "Femei":{
    "Peruci Coafuri Clasice":[
      {
        id: 0,
        title: 'Peruca par sintetic scurt, ondulat, pentru femei, Louise,',
        desc: 'Daca vrei sa fii mereu sic si eleganta si nu esti adepta schimbarilor de dragul schimbarii alege aceasta peruca cu par ondulat realizata din fibre atent selectionate, pe un material moale si usor. Nu trebuie neaparat sa suferi la frumusete. Ne-am gandit special la tine si vei vedea ca este usor de purtat. Nu-ti va tine de cald, este usoara si delicata, te vei simti confortabil. Pregateste-te pentru un nou inceput.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Louise_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Louise_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Louise_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Louise_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Louise_1-ink.jpeg'
        ]
      },
      {
        id: 1,
        title: 'Peruca bob din par sintetic, Change',
        desc: 'Doar cu un gest simplu poți sa aduci primăvara in viața ta. Alege o peruca ușoară, delicată, modernă si fă diferența! Senzația unei schimbări totale este la un click distanță!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ebony-black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Change_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Change_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Change_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Change_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Change_1-ink.jpeg'
        ]
      },
      {
        id: 2,
        title: 'Peruca eleganta cu par scurt pentru femei, Veronica',
        desc: 'Un look fresh si luminos, declicat si rafinat, imbina in acest model de peruca culori in moda dar si nuantele consacrate',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-tipped-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/coffeebrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/veronica_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/veronica_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/veronica_3.jpg'
        ]
      },
      {
        id: 3,
        title: 'Peruca bob scurt par drept Vista, Ellen Wille',
        desc: 'Adauga un strop de emotie in viata! Fa schimbarea la care te gandeai de mult. Perucile sunt ideale pentru tine daca nu esti pregatita sa faci o schimbare permanenta. Esti nehotarata, nu ai vrea sa renunti la parul tau frumos lung dar arati perfect cu tunsoare bob si anul asta se mai si poarta?! Ai ajuns unde trebuie. Avem un raspuns pentru tine: Peruca model Vista',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Vista_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Vista_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Vista_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Vista_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Vista_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Vista_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-crowm.jpg'
        ]
      },
      {
        id: 4,
        title: 'Peruca par sintetic pentru femei, Nancy',
        desc: 'Modelul de peruca creata Nancy este manufacturat printr-o tehnologie germana foarte avansata, numai din fibre atent selectionate. Incearca si tu. Cu un accesoriu de efect vei avea tinutas gata mai rapid si vei castiga timp alaturi de prieteni.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightespresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/nancy_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nancy_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nancy_3.jpg'
        ]
      },
      {
        id: 5,
        title: 'Peruca par lung Impress',
        desc: 'Armele secrete ale vedetelor sunt acum la dispozitia tuturor! Vino sa probezi accesoriile de ultima ora care pana acum au fost rezervate doar celebritatilor! Perucile au coborat de pe podium direct in magazine.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ebony-black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/impress_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/impress_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/impress_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      },
      {
        id: 6,
        title: 'Peruca par scurt Cool',
        desc: 'Aceasta peruca propune o linie clasica reinterpretata.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mango-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cool_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cool_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cool_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cool_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cool_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      },
      {
        id: 7,
        title: 'Peruca par scurt usor ondulat Apart Hi',
        desc: 'O coafura clasica pentru doamnele sofisticate care ignora trecerea timpului. Timpul tau este pretios, fii frumoasa cu un simplu click.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightespresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/coffeebrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksmoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/apart_hi_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/apart_hi_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/apart_hi_3.jpg'
        ]
      },
      {
        id: 8,
        title: 'Peruca clasica cu par cret, City',
        desc: 'Pentru un stil elegant si sofisticat, alege o peruca cu fruntea libera dar, dupa o anumita varsta, bretonul revine in atentie. Cu acest model de peruca cu par cret poti avea ambele optiuni.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/city_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/city_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/city_3.jpg'
        ]
      },
      {
        id: 9,
        title: 'Peruca clasica marime mare, City Large',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',   
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/city_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/city_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/city_3_01.jpg'
        ]
      },
      {
        id: 10,
        title: 'Peruca dama din par sintetic drept, bob, Charlotte, Ellen Wille',
        desc: 'Energizeaza-te! E mult mai usor sa o faci acum. Casa germana Ellen Wille – cel mai mare producator de peruci din Europa, a adus special pentru tine o colectie de peruci frumoase, usoare, accesibile si in trend. O peruca de calitate presupune o schimbare mica cu rezultate foarte mari. Incearca!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Charlotte_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Charlotte_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Charlotte_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Charlotte_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Charlotte_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-crowm.jpg'
        ]
      },
      {
        id: 11,
        title: 'Peruca din par sintetic scurt, clasic Lucia',
        desc: "Peruca din par sintetic de calitate superioara, cu par scurt usor ondulat, coafura clasica, prevazuta cu tul frontal transparent. Sa ai clasa nu are legatura obligatorie cu originea sociala. Este mai mult o chestiune de personalitate si alegeri personale. Fa alegerea corecta. Alege sa ai stil cu aceasta peruca clasica",
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucia_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucia_2_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucia_3.jpg'
        ]
      },
      {
        id: 12,
        title: 'Peruca sintetica femei cu par lung, brand Ellen Wille, Carrie',
        desc: `Renumitul designer de peruci Ellen Wille a reusit prin stilul european multi-premiat sa domine chiar si piata americana. Colectia Perucci este o colectie moderna care da tonul in domeniu, modelele care o compun fiind vazute ca niste peruci de ultima generatie. Unul din modelele apreciate este Carrie.
        Cu un par drept si frumos, cu un breton stratificat, varfurile usor evazate fac ca aceasta peruca sa fie adorabila. Imprima un look adolescentin. Verificati parametri de calitate sa va asigurati ca este potrivita si pentru dvs (monofilamentul partial situat chiar in crestetul capului simuleaza cresterea naturala a parului, tresele moi pe care este cusut parul permit aerului sa ajunga la nivelul pielii si sa o aeriseasca, peruca este foarte usoara (max 150 gr), la nivelul perciunilor si frunte veti gasi o dublura de catifea). Acest produs poate fi al tau chiar maine. Suna si verifica disponibilitatea in stoc a perucii cu par lung Carrie. Modelul este expus in fotografie pe culoarea chocolate.
        Pentru a fi de neinlocuit, trebuie sa fii mereu diferit” Coco Chanel. De ce nu?`,
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ebony-black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/esspreso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Carrie_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Carrie_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Carrie_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Carrie_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Carrie_1-ink.jpeg'
        ]
      },
      {
        id: 13,
        title: 'Peruca par scurt sintetic nedetectabil Tab, Ellen Wille',
        desc: `Daca ai hotarat peste noapte ca parul lung este plictsitor, obositor, whatever, si parca ai incerca o schimbare dramatica, ceva scurt, ia in calcul forma fetei pentru a aprecia daca faci sau nu o miscare inteligenta. Sunt multe mituri despre parul scurt (ca e prea masculin, ca nu se potriveste unei tinute elegante etc) dar e suficient sa te uiti in jur sa iti dai seama ca sunt false. Persoanele cu fata rotunda sau ovala sunt mai norocoase si pot experimenta mai multe stiluri, nefiind valabile „restrictiile” impuse de maxilarul sau barbia ascutita. Cu un par mai scurt, fiecare trasatura va iesi mai repede in evidenta.`,
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotaubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Tab_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_6-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      },
      {
        id: 14,
        title: 'Peruca dama marime mica, Lucia Small',
        desc: `Peruca pentru femei cu par scurt, usor ondulat, coafura clasica, disponibil in masura mica.

        Va rugam sa consultati articolul de pe site dedicat acestui subiect.`,
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucia_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucia_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucia_3_01.jpg'
        ]
      },
      {
        id: 15,
        title: 'Peruca monofilament partial Bari',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ashgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_black.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_coffeebrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightchampagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_mahogany.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_BariMono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_BariMono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_BariMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_BariMono_3.jpg'
        ]
      },
      {
        id: 16,
        title: 'Peruca ondulata cu par scurt Viva Plus',
        desc: `„Eleganţa trebuie sa fie o combinaţie potrivita de distincţie, naturaleţe, grija şi simplitate. Fara acestea, sunt de parere ca nu exista eleganţa. Numai pretenţiozitate. Eleganţa nu este dependenta de bani. Din cele patru lucruri pe care le-am menţionat mai inainte, cea mai importanta este grija. Ai grija cum iţi alegi hainele. Ai grija cum le porţi. Ai grija cum le intreţii. [Christian Dior]”

        Peruca cu par scurt din fibra sintetica de calitate, ultima generatie, este de o incredibila naturalete.
        
        Parul ei este moale , coafura este excelent realizata.
        
        Culorile sunt pline de viata. Foarte frumoa realizata aceast model de peruca.
        
        Peruca nedetectabila, cu tul frontal transparent.`,
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/coffeebrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/viva_plus_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/viva_plus_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/viva_plus_3.jpg'
        ]
      },
      {
        id: 17,
        title: 'Peruca ondulata Alive',
        desc: `Perucile din par sintetic marca Ellen Wille sunt de cea mai buna calitate. Obtinute printr-o tehnologie de ultima ora, ele simuleaza stralucirea naturala a parului sanatos, diferenta intre fibra naturala de par si parul sintetic neputand fi remarcata cu ochiul liber. Peruca cu par lung Alive are incorporat efectul de “ondulat cu perie” in structura firului de par, mentinandu-si coafura si dupa spalare. Parul poate fi aranjat cu mana sau cu ajutorul unui pieptene cu dinti rari, lungi, cu varful bont pentru a nu penetra montura. Acest model de peruca are monofilament partial pe carare, ceea ce presupune ca pe carare parul sa fie inserat fir cu fir intr-un tul transparent prin care se poate observa pielea, obtinand astfel un efect foarte realist.`,
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/alive_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alive_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alive_3_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-fara-tul.jpg'
        ]
      },
      {
        id: 18,
        title: 'Planet Hi',
        desc: 'E atatr de usor sa fii mai frumoasa! O peruca este un accesoriu secret pentru fiecare zi. Cu el castigi timp iar timpul tau e foooarte scump.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/planet_hi_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/planet_hi_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/planet_hi_3.jpg'
        ]
      },
      {
        id: 19,
        title: 'Peruca import Germania Zizi',
        desc: 'Apropos, stiai ca o frizura cu par scurt ne alungeste vizual linia gatului. Nu ai curaj sa te tunzi? E absolut ok. Alege o peruca sa te convingi si, cand te vei simti pregatita, poti sa faci si pasul urmator.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightespresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/coffeebrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/zizi_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/zizi_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/zizi_3_01.jpg'
        ]
      },
      {
        id: 20,
        title: 'Peruca cu volum pentru femei Alexis',
        desc: 'Dublu click si aceasta peruca poate fi a ta. E atat de simplu sa fii frumoasa! O echipa intreaga de specialisti s-a gandit la asta. Convinge-te singura!',    flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightespresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/alexis_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alexis_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alexis_3.jpg'
        ]
      },
      {
        id: 21,
        title: 'Peruca scurta calitate Jolly',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cosmoblack-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mangored-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/jolly_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jolly_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jolly_3.jpg'
        ]
      },
      {
        id: 22,
        title: 'Peruca sintetica cu par scurt femei Carol',
        desc: 'Stii ce se spune despre parul scurt, nu? Te intinereste, da, e adevarat. Daca eziti intre culori, nimic mai simplu, vino direct in magazin sa probam impreuna',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightespresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/carol_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/carol_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/carol_3_01.jpg'
        ]
      },
      {
        id: 23,
        title: 'Peruca cu par scurt, sintetic, pentru femei Club 10',
        desc: 'Volum si energie! Toate intr-o cutie! Peruca cu breton disponbila in nuante minunate',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/club_10_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/club_10_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/club_10_3.jpg'
        ]
      },
      {
        id: 24,
        title: 'Peruca eleganta cu frunte discreta Garda',
        desc: 'Avantajele peruci par sintetic de calitate:Incredibil de ușor de purtat si bine aerisitaGata de purtare– Perucile din păr sintetic sunt precoafate și își păstrează aspectul și forma originale după spalareCulori vibrante, nuanțe delicatePreț rezonabil comparativ cu multiple manopere de spalare, vopsire, tundere , coafare la salonO gamă largă și diversificată de modele coafate ca la salonÎntreținere redusă cu produse speciale pentru o purtare indelungataOpțiuni personalizateAtenție: părul sintetic nu tolerează căldura feonului sau a placii de indreptat .',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_coffeebrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_greymulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Garda_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Garda_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Garda_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Garda_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Garda_1.jpg'
        ]
      },
      {
        id: 25,
        title: 'Peruca par scurt Mare',
        desc: 'Peruca cu tul frontal si monofilament partial in crestet. Perucile din colectia Modixx fac parte din renumitul brand ELLEN WILLE , așa că puteți fi sigur că ati cumpărat o perucă superioară, de calitate. Designerii nostri au creat această colecție pentru a fi o serie de premieră, confortul purtării perucii tale fiind de o importanță capitală. Perucile  Ellen Wille sunt inspirate din cele mai frumoase frizuri din Germania.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_creamblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_greymulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_peppergrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_platinblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_tizianred.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Mare_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Mare_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Mare_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Mare_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Mare_1.jpg'
        ]
      },
      {
        id: 26,
        title: 'Peruca Raquel Welch din par sintetic, tunsa in scari cu volum Aspen',
        desc: 'O tunsoare plina de volum degaja multa energie si promite o zi minunata. Nu lasa pe nimeni sa iti strice ziua! Bucura-te de noua ta coafura in fiecare zi!. Modelul acesta de peruca are o baza din monofilament partial. Fibra sinteica de ultima generatie, disponibila intr-o gama generoasa de culori, faca din aceasta peruca un bestseller.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/aspen_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-crowm.jpg'
        ]
      },
      {
        id: 27,
        title: 'Peruca dama par scurt nedetectabila Ivy',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotcinnamon-mix.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ivy_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ivy_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ivy_3.jpg'
        ]
      },
      {
        id: 28,
        title: 'Peruca din par sintetic scurt Gold',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/gold_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/gold_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/gold_3.jpg'
        ]
      },
      {
        id: 29,
        title: 'Peruca dama par scurt Stop Hi Tec',
        desc: 'Incearca o frizura cu par scurt si te vei simti mai energica, mai tanara, mai indrazneata. Nu uita acest sentiment si revino pe site-ul nostru de cate ori vrei sa te rasfeti cu o peruca',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/stop_hi_tec_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stop_hi_tec_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stop_hi_tec_3.jpg'
        ]
      },
      {
        id: 30,
        title: 'Beam',
        desc: 'Un look so posh, o aparitie fina pentru toate serile calde de vara.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-lighted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/beam_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/beam_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/beam_3.jpg'
        ]
      },
      {
        id: 31,
        title: 'Peruca nedetectabila cu par scurt Foxy',
        desc: 'O sugestie pentru doamnele carora nu le e frica sa experimenteze ceva nou este tunsoarea pixie. Ce zici? Am avut dreptate?',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/foxy_2_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/foxy_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/foxy_3.jpg'
        ]
      },
      {
        id: 32,
        title: 'Peruca de calitate Spring Hi',
        desc: 'Peruca Spring Hi este dintr-o fibră sintetică de calitate, care își pastrează forma și culoarea (nu necesită coafare sau vopsire), pare identică cu părul natural și este disponibilă în mai multe culori, inclusiv  în nuanțe frumoase de gri.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/spring_hi_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spring_hi_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spring_hi_3.jpg'
        ]
      },
      {
        id: 33,
        title: 'Peruca din par sintetic, ondulat  pentru femei, Daily',
        desc: 'Ziua buna se cunoaste de dimineata asa ca nu mai pierde timp in fata oglizii. Cumpara peruca Daily daca vrei ca aranjatul sa dureze cu adevarat ‘doar doua minute, dragul meu!’',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/daily_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/daily_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/daily_3.jpg'
        ]
      },
      {
        id: 34,
        title: 'Peruca din par sintetic scurt Ginger',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/coffeebrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger_1_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger_3_01.jpg'
        ]
      },
      {
        id: 35,
        title: 'Naomi',
        desc: 'Nu ai nevoie de ocazii ca sa te rasfeti. Alege sa fii cocheta in fiecare zi, seducatoare si irezistibila. O peruca cu par lung poate face minuni in viata ta de cuplu',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/missing-picture-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mangored-rooted.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/naomi_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/naomi_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/naomi_3.jpg'
        ]
      },
      {
        id: 36,
        title: 'Peruca ondulata cu par mediu, marime mare, Daily Large',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/daily_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/daily_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/daily_3_01.jpg'
        ]
      },
      {
        id: 37,
        title: 'Peruca clasica Lina',
        desc: 'Perucile de la Ellen Wille sunt practice si comode. Uitati de orele petrecute la salon si de incercarile nereusite!! Fara surpize!  Parul dorit in culoarea visata este la indemana.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Lina_Small_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Lina_Small_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Lina_Small_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Lina_Small_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Lina_Small_2.jpg'
        ]
      },
      {
        id: 38,
        title: 'Peruca cu par scurt, sintetic pentru femei, nedetectabila Peru',
        desc: 'O frizura îngrijită, linii curate ce conturează un profil delicat. Doar așa ochii tai frumoși vor ieși in evidenta! Alege sa ignori trendurile si fii tu însăți! Știi ce se spune in domeniu: Less is More!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/peru_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/peru.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      },
      {
        id: 39,
        title: 'Pam Hi Tec',
        desc: 'Aceasta peruca reprezinta o piesa de rezistenta a acestei colectii. O peruca cu par drept, in scari, fin si sanatos, reaalizata din fibre atent selectionate.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/pam_hi_tec_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pam_hi_tec_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pam_hi_tec_3.jpg'
        ]
      },
      {
        id: 40,
        title: 'Peruca dama cu par scurt  Risk',
        desc: 'Daca vrei sa faci o schimbare radicala, iti propunem acest stil minimalist. Par super scurt, ai curajul sa fii cool. Nu vei regreta.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_2.jpg'
        ]
      },
      {
        id: 41,
        title: 'Peruca par scurt coafura clasica Villana',
        desc: 'Peruca frumoasa disponibila in culori calde si moderne, pentru toate gusturile, indiferent de varsta. Indiferent de sezon, accesorizeaza tinuta cu o peruca cu par scurt, coafura clasica care ofera oricarui profil distinctie si eleganta. Peruca din fibra sintetica de ultima generatie tehnologie Germania isi va pastra coafura si culoarea aleasa in timp. Peruca este discreta pentru ca are are tul frontal. Peruca are monofilament partial si acest detaliu creeaza efectul ca parul pare sa creasca direct din piele, exact ca in cazul parului natural.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_creamblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Villana_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Villana_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Villana_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Villana_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Villana_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Villana_1.jpg'
        ]
      },
      {
        id: 42,
        title: 'Peruca comoda cu par scurt Sabbia',
        desc: 'Daca vrei sa fii mereu sic si eleganta si nu esti adepta schimbarilor de dragul schimbarii, alege aceasta peruca cu par scurt usor bufant realizata din fibre atent selectionate, pe un material moale si usor. Nu trebuie neaparat sa suferi la frumusete. Ne-am gandit special la tine si vei vedea ca este usor de purtat. Nu-ti va tine de cald, este usoara si delicata, te vei simti confortabil. Pregateste-te pentru un nou inceput. Peruca sintetica din monofilament in crestet cu tul frontal',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolatemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightchampagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_mahogany.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_nutmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_peppergrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_plumred.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Sabbia_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Sabbia_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Sabbia_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Sabbia_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Sabbia_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Sabbia_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Sabbia_1.jpg'
        ]
      },
      {
        id: 43,
        title: 'Blues',
        desc: 'Cum ar fi sa arati tot timpul ca ‘scoasa din cutie’? O coafura impecabila, culori vibrante si totul doar cu un singur click… Alege!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cosmoblack-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotespresso-mix.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkcherry-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mangored-rooted.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/blues.jpg'
        ]
      },
      {
        id: 44,
        title: 'Peruca bob Flirt',
        desc: 'O peruca bob foarte bine realizata, o coafura in trend, si gata, ai accesoriul perfect.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranbrown-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/flirt_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flirt_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flirt_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      },
      {
        id: 45,
        title: 'Casino More',
        desc: 'Daca nu esti pregatita sa renunti la parul lung, poti sa faci tranzitia cu un par usor in scari cu breton amplu care sigur iti va flata trasaturile. Peruca par mediu Casino More este unul dintre cele mai bine cotate',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/casino_more_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/casino_more_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/casino_more_3.jpg'
        ]
      },
      {
        id: 46,
        title: 'Limit',
        desc: 'Brandul Ellen Wille pune pret in mod special pe calitatea modelelor propuse. Se gandeste la confortul si la frumusetea ta. Tu de ce nu ai face-o?! Peruca par mediu Limit iti va veni bine cu siguranta',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/limit_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/limit_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/limit_3.jpg'
        ]
      },
      {
        id: 47,
        title: 'Peruca creata Lola more',
        desc: 'Parul lung de mult visat il poti avea acum. Surprinde-ti partenerul cu noua ta achizitie si fii cat mai sexy.Nu uita ca fetele buine ajung in rai iar fetele rele ajung unde vor….',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotcognac-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lola_more_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lola_more_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lola_more_1.jpg'
        ]
      },
      {
        id: 48,
        title: 'Peruca femei cu par drept, tuns in scari Michigan',
        desc: '– Tunsoarea in scări revine in forță in 2020. Fii o divă, trăiește din plin si nu te vei pierde niciodată in mulțime!.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown_mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/michigan_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/michigan_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/EXTENDED-LACE-MONOPART-WEFTED.jpg'
        ]
      },
      {
        id: 49,
        title: 'Peruca Napoli Soft cu par scurt',
        desc: 'Colectia Modixx de la Ellen Wille include unele dintre peruci de cea mai înaltă calitate de pe piața europeană. Folosind tehnologie modernă în construcție și modele care prezintă cele mai recente tendințe de modă, fiecare perucă Ellen Wille se distinge prin cel mai înalt nivel de calitate și confort. Perucile Ellen Wille sunt potrivite purtătorilor de peruci care doresc un nou accesoriu de modă și pentru doamnele care au nevoie de o perucă din motive medicale. Ellen Wille este adesea menționată drept liderul pieței europene în peruci și articole de păr, deoarece culoarea cu radacini usor inchise, modelele discrete cu tul din dantelă sunt naturale, frumoase și confortabile. Aflați de ce ne concentrăm pe colecția de peruci Ellen Wille cu unul dintre modelele noastre preferate : Peruca Napoli Soft.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolatemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightchampagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sunnyblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NapoliSoft_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NapoliSoft_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NapoliSoft_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NapoliSoft_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NapoliSoft_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NapoliSoft_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NapoliSoft_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NapoliSoft_7.jpg'
        ]
      },
      {
        id: 50,
        title: 'Peruca cu par lung ondulat Raquel Welch, L.A. Mono',
        desc: 'Nu te-ai obișnuit încă sa fii răsfățată de cei dragi? Acum e timpul! Cât timp părul tău arata minunat, restul sunt amănunte…',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/l_a_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/LACE-WEFTED-MONO-PART.jpg'
        ]
      },
      {
        id: 51,
        title: 'Peruca par lung drept Cher',
        desc: 'Bretonul cucereste toate podiumurile internationale. Fii in moda cu aceasta peruca cu par lung si renunta la inhibitii. Fibra rezistenta termic permite schimbari de look. Grozav! O singura peruca, multe optiuni de coafare',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cher_futura_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cher_futura_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cher_futura_3.jpg'
        ]
      },
      {
        id: 52,
        title: 'Peruca cu par scurt pentru femei Risk Sensitive',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_sensitive_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_sensitive_2.jpg'
        ]
      },
      {
        id: 53,
        title: 'Lucky Hi',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucky_hi_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucky_hi_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucky_hi_3.jpg'
        ]
      },
      {
        id: 54,
        title: 'Peruca sintetica dama cu par ondulat Elenora Comfort',
        desc: 'Invata sa te alinti. Ce e rau in a te simti cu mult mai bine?! Peruca realizata integral manual, parul este inserat fir cu fir intr-o calota moale',      
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/elenora_comfort_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/elenora_comfort_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/elenora_comfort_3.jpg'
        ]
      },
      {
        id: 55,
        title: 'Young Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/young_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/young_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/young_mono_3.jpg'
        ]
      },
      {
        id: 56,
        title: 'Peruca confectionata manual cu par scurt Aurora Comfort',
        desc: 'Obisnuieste-te sa fii in centrul atentiei! Cu aceasta peruca viata pare mai frumoasa si inevitabilele necazuri par …. mult mai mici! De cand nu te-ai mai gandit doar la tine?',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/coffeebrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/aurora_comfort_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aurora_comfort_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aurora_comfort_3.jpg'
        ]
      },
      {
        id: 57,
        title: 'Peruca par ondulat eleganta Grace',
        desc: 'Fii tu cea care dicteaza moda! Creeaza curentul si lasa-te urmata de cei care te admira. Diponibila in combinatii de culori extrem de vii sau foarte naturale, cu efecte spectaculoase, aceasta peruca propune coafura wavy, stilul de pe buzele tuturor',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/grace_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/grace_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/grace_3.jpg'
        ]
      },
      {
        id: 58,
        title: 'Peruca bob cu breton Monza',
        desc: 'Perucile bob de la binecunoscutul brand Ellen Wille, un brand dedicat inspirării femeilor din întreaga lume cu colecțiile lor de peruci și stilurile lor moderne. Colecția de peruci Modixx prezintă un stil fără efort pentru un aspect de zi cu zi, toate perucile noastre emanând un aspect frumos natural prin tonuri gradate și culori înrădăcinate care reproduc aspectul creșterii naturale a părului. Răsfoiți prin opțiunile minunate de culoare, stiluri și lungimi pentru a găsi o perucă care vi se potrivește. Deoarece aceste peruci au fost create pentru purtarea zilnică, întreaga colecție este minunat de ușoară și confortabilă. Indiferent dacă doriți un model in scari  sau poate ceva mai lung, Colecția Modixx are câteva opțiuni de stil minunate. Peruca bob Monza are un breton care poate sa ascunda anumite imperfectiuni si unde mai pui ca bretonul este si in moda. Peruca bob lung din par sintetic prezinta pentru prima oara niste culori ombre extrem de reusite. Vino sa le vezi in magazin. Peruca are tul frontal din dantela pentru perosanele care vor sa isi lase si fruntea libera. Indrazniti, doamnelor, indrazniti!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_rubyred.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_6.jpg'
        ]
      },
      {
        id: 59,
        title: 'Peruca bob fara breton Elite',
        desc: 'Rafinament si eleganta -macestea sunt doar doua cuvinte care vin in minte cand te gandesti la peruca Elite. Personalitatea si charisma radiaza din interior si parul frumos se asaza ca o aura. Esti frumoasa ca un inger',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_3.jpg'
        ]
      },
      {
        id: 60,
        title: 'Peruca bob scurt pentru femei din par sintetic, Cape Mono',
        desc: 'Daca nu ai curajul sa te tunzi mai scurt dar vrei o frizura cu care sa mergi la sigur, un aspect ingrijit, cu linii curate care incadreaza fata ca o rama de tablou, peruca bob este o solutie. Feminina si rafinata, coafura bob avantajeaza un profil sobru, elegant. Sandyblonde rooted este culoarea aleasa in fotografie, dar',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cape_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cape_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-carare.jpg'
        ]
      },
      {
        id: 61,
        title: 'Code Mono',
        desc: 'Feminina si eleganta, aceasta peruca te va aseza in centrul atentiei. Usor asimetrica, breton franjurat, linii simple, par super drept, efect placa',     
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/code_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/code_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/code_mono_3.jpg'
        ]
      },
      {
        id: 62,
        title: 'Peruca dama par lung Glamour Mono',
        desc: 'Tocurile te ridica, cultura te inalţa, eleganţa te transforma. [Kathleen Tessaro]',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/glamour_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/glamour_mono_2.jpg'
        ]
      },
      {
        id: 63,
        title: 'Elite small',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_3_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_2_01.jpg'
        ]
      },
      {
        id: 64,
        title: 'Peruca Raquel Welch, manuala, model Reno Hi Comfort',
        desc: '– Într-o lume in continua mișcare, alege sa fi neschimbata. Naturală si rafinată, aceasta coafura te protejează de privirile indiscrete. Perucă nedetectabila, părul este inserat fir cu fir manual într-o montură confortabilă, fără trese incomode.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/reno_hi_comfort_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/reno_hi_comfort_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/reno_hi_comfort_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/EXT-LACE-MONO-WEFT.jpg'
        ]
      },
      {
        id: 65,
        title: 'Peruca manuala Love Comfort',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/love_comfort_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/love_comfort_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/love_comfort_3.jpg'
        ]
      },
      {
        id: 66,
        title: 'Peruca delicata 100% manual Savona Soft',
        desc: 'Cel mai accesibil lux realizat manual! Perucile 100% lucrate manual ofera naturalete maxima. Fiecare fir de păr de pe coroana este introdus individual pe o plasă de țesătură care pare a fi invizibilă atunci când este plasată pe piele. Cealaltă parte a părului este împletită pe tul fin. Ușoare ca o pană, cu un nivel uimitor de confort, aceste peruci aproape că nu se pot distinge de părul purtătorului.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivorygrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SavonaSoft_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SavonaSoft_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SavonaSoft_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SavonaSoft_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SavonaSoft_1.jpg'
        ]
      },
      {
        id: 67,
        title: 'Peruca sintetica dama cu par scurt, Island Mono, Raquel Welch',
        desc: 'Un păr tuns scurt, băiețește este foarte practic si comod mai ales in zilele calde de vara. Iar iarna, o peruca scurta va rezista mult mai bine întrucât nu este expusa electrizării prin frecarea de gulerele înalte sau de fulare. Atunci când comoditatea este pe primul plan, alege sa te joci cu nuanțele in degradeuri sau cu un mix de tonuri opuse si vei obține un par vesel care iți va lumina toata ținuta. O peruca cu par scurt este ideala pentru femeile active, mereu in acțiune.', 
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearlblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/island_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/island_mono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/LACE-WEFTED-MONO-PART.jpg'
        ]
      },
      {
        id: 68,
        title: 'Sue Mono',
        desc: 'O tunsoare bob, o atitudine french, o imagine impecabila. Peruca bob este deja o alegere sigura, clasica. Calitatea o recomanda',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/sue_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sue_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sue_mono_4.jpg'
        ]
      },
      {
        id: 69,
        title: 'Peruca manuala cu par scurt Risk Comfort',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_comfort_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_comfort_3.jpg'
        ]
      },
      {
        id: 70,
        title: 'Miley small Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightespresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/miley_small_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/miley_small_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/miley_small_3.jpg'
        ]
      },
      {
        id: 71,
        title: 'Peruca monofilament Sabbia Soft',
        desc: 'Draga mea, sa iti fac o confidenta: ai o personalitate minunata. Dar nu personalitatea se vede de la distanta!!! Alege o peruca care sa te reprezinte pe tine, cea care esti acum. Ai ocazie sa faci ce nu a facut natura: sa alegi ceva pe gustul tau.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ashgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_creamblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SabbiaSoft_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SabbiaSoft_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SabbiaSoft_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SabbiaSoft_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SabbiaSoft_1.jpg'
        ]
      },
      {
        id: 72,
        title: 'Peruca din par sintetic scurt Zizi Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightespresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksmoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/zizi_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/zizi_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/zizi_3.jpg'
        ]
      },
      {
        id: 73,
        title: 'Peruca par scurt clasic Modena Mono',
        desc: 'Peruca Modena Mono face parte din popularul brand Ellen Wille, așa că puteți fi siguri că ati achizitionat o perucă superioară, de calitate. Colecția Modixx este concepută ținând cont de situația de zi cu zi a frumuseții naturale. Gama extinsă are o varietate de amestecuri de tonuri încorporate în noile lor stiluri uimitoare. Aceste look-uri inspirate de salon ajută la transformarea instantanee a ta cu stil. Peruca Modena Mono reprezinta un stil practic indiferent de anotimp intr-un finisaj superior. Peruca monofilament este o peruca bine aerisita si usoara , delicata pe piele în si extrem de realista. Ea arata natural fără efort. Perucile noastre sunt realizate de designeri  și au o tehnologie de ultimă oră pentru peruci.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ashgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivorygrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_white.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ModenaMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ModenaMono_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ModenaMono_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ModenaMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ModenaMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ModenaMono_1.jpg'
        ]
      },
      {
        id: 74,
        title: 'Peruca Germania par scurt Alba Comfort',
        desc: 'Frumusete clasica si eleganta indiferent de ocazie. O peruca pentru o adevarata doamna. Aceasta peruca este prevazuta cu un tiv fin de matase pe care este inserat fir cu fir parul, metoda revolutionara si de mare efect care creeaza un aspect cat se poate de natural.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/alba_comfort_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alba_comfort_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alba_comfort_3.jpg'
        ]
      },
      {
        id: 75,
        title: 'Peruca sintetica pentru femei Tact Soft',
        desc: '100% FĂCUT MANUALPeruca este țesuta manual, cu măiestrie desavarsita, într-un tul elastic fin, oferindu-vă o perucă incredibil de ușoară și confortabilă, ideală pentru cei dintre noi cu scalp sensibil.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_metallicblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_TactSoft_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_TactSoft_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_TactSoft_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_TactSoft_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_TactSoft_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_TactSoft_1.jpg'
        ]
      },
      {
        id: 76,
        title: 'Peruca par scurt monofilament Light Mono',
        desc: 'Incearca gustul schimbarii in bine! O tinuta sport perfecta pentru femeia dinamica a zilelor moderne. Traieste din plin!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksmoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/light_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/light_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/light_mono_3.jpg'
        ]
      },
      {
        id: 77,
        title: 'Peruca monofilament pentru femei Fiore Soft',
        desc: 'Peruca monofilament cu par scurt, coafura clasica model Fire Soft are intreaga zonă a coroanei este formată din monofilament – o plasă care pare a fi invizibilă pe piele și unde părul este țesut individual într-o măiestrie fină. Restul acestei peruci constă în mare parte dintr-o tesatura în care părul este cusut cu talent pe benzi fine de bumbac. Montura perucii se adaptează foarte bine formei capului, și are o circulație a aerului foarte bună. Pe zona cararii ușor de văzut, monofilamentul realizat manual vă oferă o naturalețe deosebită.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_greymulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_peppergrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_FioreSoft_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_FioreSoft_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_FioreSoft_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_FioreSoft_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_FioreSoft_1.jpg'
        ]
      },
      {
        id: 78,
        title: 'Peruca eleganta dama Savona Mono',
        desc: 'Pot fi ancorate suplimentar si cu ajutorul sistemelor de siguranta, de fixare pe care noi le comercializam.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ashgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivorygrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_white.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SavonaMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SavonaMono_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SavonaMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SavonaMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SavonaMono_1.jpg'
        ]
      },
      {
        id: 79,
        title: 'Peruca femei nedetectabila Ever Mono',
        desc: 'Draga mea, sa iti fac o confidenta: ai o personalitate minunata. Dar nu personalitatea se vede de la distanta!!! Alege o peruca care sa te reprezinte pe tine, cea care esti acum. Ai ocazie sa faci ce nu a facut natura: sa alegi ceva pe gustul tau.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ever_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ever_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ever_mono_3.jpg'
        ]
      },
      {
        id: 80,
        title: 'Peruca monofilament penrtu femei Rimini Mono',
        desc: 'Pot fi ancorate suplimentar si cu ajutorul sistemelor de siguranta, de fixare pe care noi le comercializam.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_coffeebrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivorygrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rimini_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rimini_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rimini_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rimini_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rimini_1.jpg'
        ]
      },
      {
        id: 81,
        title: 'Peruca bob pentru femei, monofilament, Idaho Mono',
        desc: 'Peruca Idaho Mono de la Raquel Welch este o perucă bob scurtă. Datorită monofilamentului pe cărare, unde firele de păr sunt inserate manual, fir cu fir, aceasta perucă imită creșterea naturală a părului și se îmbină cu tonurile pielii oferindu-ți cel mai natural aspect. Coafura perucii Idaho Mono este elegantă și clasică, dar totuși la moda. Această perucă scurtă este croită, aranjată si gata de purtat. Pentru a o personaliza, puteți fila ușor bretonul sau îl puteți purta după ureche. Idaho Mono este disponibil în zece mixuri de culori, printre care si o frumoasă nuanță grizonată!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/idaho_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      },
      {
        id: 82,
        title: 'Beach Mono',
        desc: 'Un model in care se imbina fericit inovatia si inspiratia. Arati si te simti bine! Peruca Beach Mono face moda',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown_mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/beach_mono_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/beach_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/beach_mono_3.jpg'
        ]
      },
      {
        id: 83,
        title: 'Peruca in scari Flash Mono',
        desc: 'Frumusetea ii da putere unei femei. ‘:E extraordinar ce poate sa faca: e o expresie a sinelui; sistem de comunicare; forma de protest; semnaleaza identitatea si determina schimbare.”',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/flash_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flash_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flash_mono_3.jpg'
        ]
      },
      {
        id: 84,
        title: 'Peruca par scurt monofilament Only Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/only_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/only_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/only_mono_3.jpg'
        ]
      },
      {
        id: 85,
        title: 'Peruca eleganta Bolzano Mono',
        desc: 'Peruca monofilament Bolzano mono propune o linie eleganta ideala pentru o doamna. Parul este realizat din fibra sintetica de ultima tehnologie Germania. Coafura isi va pastra linia si culoarea si dupa apalare pentru mult timp. Produsele monofilament sunt mai realiste, mai usoare si mai bine aerisite. Parul este cusut fir cu fir pe baza transparenta de monofilament si pare sa creasca direct din piele. In fata, in dreptul fruntii, parul este cusut pe un tiv discret, asftel incat putem modifica cararea sau putem purta peruca cu parul pe spate fara ca cineva sa banuiasca ca este vorba de o peruca. Vrei sa te convingi/ Vino la magazin sa o probezi!',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BolzanoMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BolzanoMono_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BolzanoMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BolzanoMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BolzanoMono_1.jpg'
        ]
      },
      {
        id: 86,
        title: 'Peruca eleganta femei Bolzano Mono',
        desc: 'Monofilamentul se refera la baza perucii, la textura pe care sunt inserate suvitele de par.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivorygrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BolzanoMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BolzanoMono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BolzanoMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BolzanoMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BolzanoMono_4.jpg'
        ]
      },
      {
        id: 87,
        title: 'Peruca sintetica par scurt monofilament Carol Mono',
        desc: 'O tunsoare mereu in moda, peruca cu par scurt accentueaza trasaturile tale frumoase si creeaza un look rafinat',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/carol_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/carol_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/carol_3.jpg'
        ]
      },
      {
        id: 88,
        title: 'Peruca dama par scurt, clasic, monofilament Solitär Hi Mono',
        desc: 'O peruca eleganta prin definitie. Alege sa faci diferenta, alege sa iesi in fata.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/solitaer_hi_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/solitaer_hi_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/solitaer_hi_mono_3.jpg'
        ]
      },
      {
        id: 89,
        title: 'Peruca marime mare Smart Large Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/smart_mono_2_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smart_mono_1.jpg'
        ]
      },
      {
        id: 90,
        title: 'Peruca monofilament pentru femei Castello Mono',
        desc: 'Peruca nedetectabila monofilament este o peruca usoara si discreta. Peruca Castello Mono este ideala pentru sezonul cald sau pentru doamnele care nu vor ca transpiratia excesiva sa le deranjeze. Peruca model Castello are parul din dreptul fruntii inserat fir cu fir pe o bordura semi transparenta astfel incat sa nu se vada ca este vorba de o peruca. Parul poate fi dat pe spate, in parti dupa cum doresti. Prin monofilament se vede pielea capului.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightchampagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CastelloMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CastelloMono_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CastelloMono_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CastelloMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CastelloMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CastelloMono_1.jpg'
        ]
      },
      {
        id: 91,
        title: 'Peruca medicala dama Cella Mono',
        desc: 'Peruca pentru femei cu par scurt. Peruca eleganta realizata din monofilament si trese. Prin monofilament se vede scalpul creaind un efect realist. Parul este cusut fir cu fir pe crestet iar in laterale si spate, parul este cusut pe niste trese moi din bumbac. Peruca monofilament are avantajul ca est emai usoara si mai binbe ventilata decat una standard, fiind potrivita pentru sezonul cald. Parul este din fibra sintetica de calitate. Astfel va veti bucura de culoarea aleasa si coafura dorita pentru mult timp.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ashgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivorygrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Cella_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Cella_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Cella_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Cella_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Cella_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Cella_1.jpg'
        ]
      },
      {
        id: 92,
        title: 'Peruca sintetica delicata Cesana Soft',
        desc: 'Peruca delicata confectionata manual model Cesana Soft. Exact asa cum ii spune si numele peruca are o captuseala moale, delicata potrivita pentru persoanele care au o sesibilitate cescuta la nivelul pielii. Textura moale atinge placut scalpul si nu irita intrucat fiecare fir de par este cusut manual. Peruca are o frunte discreta inbtrucat parul din dreptul bretonului este cusut pe o banda semi transparenta. Perucile realizate manual sunt bine ventilate, au o densitate naturala si lasa pielea scapului sa respire. Sunt ideale pe timpul verii sau pentru doamnele care au bufeuri. Peruca vine mulat pe pielea capului. Parul este realizat din fibre sintetice extrem de realist confectionate care isi pastreaza coafura si culoarea pe toata durata intrebuintarii. Daca se respecta intocmai instrucvtiunile, este facuta sa reziste in forma aleasa cca un an de purtare zilnica.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ashgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_pepperbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_1.jpg'
        ]
      },
      {
        id: 93,
        title: 'Peruca par scurt monofilament Mia Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/mia_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mia_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mia_mono_3.jpg'
        ]
      },
      {
        id: 94,
        title: 'Spring Mono',
        desc: 'Aceasta peruca cu par scurt ondulat raspunde celor mai inalte exigente. Uita de zilele pierdute la coafor si de noptile cu bigudiuri. Totul poate fi cu mult mai simplu pentru tine. Alege Hermax!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/spring_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spring_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spring_mono_3.jpg'
        ]
      },
      {
        id: 95,
        title: 'Peruca cu volum fibra termo Grenada Mono Part',
        desc: 'Peruca moderna realizata cu monofilament pe carare si tul frontal transparent. Peruca Raquerl Welch cu par din fibra termorezistenta',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_grenada_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_grenada_mono_2_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_grenada_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_grenada_mono_3.jpg'
        ]
      },
      {
        id: 96,
        title: 'Peruca ondulata cu chica Gina Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/gina_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/gina_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/gina_mono_3.jpg'
        ]
      },
      {
        id: 97,
        title: 'Peruca dama par scurt Barletta Hi Mono',
        desc: '',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Barletta_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Barletta_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Barletta_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Barletta_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Barletta_4.jpg'
        ]
      },
      {
        id: 98,
        title: 'Peruca marime mare Smart Large Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/smart_mono_2_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smart_mono_1.jpg'
        ]
      },
      {
        id: 99,
        title: 'Peruca monofilament pentru femei Castello Mono',
        desc: 'Peruca nedetectabila monofilament este o peruca usoara si discreta. Peruca Castello Mono este ideala pentru sezonul cald sau pentru doamnele care nu vor ca transpiratia excesiva sa le deranjeze. Peruca model Castello are parul din dreptul fruntii inserat fir cu fir pe o bordura semi transparenta astfel incat sa nu se vada ca este vorba de o peruca. Parul poate fi dat pe spate, in parti dupa cum doresti. Prin monofilament se vede pielea capului.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightchampagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CastelloMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CastelloMono_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CastelloMono_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CastelloMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CastelloMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CastelloMono_1.jpg'
        ]
      },
      {
        id: 100,
        title: 'Peruca medicala dama Cella Mono',
        desc: 'Peruca pentru femei cu par scurt. Peruca eleganta realizata din monofilament si trese. Prin monofilament se vede scalpul creaind un efect realist. Parul este cusut fir cu fir pe crestet iar in laterale si spate, parul este cusut pe niste trese moi din bumbac. Peruca monofilament are avantajul ca est emai usoara si mai binbe ventilata decat una standard, fiind potrivita pentru sezonul cald. Parul este din fibra sintetica de calitate. Astfel va veti bucura de culoarea aleasa si coafura dorita pentru mult timp.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ashgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivorygrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Cella_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Cella_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Cella_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Cella_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Cella_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Cella_1.jpg'
        ]
      },
      {
        id: 101,
        title: 'Peruca sintetica delicata Cesana Soft',
        desc: 'Peruca delicata confectionata manual model Cesana Soft. Exact asa cum ii spune si numele peruca are o captuseala moale, delicata potrivita pentru persoanele care au o sesibilitate cescuta la nivelul pielii. Textura moale atinge placut scalpul si nu irita intrucat fiecare fir de par  este cusut manual. Peruca are o frunte discreta inbtrucat parul din dreptul bretonului este cusut pe o banda semi transparenta. Perucile realizate  manual sunt bine ventilate, au o densitate naturala si lasa pielea scapului sa respire. Sunt ideale pe timpul verii sau pentru doamnele care au bufeuri. Peruca vine mulat pe pielea capului. Parul este realizat din fibre sintetice extrem de realist confectionate care isi pastreaza coafura si culoarea pe toata durata intrebuintarii. Daca se respecta intocmai instrucvtiunile, este facuta sa reziste in forma aleasa cca un an de purtare zilnica.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ashgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_pepperbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_1.jpg'
        ]
      },
      {
        id: 102,
        title: 'Peruca par scurt monofilament Mia Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/mia_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mia_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mia_mono_3.jpg'
        ]
      },
      {
        id: 103,
        title: 'Spring Mono',
        desc: 'Aceasta peruca cu par scurt ondulat raspunde celor mai inalte exigente. Uita de zilele pierdute la coafor si de noptile cu bigudiuri. Totul poate fi cu mult mai simplu pentru tine. Alege Hermax!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/spring_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spring_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spring_mono_3.jpg'
        ]
      },
      {
        id: 104,
        title: 'Peruca cu volum fibra termo Grenada Mono Part',
        desc: 'Peruca moderna realizata cu monofilament pe carare si tul frontal transparent. Peruca Raquerl Welch cu par din fibra termorezistenta',  
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_grenada_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_grenada_mono_2_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_grenada_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_grenada_mono_3.jpg'
        ]
      },
      {
        id: 105,
        title: 'Peruca ondulata cu chica Gina Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/gina_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/gina_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/gina_mono_3.jpg'
        ]
      },
      {
        id: 106,
        title: 'Peruca dama par scurt Barletta Hi Mono',
        desc: '',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Barletta_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Barletta_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Barletta_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Barletta_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Barletta_4.jpg'
        ]
      },
      {
        id: 107,
        title: 'Peruca femei cu par sintetic scurt Noelle Mono',
        desc: 'Alege sa te rasfeti mai intai pe tine. O femeie multumita va fi mult mai blanda, zambitoare si generoasa. Crede-ma, nimeni nu iti va reprosa ca ti-ai permis acest capriciu. Cumpara daca iti place cu adevarat!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/noelle_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/noelle_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/noelle_mono_3.jpg'
        ]
      },
      {
        id: 108,
        title: 'Peruca Raquel Welch import Germania model Aspen Mono',
        desc: 'O frizura pixie cu volum este foarte populara intrucat indulceste trasaturile si daca este combinata in 2 tonuri, aduce un plus de culoare oricarei tinute. Accesorizeaza-te cu o peruca blonda (sau de ce nu, roscata) si vei vedea diferenta. Modelul acesta reprezinta o peruca monofilament, ceeavce inseamna ca este mult mai bine ventilata, mai usoara si mai confortabila decat varianta standard.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/aspen_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mono-wefted.jpg'
        ]
      },
      {
        id: 109,
        title: 'Peruca din par sintetic monofilament Travel Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown_mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silvergrey-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/travel_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/travel_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/travel_mono_3.jpg'
        ]
      },
      {
        id: 110,
        title: 'Peruca lux marime mica Cara Small Deluxe',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti_mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cara_deluxe_2_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cara_deluxe_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cara_deluxe_3_01.jpg'
        ]
      },
      {
        id: 111,
        title: 'Peruca de lux marime mica Liza small Deluxe',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightsand-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/liza_small_deluxe_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/liza_small_deluxe_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/liza_small_deluxe_3.jpg'
        ]
      },
      {
        id: 112,
        title: 'Peruca scurta Raquel Welch, Peru Mono, tul frontal',
        desc: 'Păr scurt, culori calde, finisaj de calitate, colecție de peruci de brand…. Sa mai continui?! Lista e lungă. Toate acestea sunt motive sa alegi peruca monofilament Peru Mono',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/peru_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/peru_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mono-wefted-lacefront-hh.jpg'
        ]
      },
      {
        id: 113,
        title: 'Mega Mono',
        desc: 'Parul lung este atuul femeii care se trezeste cu zambetul pe buze, Alege sa iti faci un cadou tie de care sa se bucure deopotriva EL.', 
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/mega_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mega_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mega_mono_3.jpg'
        ]
      },
      {
        id: 114,
        title: 'Peruca clasica femei Citta Mono',
        desc: 'Foarte populara aceasta tunsoare! Bretonul amplu creeaza o aparitie seducatoare, coafura se aranjeaza FOARTE usor. Doamnele +50 au ales  aceasta peruca cu breton lung nr 1',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/citta_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/citta_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/citta_mono_3.jpg'
        ]
      },
      {
        id: 115,
        title: 'Marusha Mono',
        desc: 'Efect WoW garantat! Par lung si matasos, senzational. Traieste intens fiecare secunda si bucura-te de calitatea monofilament a acestui produs',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-lighted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/marusha_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/marusha_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/marusha_mono_3.jpg'
        ]
      },
      {
        id: 116,
        title: 'Peruca de lux nedetectabila Cara 100 Deluxe',
        desc: 'Confort pur. Nu cauta mai departe! Daca linia tunsorii te reprezinta, daca nu esti timida si iti place sa fii in centrul atentiei, atunci aceasta peruca cu par scurt este pentru tine!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cara_deluxe_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cara_deluxe_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cara_deluxe_3.jpg'
        ]
      },
      {
        id: 117,
        title: 'Tempo 100 Deluxe',
        desc: 'Coco Chanel spunea ‘Pentru a fi de neinlocuit, trebuie sa fii intotdeauna diferita.’ Draga mea, nu e niciodata prea tarziu sa te faci indispensabila. Daca iti place aceasta peruca, cumpar-o. Simplu',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-tipped-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/coffeebrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/tempo_100_deluxe_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tempo_100_deluxe_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tempo_100_deluxe_3.jpg'
        ]
      },
      {
        id: 118,
        title: 'Tempo Large Deluxe',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-tipped-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/tempo_100_deluxe_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tempo_100_deluxe_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tempo_100_deluxe_3_01.jpg'
        ]
      },
      {
        id: 119,
        title: 'Peruca sintetica de lux Alexis Deluxe',
        desc: 'Esential feminina, aceasta peruca propune o tinuta lejera . Incarca-te de energia acestui anotimp si radiaza! Acum este timpul sa fii frumoasa! Aceasta peruca este lucrata in tehnologia revolutionara a monofilamentului pe baza de tul.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/alexis_deluxe_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alexis_deluxe_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alexis_deluxe_2.jpg'
        ]
      },
      {
        id: 120,
        title: 'Peruca Raquel Welch, par sintetic, pana la umeri, monofilament Empire Mono',
        desc: 'O coafura ampla, volum natural, nimic excesiv, toate astea recomanda acesta peruca oricarei doamne care se vrea in centrul atentiei. Cine nu si-ar dori asemena coafura?! Din pacate, nu suntem toate niste norocoase. O coafura asa reusita se obtine cu greu fara un ajutor profesionist  si chiar si atunci, coafura NU REZISTA. Pentru cineva care este pregatit pentru o relatie “pe termen lung” achizitionarea unei peruci de calitate in stilul preferat este o optiune sigura. De ce sa risti?! Suna, comanda si, daca nu iti place, o faci retur. Ai 15 zile sa te razgandesti. Dar ce sa nu iti placa? Peruca monofilament total, peruca nedetectabila, culori suvitate permanente, tinuta perfecta a buclelor, sincer, cand stau sa ma gandesc asa, nici nu pare atat de scumpa. Cine spune ca tu nu meriti?',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/empire_mono_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mono-extend-lace-wefted-hh.jpg'
        ]
      },
      {
        id: 121,
        title: 'Peruca de lux pentru femei Pisa Super',
        desc: 'Perucile de lux din care face parte si Pisa Super sunt cu baza din monofilament si realizate integral artizanal.Cel mai bun lux realizat manual! Perucile 100% lucrate manual ofera naturalete maxima. Fiecare fir de păr de pe coroana capacului este introdus individual printr-un monofilament – o plasă de țesătură care pare a fi invizibilă atunci când este plasată pe piele. Cealaltă parte a părului este împletită pe tul fin. Ușoare ca o pană, cu un nivel uimitor de confort, aceste peruci aproape că nu se pot distinge de părul purtătorului.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_nutmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sunnyblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PisaSuper_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PisaSuper_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PisaSuper_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PisaSuper_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PisaSuper_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PisaSuper_1.jpg'
        ]
      },
      {
        id: 122,
        title: 'Peruca de lux pentru femei East Luxury',
        desc: 'V- am spus ca la Raquel Welch luxul este numitor comun? Perucile de calitate trebuie sa fie usoare, bine ventilate, discrete, cu par moale, matasos. Iar perucile de lux corespund celor mai exigente criterii. Perucile au cateva zeci de grame, sunt usoare si delicate. Nu trebuie sa pierdeti din vedere ca sunt un accesoriu si se comporta in timp direct proportional cu timpul, banii si atentia cu care v-ati ocupat de ea. Trebuie sa aveti asteptari realiste insa. Nu rezista unei torsiuni extreme, sunt ca o bijuterie, un décor de cap. Este suficient spre exemplificare sa va ganditi la un ciorap fin de matase. Unul dintre multiplele avantaje ale monturii tip monofilament este ca se poate schimba cararea in fuctie de obisnuinta. Este dificil de inteles cat suntem tributari unui gest reflex de tipul alegerea cararii dar aceasta nu este deloc intamplatoare. Bretonul pe o parte (sau alta) poate ajuta la mascarea unei mici imperfectiuni (cicatrice) sau poate favoriza profilul nostru bun (stangul, de regula). Astfel, oprtunitatile de aranjare sunt multiple, putem alege chiar sa ne aranjam frumos parul dupa ureche sau cu ajutorul unei cleme, sa dam parul pe spate. Peruca bob al carei par se opreste exact la nivelul maxilarului este extrem de actuala, lasand gatul expus. In modul acesta, se evita frictiunile repetate cu textile (guler etc) parul nu se mai electrizeaza, eliminand riscul degradarii ei pe lungime atunci cand parul se mai incurca la spate. Perucile de lux fac priza perfecta cu scalpul, este indicat sa fie aplicate direct pe piele avand o marime mai mica decat perucile fashion. Culoarea din poza: sandyblonde.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/east_luxury.jpg'
        ]
      },
      {
        id: 123,
        title: 'Peruca de lux par scurt ondulat Malibu Luxury',
        desc: 'Luxul in doze mici nu poate face rău nimănui, nu-i așa? Răsfață-te! De ce te-ai mulțumi cu puțin?! O perucă monofilament realizata integral manual, cu tul in fata pentru a putea să-ți aranjezi părul cum vrei, cu sau fără breton, in funcție de dispoziție. Părul este realizat dintr-o fibra sintetică de ultima tehnologie, calitate Germania. Oricine își permite ar alege colecția de peruci de lux de la Raquel Welch.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/malibu_luxury_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/malibu_luxury_2.jpg'
        ]
      },
      {
        id: 124,
        title: 'Peruca nedetectabila lux Aura',
        desc: 'Eticheta nu este doar un moft. Alege Colectia hairsociety si te vei bucura de experienta calitatii la superlativ.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Aura.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Aura_8-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Aura_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Aura_4-ink.jpeg'
        ]
      },
      {
        id: 125,
        title: 'Peruca monofilament integral manual Posh',
        desc: 'Un stil clasic, atemporal, o peruca de calitate germana, ultima tehnologie in materie de fashion. Cuvintele sunt de prisos. Vino la magazin sa o probezi. Sa te simti bine si sa arati bine, asta da satisfactie.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Posh_01.jpg'
        ]
      },
      {
        id: 126,
        title: 'Peruca scurta de lux Select',
        desc: 'Un stil clasic, o coafura ladylike, un par care arata bine din toate unghiurile. Vei trece proba zilelor caduroase de vara, a serilor umede de toamna. O peruca monofilament realizata integral manual, premiata pentru calitate si confort. Vrei sa incerci?',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Select_01.jpg'
        ]
      },
      {
        id: 127,
        title: 'Peruca par scurt mono Air',
        desc: 'Rasfata-te si lasa-te iubita! Cei dragi se vor bucura sa te vada cu zambetul pe buze din nou. Fericita si increzatoare, vei radia incredere. Confortul tau este pentru noi prioritate 0. Gama de peruci de lux',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Air_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Air_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Air_6-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Air_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Air_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Air_2-ink.jpeg'
        ]
      },
      {
        id: 128,
        title: 'Amy Small Deluxe',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darknougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightpearl_mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/amy_small_deluxe_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/amy_small_deluxe_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/amy_small_deluxe_3.jpg'
        ]
      },
      {
        id: 129,
        title: 'Peruca par lung monofilament Affair',
        desc: 'Timpul trece repede dar un singur moment poate face diferenta. Poate sa iti schimbe viata. Traieste frumos si alege sa iti faci viata cat mai buna. De parul tau ne ocupam noi deocamdata.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Affair.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HairSociety_Affair_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Affair_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Affair_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Affair_1-ink.jpeg'
        ]
      },
      {
        id: 130,
        title: 'Peruca lux Bloom',
        desc: 'O coafura ampla, in scari, versatila. O peruca de 5*. Vino sa te convingi de calitatea Premium direct in magazin. Aceasta colectie intruneste toate conditiile: Fibra calitate lux, baza de monofilament in care parul este inserat fir cu fir, manual, pentru un tangaj firesc, parul se misca natural. Peruca este nedetectabila.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkpearl-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Bloom_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Bloom_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Bloom_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Bloom_3-ink.jpeg'
        ]
      },
      {
        id: 131,
        title: 'Peruca nedetectabila de lux Icone',
        desc: 'Un moment de placere facut sa dureze cat vrei tu! Un bob delicat, culori naturale, peruca monofilament realizata integral manual, toate astea doar pentru tine. Pentru ca tu meriti ce e mai bun!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Icone_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Icone_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Icone_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Icone_4-ink.jpeg'
        ]
      },
      {
        id: 132,
        title: 'Peruca de lux Star',
        desc: 'O linie atent elaborata pentru un aspect cat mai natural. Confortabil. E timpul sa vezi diferenta. Calitatea germana nu este un mit. Nimeni nu va sti ca porti o peruca, pana si tu vei uita.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Star.jpg'
        ]
      },
      {
        id: 133,
        title: 'Peruca par scurt Berlin Super',
        desc: 'Peruca de lux cu parul scurt model Berlin Super este un produs recomandat doamnelor care au parul subtire, rar sau care sunt nevoite sa  aplice peruca direct pe scalpul gol. Doar asa te poti bucura pe deplin de beneficiile unei peruci cu monofilament pe crestet si realizata integral  manual. Parul este cusut fir cu fir pe o baza delicata care aplicata pe piele are un efect extrem de realist. Peruca este usoara si foarte bine ventilata. Coafura scurta va ofera o tinuta comoda si practica. Fibra tehnologie Germania din care este confectionata va asigura o culoare stabila pe  termen indelungat si o densitate medie pentru o coafura cat mai discreta.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BerlinSuper_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BerlinSuper_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BerlinSuper_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BerlinSuper_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BerlinSuper_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_BerlinSuper_1.jpg'
        ]
      },
      {
        id: 134,
        title: 'Peruca par natural remy Xela',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/xela_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/xela_2.jpg'
        ]
      },
      {
        id: 135,
        title: 'Peruca par natural prime Adore',
        desc: 'Creatorii de modele de la Ellen Wille, cel mai mare producator de peruci din Europa, s-au gandit sa adune intr-un singur produs avantajele parului Prime, rezistent termic, si parului uman. Parul Prime simuleaza perefct naturalul, iar combinatia perfecta ofera satisfactie deplina cumparatorului sofisticat: volum si tinuta, suplete si rezistenta. Peruca Adore – sigur o vei iubi!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nutbrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/adore.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/adore_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/adore_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/adore_4.jpg'
        ]
      },
      {
        id: 136,
        title: 'Peruca par natural prime scurt Encore',
        desc: 'Un par atat de scurt si cu toate astea, avem atat de multe variante! Nu exista nici un motiv sa te opresti. Schimbarea e la ordinea zilei. Cu putina indemanare, imaginatie si cu ajutorul produselor destinate perucilor, poti imbratisa ce stil doresti. O peruca monofilament nedetectabila este un must-have al acestui sezon. Suna si poate fi a ta!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nutbrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/naturewhite-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/encore.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/encore_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/encore_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/encore_4.jpg'
        ]
      },
      {
        id: 137,
        title: 'Peruca din par natural remy pentru femei, nedetectabila, monofilament model bob Yara',
        desc: 'Un bob lung, par natural, eleganta fara compromisuri, ideal pentru orice femeie care se vrea frumoasa si cocheta. Bucura-te de calitatea produselor Ellen Wille. Nu uita ca „fericirea este sanatatea sufletului.”',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chestnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Yara_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Yara_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Yara_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Yara_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Yara_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/10/mono-wefted.jpg'
        ]
      },
      {
        id: 138,
        title: 'Peruca dama par natural mix Promise',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/quotsand-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/06/promise_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/06/promise_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/06/promise_3.jpg'
        ]
      },
      {
        id: 139,
        title: 'Peruca par natura prime Illusion',
        desc: 'Cuvintele sunt de prisos. Imaginea vorbeste de la sine. Un par lung asa cum ai visat, matasos si des, o senzatie de mangaiere calda pe umeri, o bucurie fara margini. Te incanta? Peruca Illusion vine la un pret atractiv azi, doar pentru tine.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/illusion.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/illusion_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/illusion_3.jpg'
        ]
      },
      {
        id: 140,
        title: 'Peruca naturala par scurt Ivory',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearlgrey-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ivory_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ivory_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ivory_3.jpg'
        ]
      },
      {
        id: 141,
        title: 'Peruca par natural prime Impulse',
        desc: 'Acest model de peruca te rasfata atat prin calitate cat si prin stilul propus. In scurt timp a devenit unul dintre bestseller-ele colectiei si va deveni un model clasic, preluat in colectia permanenta. Alege sa impresionezi!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/impulse_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/impulse_2.jpg'
        ]
      },
      {
        id: 142,
        title: 'Peruca par natural prime Mood',
        desc: 'O peruca din par natural calitate premium intr-un mix sofisticat cu par Prime rezistent termic. Rezultatul: O peruca bob cu par matasos  si fin, o coafura rezistenta, cu tinuta, ideala pentru un brunch intr-o zi frumoasa de duminica. Bucura-te alaturi de familie de lucrurile simple.  Pentru ca noi ne gandim la toate.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/mood.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mood_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mood_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mood_4.jpg'
        ]
      },
      {
        id: 143,
        title: 'Peruca par natural european Mondo',
        desc: 'Peruca naturala remy, de origine europeana, montata in monofilament cu tul frontal transparent.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/03/mondo-5-1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/mondo-5-3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/mondo-5-2.jpg'
        ]
      },
      {
        id: 144,
        title: 'Appeal',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/winered-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/appeal_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/appeal_2_01.jpg'
        ]
      },
      {
        id: 145,
        title: 'Peruca de lux pentru femei Image',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/06/image_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/06/image_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/06/image_1.jpg'
        ]
      },
      {
        id: 146,
        title: 'Peruca naturala bob Wish',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearlgrey-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/wish_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wish_2.jpg'
        ]
      },
      {
        id: 147,
        title: 'Peruca naturala cu par lung Passion',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/passion_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/passion_2.jpg'
        ]
      },
      {
        id: 148,
        title: 'Peruca de lux din par natural Delicate Plus',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/delicate_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/delicate_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/delicate_3_01.jpg'
        ]
      },
      {
        id: 149,
        title: 'Peruca silicon din par natural remy Trinity Plus',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/softcopper-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/trinity_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/trinity_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/trinity_3_01.jpg'
        ]
      },
      {
        id: 150,
        title: 'Peruca remy naturala cu par lung Emotion',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/emotion_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/emotion_1_01.jpg'
        ]
      },
      {
        id: 151,
        title: 'Peruca cu silicon din  par natural remy Spectra Plus',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/spectra_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spectra_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spectra_3.jpg'
        ]
      },
      {
        id: 152,
        title: 'Peruca naturala cu par lung Cascade',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cascade_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cascade_2_01.jpg'
        ]
      },
      {
        id: 153,
        title: 'Peruca din par lung natural remy Obsession',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/obsession_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/obsession_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/obsession_3_05.jpg'
        ]
      },
      {
        id: 154,
        title: 'Peruca sintetica Bravo',
        desc: 'Acest tip de montura propune un finisaj realist prin efectul de vartej din crestetul capului.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/bravo_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bravo_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-carare.jpg'
        ]
      },
      {
        id: 155,
        title: 'Peruca monofilament Beauty',
        desc: 'Discretie si confort, acestea sunt doar cuvinte. Comanda modelul de peruca de lux si cuvintele vor capata un sens nou. Un model de colectie premiat la Paris poate fi al tau in cateva zile.Vrei sa afli cum? Suna si cere mai multe detalii despre Colectia de lux.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Beauty_01.jpg'
        ]
      },
      {
        id: 156,
        title: 'Peruca par mediu mono Charisma',
        desc: 'Nu ai curaj sa comanzi online? Te asteptam in magazin. Aceasta colectie este destinata cunoscatorilor. Daca nu ai purtat niciodata o peruca de lux, cere detalii online sau telefonic. Model peruca de lux Charisma face parte din Colectia Hairsociety. Toate produsele din aceasta colectie sunt calificate ca dispozitive medicale. Peruca este foarte usoara, foarte bine ventilata.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/charisma.jpg'
        ]
      },
      {
        id: 157,
        title: 'Peruca par lung sintetic lux Fortune',
        desc: 'Clipa, opreste-te! Esti minunata! Parul tau nu iti va mai rezerva surprize. Avem noi grija de asta. Accesoriul tau preferat te asteapta!',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Fortune.jpg'
        ]
      },
      {
        id: 158,
        title: 'Peruca par scurt volum lux Glory',
        desc: 'Un stil ultra-feminin, mereu in moda pentru o adevarata doamna. Nu ezita, comanda peruca de lux si vino sa te convingi la probe. Crede-ma, ai si uitat de cand nu te-ai simtit atat de sigura!',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/glory_01.jpg'
        ]
      },
      {
        id: 159,
        title: 'Peruca bob de lux Shape',
        desc: 'O tunosoare bob franjurat, nu foarte lung, exact cum se poarta, fix pana la linia maxilarului. Mereu ai vrut sa vezi cum iti sta dar nu  ai avut curaj sa te tunzi. Acum ai ocazia. Vino! Alege.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Shape_01.jpg'
        ]
      },
      {
        id: 160,
        title: 'Peruca manuala nedetectabila Splendid',
        desc: 'Numele acestei peruci spune totul. Splendid. Indescriptibil feminin. Magie la cutie. Culoarea dorita, mereu aceeasi, fara surprize neplacute. Daca vrei poate fi a ta!',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/splendid_01.jpg'
        ]
      },
      {
        id: 161,
        title: 'Peruca par cret femei Avanti',
        desc: 'Asigura-ti cu aceasta peruca o tinuta sobra, profi si contractele vor incepe sa curga. Nu uita ca in afaceri, respectul nu se cere, respectul se impune!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/avanti_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/avanti_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/avanti_3.jpg'
        ]
      },
      {
        id: 162,
        title: 'Peruca par scurt drept pentru femei, Take',
        desc: 'O tunsoare garcon, o linie simpla dar mereu in moda. Asa cum spunea Leonardo da Vinci ‘Simplitatea ramane cel mai sofisticat lucru.’',  
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/take_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/take_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/take_3.jpg'
        ]
      }

    ],
    "Peruci Coafuri Moderne":[
      {
        id: 163,
        title: 'Peruca cu par creponat  Wiki, Ellen Wille indisponibil',
        desc: 'Sezonul acesta parul creponat este in mare voga!. Fii super cool cu peruca par creponat lungime bob scurt. O coafura sic si volum exploziv in cateva minute, fara sa iti agresezi parul cu placa.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Wiki_1_2016.jpg']
      },
      {
        id: 164,
        title: 'Peruca din par sintetic scurt Debbie',
        desc: 'O peruca cu par scurt va scoate in eveidenta trasaturile tale frumoase si te intinereste. Aceasta peruca este un bestseller al colectiei Perucci din Germania. Iar asta se intampla cu un motiv: ea este realizata din materiale selectate cu grija si combina un design clasic cu o gama larga de culori moderne.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Debbie_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Debbie_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Debbie_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Debbie_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Debbie_1-ink.jpeg'
        ]
      },
      {
        id: 165,
        title: 'Peruca scurta cu breton asimetric pentru femei, Point',
        desc: 'O peruca ultra moderna pentru o femeie hotarata sa faca schimbari! Fii sexy, senzuala si nimic nu iti va rezista. Controleaza prezentul. Uita de trecut. Nu iti fa reprosuri, lasa regretele in urma. Azi este inceputul pentru tine. Noi am ales o peruca scurta, cu breton lung asimetric.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ebony-black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotaubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightmango-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Point_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Point_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Point_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Point_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Point_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-crowm.jpg'
        ]
      },
      {
        id: 166,
        title: 'Peruca sintetica cu trese, pentru femei cu par voluminos,Open',
        desc: 'Cum sa arati altfel in doar cateva minute? Nimic mai simplu! Alege sa iti condimentezi viata de cuplu cu mici schimabari in bine. O coafura moderna, sic in culori in tendinta poate fi piesa de rezistenta. O peruca de calitate la preturi moderate',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotaubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Open_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Open_8-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Open_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Open_6-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Open_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Open_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Open_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Open_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Open_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-crowm.jpg'
        ]
      },
      {
        id: 167,
        title: 'Peruca cu par scurt, drept cu breton Tool',
        desc: 'Lasa frumusetea ochilor tai sa atraga, renunta la bretoane complicate, adopta un stil cu linii curate, geometrice. O coafura cum e Tool lumineaza fata si descopera linia gatului. O peruca scurta, usoara, densitate medie, disponibila in culori naturale',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotaubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Tool_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tool_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tool_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tool_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tool_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-crowm.jpg'
        ]
      },
      {
        id: 168,
        title: 'Peruca din par cu volum Click',
        desc: 'O peruca cu mult volum este alegerea doamnelor pentru o ocazie speciala. Coafura cu breton mascheaza trecerea anilor si te fereste de privirile indiscrete',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/click_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/click_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/click_3.jpg'
        ]
      },
      {
        id: 169,
        title: 'Peruca par lung Impress',
        desc: 'Armele secrete ale vedetelor sunt acum la dispozitia tuturor! Vino sa probezi accesoriile de ultima ora  care pana acum au fost rezervate doar celebritatilor! Perucile au coborat de pe podium direct in magazine.',       
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ebony-black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/impress_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/impress_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/impress_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      },
      {
        id: 170,
        title: 'Peruca par scurt Cool',
        desc: 'Aceasta peruca propune o linie clasica reinterpretata.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mango-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cool_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cool_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cool_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cool_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cool_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      },
      {
        id: 171,
        title: 'Peruca sintetica pentru femei Golf',
        desc: 'Daca iti sta bine cu parul scurt si toti iti confirma asta, fii consecventa!c Perucile cu par scurt se intretin cel mai usor si au durata cea mai mare de viata. Alege inteligent!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/golf_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/golf_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/golf_3.jpg'
        ]
      },
      {
        id: 172,
        title: 'Peruca standard din par sintetic scurt, You',
        desc: 'Cea mai mare aventura pe care o poţi avea este sa iţi traieşti viaţa pe care ai visat-o. – spunea Oprah Winfrey. Alege sa traiesti intens si sa iti refuzi putine',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/you_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/you_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/you_3.jpg'
        ]
      },
      {
        id: 173,
        title: 'Peruca cu par scurt sintetic, nedetectabila Stay',
        desc: 'Gaseste curajul sa te accepti! Fii arhitectul vietii tale. Actioneaza asa cum simti si ignora canoanele. Peruca model scurt Stay este accesoriul perfect pentru o femeie dinamica care traieste clipa. No regrets!',       
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Stay_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Stay_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Stay_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Stay_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Stay_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/10/wefted-tul.jpg'
        ]
      },
      {
        id: 174,
        title: 'Peruca sintetica Raise',
        desc: 'Chiar daca exista mitul ca o peruca cu par scurt nu asigura o tinuta eleganta, o frizura texturata cu volum si breton cu lungime versatila, usor asmetric, este plina de feminitate.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkcherry-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/raise_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/raise_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/raise_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/raise_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      },
      {
        id: 175,
        title: 'Peruca par scurt cu volum West',
        desc: 'Conform experților, coafura pixie este in moda anul acesta. Cu putina ceara de par poți obține un look rebel sau ceva foarte decent, in funcție de dispoziție. Pune-te la adăpost de capriciile vremii si alege un accesoriu nelipsit din garderoba tuturor vedetelor. Culoarea din poza este hotchilli rooted pentru ca se pare “roșcatele” atrag toate privirile. O peruca moderna iți asigura o coafura fresh in fiecare zi, este “frumusețe la cutie – efect  garantat.” Uita de orele petrecute la salon, timpul tău este mult prea prețios.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/west.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-crowm.jpg'
        ]
      },
      {
        id: 176,
        title: 'Peruca par scurt sintetic nedetectabil Tab, Ellen Wille',
        desc: 'Clasic is fantastik! Pentru o prima impresie buna! Daca nu vrei sa risti nimic si vrei o coafura clasica, corecta, realizata impecabil, cumpara peruca par scurt Tab. Avem zece culori sa te convingem ca ai facut cea mai  buna alegere',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotaubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Tab_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_6-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Tab_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      },
      {
        id: 177,
        title: 'Peruca par mediu ondulat Turn',
        desc: 'Aceasta peruca este peruca mea favorita din colectia Changes.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/candyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkcherry-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/turn_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/turn_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/turn_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/turn_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-carare.jpg'
        ]
      },
      {
        id: 178,
        title: 'Peruca păr ondulat Movie Star',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Moviestar_8-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Moviestar_9-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Moviestar_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Moviestar_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Moviestar_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Moviestar_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      },
      {
        id: 179,
        title: 'Peruca bob pentru femei din par sintetic, monofilament, nedetectabil, Java, Ellen Wille',
        desc: 'Simte-te alta persoana, o femeie in moda, fresh, lipsita de complexe si inhibitii! O peruca moderna poate fi un aliat in lupta ta cu anii. Nu uita ca un nou anotimp incepe doar cu tine!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platin-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Java_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Java_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Java_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Java_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Java_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Java_1-ink.jpeg'
        ]
      },
      {
        id: 180,
        title: 'Peruca roscata pentru femei Push up',
        desc: 'Alege sa iti accesorizezi tinuta cu o peruca super! Moderna, plina de viata',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',      
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/push_up_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/push_up_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/push_up_3.jpg'
        ]
      },
      {
        id: 181,
        title: 'Peruca din par sintetic de calitate Keira',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/keira_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/keira_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/keira_3.jpg'
        ]
      },
      {
        id: 182,
        title: 'Peruca Perla cu par mediu usor ondulat',
        desc: 'Peruca Perla este un model din cea mai noua colectie de la Ellen Wille.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_9.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_8.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_5.jpg'
        ]
      },
      {
        id: 183,
        title: 'Peruca ondulata Alive',
        desc: 'Perucile din par sintetic marca Ellen Wille sunt de cea mai buna calitate.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/alive_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alive_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alive_3_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-fara-tul.jpg'
        ]
      },
      {
        id: 184,
        title: 'Peruca tul frontal Coco',
        desc: 'Scurt si simplu! Tac-pac. O culoare vibranta, o frizura indrazneata. Industria de fashion avanseaza cu viteaza si propune o selectie de modele care mai de care mai originale. Indrazneste si tu!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/coco_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/coco_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/coco_3.jpg'
        ]
      },
      {
        id: 185,
        title: 'Peruca scurta pentru femei Flex',
        desc: 'O peruca foarte practica, ideala pentru o zi in forta. Veselia ei te va cvoplesi pentru toata ziua',    
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/flex_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flex_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flex_3.jpg'
        ]
      },
      {
        id: 186,
        title: 'Planet Hi',
        desc: 'E atatr de usor sa fii mai frumoasa! O peruca este un accesoriu secret pentru fiecare zi. Cu el castigi  timp iar timpul tau e foooarte scump.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/planet_hi_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/planet_hi_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/planet_hi_3.jpg'
        ]
      },
      {
        id: 187,
        title: 'Peruca afro Disco',
        desc: 'Un păr moale și o coafură îndrăzneață, culori radiante, moderne, toate într-o coafură retro care a revenit în modă.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/04/espresso_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/darkchocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/chocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/hotmocca_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/lightbernstein_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/pearlblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/champagne_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/aubergine_perucci20-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_10-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_13-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_11-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_10-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_9-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_8-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_6-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/10/wefted-tul.jpg'
        ]
      },
      {
        id: 188,
        title: 'Peruca cu breton lung, asimetric, rezistentă termic, Link',
        desc: 'Transforma-ti imaginea cu care ne-ai obisnuit cu o peruca noua, draguta si cei dragi vor ramane impresionati. Iar tu te vei simti mult mai bine. Orice model ai alege, lasa-ti personalitatea sa te ghideze. Vei vedea cum  o singura schimbare in viata ta poate schimba cu totul vibe-ul pentru ca detaliile fac diferenta!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/04/espresso_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/darkchocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/chocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/mocca_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/lightbernstein_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/sandyblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/pearlblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/platinblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/pastelrose_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/rosewood_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/lavender_perucci20-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Link_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Link_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Link_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Link_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-fara-tul.jpg'
        ]
      },
      {
        id: 189,
        title: 'Peruca scurta calitate Jolly',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cosmoblack-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mangored-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/jolly_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jolly_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jolly_3.jpg'
        ]
      },
      {
        id: 190,
        title: 'Peruca din par sintetic scurt pentru femei, Indiana',
        desc: 'Vrei să arăți ca o vedeta de la Hollywood? Sau vrei tu sa fii o sursa de inspirație pentru alții?! Un păr tuns in trepte, practic, sexy  si tineresc. Nu ai nevoie de nici un stilist. Scuturi ușor din cap, dai cu mana prin păr si poți trece la atac. Viața e frumoasa si merită trăită la orice vârstă.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/quotsand-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnutmix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/indiana_mono_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-crowm.jpg'
        ]
      },
      {
        id: 191,
        title: 'Peruca sintetica cu par scurt femei Carol',
        desc: 'Stii ce se spune despre parul scurt, nu? Te intinereste, da, e adevarat. Daca eziti intre culori, nimic mai simplu, vino direct in magazin sa probam impreuna',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightespresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/carol_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/carol_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/carol_3_01.jpg'
        ]
      },
      {
        id: 192,
        title: 'Peruca cu par scurt, sintetic, pentru femei Club 10',
        desc: 'Volum si energie! Toate intr-o cutie! Peruca cu breton disponbila in nuante minunate',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/club_10_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/club_10_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/club_10_3.jpg'
        ]
      },
      {
        id: 193,
        title: 'Peruca din par sintetic, scurt, clasic, femei, Cora',
        desc: 'Intotdeauna ai fost preocupata de imaginea ta! Acum ne ai pe noi sa te ajutam. Ce zici? Brandul ellen Wille are peste 50 ani de cand ocupa locul 1 in industria de gen din Europa. Eu zic ca merita sa te bazezi pe noi',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cora_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cora_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cora_3.jpg'
        ]
      },
      {
        id: 194,
        title: 'Peruca dama cu par scurt Date',
        desc: 'Bunul gust va fi intotdeauna la moda. O peruca usoara si vesela ca o zi de primavara. Ce daca e iarna?',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/date_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/date_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/date_4_01.jpg'
        ]
      },
      {
        id: 195,
        title: 'Peruca cu par drept pentru femei Play',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cappucino-softed-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/play_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/play_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/play_3.jpg'
        ]
      },
      {
        id: 196,
        title: 'Peruca marime mare Date Large',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/date_1_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/date_2_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/date_4_02.jpg'
        ]
      },
      {
        id: 197,
        title: 'Peruca par scurt Mare',
        desc: 'Peruca cu tul frontal si monofilament partial in crestet. Perucile din colectia Modixx fac parte din renumitul brand ELLEN WILLE , așa că puteți fi sigur că ati cumpărat o perucă superioară, de calitate. Designerii nostri au creat această colecție pentru a fi o serie de premieră, confortul purtării perucii tale fiind de o importanță capitală. Perucile  Ellen Wille sunt inspirate din cele mai frumoase frizuri din Germania.',   
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_creamblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_greymulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_peppergrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_platinblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_tizianred.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Mare_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Mare_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Mare_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Mare_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Mare_1.jpg'
        ]
      },
      {
        id: 198,
        title: 'Peruca Raquel Welch din par sintetic, tunsa in scari cu volum Aspen',
        desc: 'O tunsoare plina de volum degaja multa energie si promite o zi minunata. Nu lasa pe nimeni sa iti strice ziua! Bucura-te de noua ta coafura in fiecare zi!. Modelul acesta de peruca are o baza din monofilament partial. Fibra sinteica de ultima generatie, disponibila intr-o gama generoasa de culori, faca din aceasta peruca un bestseller.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/aspen_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-crowm.jpg'
        ]
      },
      {
        id: 199,
        title: 'Peruca ondulata Jade',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/jade_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jade_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jade_3.jpg'
        ]
      },
      {
        id: 200,
        title: 'Peruca femei Germania, Sky',
        desc: 'Calitate germana la un pret simbolic! Daca esti o personalitate Alfa, amatoare de nou, fii un trend-setter in cercul tau de prieteni. Poarta peruca Sky!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/sky_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sky_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sky_3.jpg'
        ]
      },
      {
        id: 201,
        title: 'Peruca sintetica cu par scurt Start',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/start_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/start_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/start_3.jpg'
        ]
      },
      {
        id: 202,
        title: 'Peruca dama din par sintetic bob Echo, Ellen Wille',
        desc: 'Esti nevoita sa faci o schimbare? Alege o peruca de calitate de la Ellen Wille. Colectia Perucci aduce laolalta o serie restransa de modele, care mai de care mai frumoase si moderne, realizate dupa ultima tehnologie germana. Cu ajutorul designerilor germani si a celor mai priceputi  stilisti, am pus la punct o colectie unica unde calitatea vine la pret moderat.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Echo_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Echo_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Echo_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Echo_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Echo_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-carare.jpg'
        ]
      },
      {
        id: 203,
        title: 'Peruca păr lung fibră rezistentă termic Cloud',
        desc: 'Fibra rezistentă termic își păstrează forma inițială, dar, dacă se dorește, ea poate fi restilizată cu ajutorul plăcii de îndreptat sau  a ondulatorului cu o temperatură de până la 140 de grade Celsius.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/04/espresso_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/darkchocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/chocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/sand_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/sandyblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/champagne_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/cherryred_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/pastelrose_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/rosewood_perucci20-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cloud_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cloud_6-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cloud_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cloud_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cloud_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cloud_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/10/mono-parti-mini-lace-wefted.jpg'
        ]
      },
      {
        id: 204,
        title: 'Beam',
        desc: 'Un look so posh, o aparitie fina pentru toate serile calde de vara.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-lighted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/beam_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/beam_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/beam_3.jpg'
        ]
      },
      {
        id: 205,
        title: 'Peruca cu par scurt si breton asimetric Disc',
        desc: 'Exteriorizeaza-te si descopera puterea frumusetii! Cei din jur sunt captivi. Personalitatea ta este magnetica. Toti vor ssa fie langa tine',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/coffeebrown-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightmango-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platin-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/disc_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/disc_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/disc_4.jpg'
        ]
      },
      {
        id: 206,
        title: 'Peruca  de calitate pentru femei, Score',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/score_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/score_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/score_3.jpg'
        ]
      },
      {
        id: 207,
        title: 'Peruca dama par scurt sintetic Fair',
        desc: 'Nimic nu subliniza mai usor tipul de personalitate precum o face parul tau. Sportiv si plin de viata, nu-I asa?',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/fair_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fair_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fair_3_01.jpg'
        ]
      },
      {
        id: 208,
        title: 'Peruca par sintetic lungime medie mono part, tul frontal, United de la Ellen Wille',
        desc: 'Coafurile cu par mediu au revenit anul acesta in tendinte. Extrem de populare intrucat sunt usor de ingrijirt si se potrivesc la aproape orice tinuta, perucile cu par pana la umeri reprezinta un compromis perfect intre lungimea parului sin timpul destinat ingrijirii lui. Fii rezonabila, alege inteligent!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chillibrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranbrown-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_United_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_United_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_United_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_United_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_United_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_United_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      },
      {
        id: 209,
        title: 'Peruca dama cu tul frontal, Clever',
        desc: 'O tunsoare foarte indrazneata, ideala pentru o femeie dinamica care prefera un aspect tineresc si natural. O peruca young ca o zi de primavara!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/clever_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/clever_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/clever_3.jpg'
        ]
      },
      {
        id: 210,
        title: 'Peruca din par sintetic scurt Ginger',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/coffeebrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger_1_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger_3_01.jpg'
        ]
      },
      {
        id: 211,
        title: 'Peruca moderna, par scurt, breton asimetric Jazz',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mangored-rooted.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/jazz_1.jpg']
      },
      {
        id: 212,
        title: 'Peruca par ondulat Kiss',
        desc: 'Tunsoarea favorita a acestui sezon este propusa de Ellen Wille pentru tine. O structura foarte interesanta, parul se aseaza singur, noi  il ajutam putin doar cu degetele. Adio fixativ, gel si spuma!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mango-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/kiss_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/kiss_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/kiss_3.jpg'
        ]
      },
      {
        id: 213,
        title: 'Naomi',
        desc: 'Nu ai nevoie de ocazii ca sa te rasfeti. Alege sa fii cocheta in fiecare zi, seducatoare si irezistibila. O peruca cu par lung poate face minuni in viata ta de cuplu',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/missing-picture-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mangored-rooted.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/naomi_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/naomi_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/naomi_3.jpg'
        ]
      },
      {
        id: 214,
        title: 'Pretty',
        desc: 'Alege sa fii diferita azi! Creder-ne, vei simti diferenta! Parul tau nou va intoarce capete si va suci mintile',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/pretty_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pretty_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pretty_3.jpg'
        ]
      },
      {
        id: 215,
        title: 'Peruca cu breton Swing',
        desc: 'Fii cea mai buna versiune a ta! Un model indraznet, culori vii si azi poate fi un nou inceput! Peruca cu par scurt si breton asimetric disponibila la comanda intr-o paleta de 10 culori',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cosmoblack-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkespresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mangored-rooted.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/swing_1.jpg']
      },
      {
        id: 216,
        title: 'Vogue',
        desc: 'Un par mai lung in cateva secunde, o problema mai putin in fiecare zi.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand2tone-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/toffee2tone-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/vogue_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/vogue_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/vogue_3.jpg'
        ]
      },
      {
        id: 217,
        title: 'Peruca marime mica tul frontal Foxy Small',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/foxy_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/foxy_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/foxy_3_01.jpg'
        ]
      },
      {
        id: 218,
        title: 'Peruca breton modern asimetric Avola',
        desc: '',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Avola_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Avola_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Avola_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Avola_4.jpg'
        ]
      },
      {
        id: 219,
        title: 'Peruca mono part Rica',
        desc: 'Perucile din păr sintetic de la Ellen Wille sunt de cea mai bună calitate. Fibra noastră de păr sintetică simulează strălucirea naturală și sănătoasă și nu poate fi distinsă vizual de părul natural.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ashgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_nutmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rica_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rica_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rica_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rica_1.jpg'
        ]
      },
      {
        id: 220,
        title: 'Peruca bob filat nedetectabila pentru femei Miami',
        desc: 'Acest model de perucă cu par nu tocmai scurt, in trepte, ce îmbracă fruntea și mai ascunde din ani, propune un look tineresc, nonconformist. Culoarea din imagine este sandyblonde rooted dar dacă nu e tocmai genul tău, această perucă este disponibilă in 10 culori extrem de frumoase.  Ai de unde alege!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/miami.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/EXTENDED-LACE-MONOPART-WEFTED.jpg'
        ]
      },
      {
        id: 221,
        title: 'Peruca cu par scurt, sintetic pentru femei, nedetectabila Peru',
        desc: 'O frizura îngrijită, linii curate ce conturează un profil delicat. Doar așa ochii tai frumoși vor ieși in evidenta! Alege sa ignori trendurile si fii tu însăți! Știi ce se spune in domeniu: Less is More!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/peru_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/peru.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      },
      {
        id: 222,
        title: 'Pam Hi Tec',
        desc: 'Aceasta peruca reprezinta o piesa de rezistenta a acestei colectii. O peruca cu par drept, in scari, fin si sanatos, reaalizata din fibre atent selectionate.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/pam_hi_tec_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pam_hi_tec_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pam_hi_tec_3.jpg'
        ]
      },
      {
        id: 223,
        title: 'Peruca par sintetic wavy Touch',
        desc: 'Anul acesta coafurile tip bob sunt din ce in e mai populare.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/candyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cherry-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/touch_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/touch_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/touch_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      },
      {
        id: 224,
        title: 'Peruca cu par ondulat Cat',
        desc: 'Daca iti doresti o imagine lipsita de griji alege aceasta peruca wavy. O dezordine usoara, culori stralucitoare, toate degaja pofta de viata si bucuria momentului',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cat_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cat_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cat_3.jpg'
        ]
      },
      {
        id: 225,
        title: 'Ocean',
        desc: 'Nu exista pret prea mare pentru starea ta de bine. Nu iti mai tortura parul  doar de dragul schimbarilor. Alege o peruca cu par usor ondulat, super moderna si conserva-ti atuurile naturale',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ocean_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ocean_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ocean_3.jpg'
        ]
      },
      {
        id: 226,
        title: 'Peruca dama cu par scurt  Risk',
        desc: 'Daca vrei sa faci o schimbare radicala, iti propunem acest stil minimalist. Par super scurt, ai curajul sa fii cool. Nu vei regreta.',  
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_2.jpg'
        ]
      },
      {
        id: 227,
        title: 'Peruca dama marime mica Ginger small',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/coffeebrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger_small_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger_small_2.jpg'
        ]
      },
      {
        id: 228,
        title: 'Perucă monofilament bob cu breton, model Cri',
        desc: 'Peruca este confecționată din fibre sintetice rezintente la căldură, de ultimă generație. Se comportă extrem de bine în timp și permite  stilizări multiple. Vă puteți bucura de această culoare pentru o perioadă lungă.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/04/espresso_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/darkchocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/chocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/lightbernstein_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/sandyblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/champagne_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/cherryred_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/pastelrose_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/rosewood_perucci20-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cri_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cri_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cri_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/10/mono-wefted.jpg'
        ]
      },
      {
        id: 229,
        title: 'Peruca bob, din par sintetic, monofilament, nedetectabil, Sunset, Ellen Wille',
        desc: 'Peruca bob fârâ breton cu un look modern, care te scoate din anonimat.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti_mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Sunset_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Sunset_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Sunset_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Sunset_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Sunset_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      },
      {
        id: 230,
        title: 'Peruca Ava mono Part',
        desc: 'Peruca Ava are o coafura in tendinte foarte potrivita atat pentru zilele calduroase de vara cat si pentru sezonul rece cand gulerele inalte de la haine pot electriza parul de la spate. Tunsa scurt la spate, peruca este un bob scurt, filat care incadreaza frumos o fata rotunda.  Bretonul lung pierdut imbraca orice chip, indiferent de varsta. Peruca are monofilament pe carare si frunte nedetectabila. Perucile cu fruntea de matase sunt mai realiste in detaliu, nelasand sa se vada o montura grosolana. Parul pare sa creasca direct din scalp, exact ca in cazul parului natural.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_8.jpg'
        ]
      },
      {
        id: 231,
        title: 'Peruca par mediu French',
        desc: 'Denumirea acestei peruci spune totul: model peruca French.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/french_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/french_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/french_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-carare.jpg'
        ]
      },
      {
        id: 232,
        title: 'Blues',
        desc: 'Cum ar fi sa arati tot timpul ca ‘scoasa din cutie’? O coafura impecabila, culori vibrante si totul doar cu un singur click… Alege!',   
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cosmoblack-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotespresso-mix.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkcherry-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mangored-rooted.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/blues.jpg']
      },
      {
        id: 233,
        title: 'Peruca medie ondulata Girl Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hothazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/girl_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/girl_mono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/girl_mono_4.jpg'
        ]
      },
      {
        id: 234,
        title: 'Peruca Dance',
        desc: 'Peruca sintetică nedetectabilă cu monofilament pe cărare Dance asigură un stil estival ce amintește de vacanța la mare.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/04/espresso_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/chocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/mocca_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/lightbernstein_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/sand_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/sandyblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/lightchampagne_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/hotchilli_perucci20-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Dance_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Dance_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Dance_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Dance_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Dance_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      },
      {
        id: 235,
        title: 'Peruca par mediu tunsa in scari Ferrara Mono Part',
        desc: 'Peruca tunsa in scari este disponibila pe comanda intr-o gama larga de culori vii si moderne.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_black.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_granatred.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_nutmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_1.jpg'
        ]
      },
      {
        id: 236,
        title: 'Casino More',
        desc: 'Daca nu esti pregatita sa renunti la parul lung, poti sa faci tranzitia cu un par usor in scari cu breton amplu care sigur iti va flata   trasaturile. Peruca par mediu Casino More este unul dintre cele mai bine cotate',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/casino_more_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/casino_more_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/casino_more_3.jpg'
        ]
      },
      {
        id: 237,
        title: 'Peruca creata Jamila Plus',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/jamila_plus_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jamila_plus_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jamila_plus_4.jpg'
        ]
      },
      {
        id: 238,
        title: 'Limit',
        desc: 'Brandul Ellen Wille pune pret in mod special pe calitatea modelelor propuse. Se gandeste la confortul si la frumusetea ta. Tu de ce nu ai face-o?! Peruca par mediu Limit iti va veni bine cu siguranta',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/limit_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/limit_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/limit_3.jpg'
        ]
      },
      {
        id: 239,
        title: 'Peruca creata Lola more',
        desc: 'Parul lung de mult visat il poti avea acum. Surprinde-ti partenerul cu noua ta achizitie si fii cat mai sexy.Nu uita ca fetele buine ajung in rai iar fetele rele ajung unde vor….',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotcognac-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lola_more_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lola_more_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lola_more_1.jpg'
        ]
      },
      {
        id: 240,
        title: 'Peruca cu par drept, mediu, tuns in scari, mono part, tul frontal  Atlantic Mono',
        desc: 'RaQUEL Welch a fost si va ramane un model de frumusete. Ea a ales sa isi asocieze numele doar cu “frumusetea la superlativ”. Colectia ei de peruci este cunoscuta in toata lumea si apreciata de profesionistii din domeniu. In poza este prezentat modelul de peruca pe culoarea Bernstein rooted. Radacinile usor inchise sunt o tusa moderna ce confera naturalete. Parul incadreaza frumos fata, mangaie usor decolteul si sublimeaza o tinuta tanara. Peruca este confectionata din monofilament partial pe carare si frunte discreta. Nu te grabi, alege cu atentie! Daca timpul este dusmanul tau, da o fuga pana la magazin sa te convingi.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/atlantic_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-carare.jpg'
        ]
      },
      {
        id: 241,
        title: 'Peruca pentru femei cu par cret, pana la umeri, Hawaii',
        desc: 'O perucă fashion rămâne trucul tău secret în lupta cu capriciile vremii si trendurile momentului. Bucle ascultătoare, o cascadă de păr moale va garanta o prezență electrizantă. Nu vrei să treci neobservată? Alege modelul Hawaii – peruca cu par ondulat si vei arata de milioane!',    
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/hawaii.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/WEFTED-LACE-FRONT.jpg'
        ]
      },
      {
        id: 242,
        title: 'Peruca nedetectabila pentru femei, par sintetic, Lake Mono',
        desc: 'Fă din orice apariție o ocazie! Alege să strălucești! Niciodată nu te-au deranjat privirile indiscrete.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkcherry-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lake_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lake_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lake_mono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/LACE-WEFTED-MONO-PART.jpg'
        ]
      },
      {
        id: 243,
        title: 'Peruca creata Onda Mono part',
        desc: 'Un alt model care a cucerit deja doamnele este peruca carliontata Onda Mono. Cu o baza de monofilament partial pe carare prin care se vede efectiv parul cum creste direct din piele – efect extrem de realist, aceasta peruca propune un aspect tineresc si proaspat.  Peruca are tul frontal transparent si este foarte discreta. Culorile sunt din noua paleta cromatica 2021. Alege sa fii in moda! Uita de parul trist si fara viata! Perucile sunt accesoriile anului!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_creamblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_nutmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_platinblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_plumred.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_8.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_1.jpg'
        ]
      },
      {
        id: 244,
        title: 'Peruca par scurt cu breton Seven Super',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/seven_super_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/seven_super_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/seven_super_3.jpg'
        ]
      },
      {
        id: 245,
        title: 'Peruca din par sintetic lung pentru femei, mono part, tul frontal, Arrow',
        desc: 'Lasa-te inspirata de primavara si alege pentru tine o peruca cu par lung, spectaculoasa, usoara, cu par matasos. Viata noastra este in continua schimbare si nevoile noastre se schimba odata cu ea. E firesc sa cresti. Alege o peruca de colectie si uita de artificiile ieftine. Invata  sa te raspecti!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Arrow_mono_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Arrow_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Arrow_6-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Arrow_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Arrow_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Arrow_2_50-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Arrow_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/Arrow-3-ink.jpeg'
        ]
      },
      {
        id: 246,
        title: 'Peruca moderna par scurt, sintetic, cu breton lung asimetric Fenja',
        desc: 'Neconventional, atipic, scos din tipare. Asta esti tu? Foloseste-te de puterea modei. Un accesoriu ca aceasta peruca te scoate din situatii limita dar si din multime. Elibereaza-te!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/blackcherry-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/fenja_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fenja_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fenja_3.jpg'
        ]
      },
      {
        id: 247,
        title: 'Peruca Raquel Welch par scurt cu breton lung, asimetric, River Mono',
        desc: '“Eleganța este atunci când interiorul este la fel de frumos ca exteriorul” spunea Coco Chanel. Lasă-ne pe noi sa ne ocupăm de fizicul tău, tu alege sa fii frumoasa cu o perucă Raquel Welch de calitate, Bretonul amplu flatează orice fizionomie, cărarea de monofilament realistă, culori natural sau extravagante, ce mai, ai toate motivele sa apeși pe buton. Click si aceasta peruca poate fi a ta chiar mâine!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkespresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/river_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/river_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-fara-tul.jpg'
        ]
      },
      {
        id: 248,
        title: 'Peruca femei cu par drept, tuns in scari Michigan',
        desc: '– Tunsoarea in scări revine in forță in 2020. Fii o divă, trăiește din plin si nu te vei pierde niciodată in mulțime!.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown_mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/michigan_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/michigan_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/EXTENDED-LACE-MONOPART-WEFTED.jpg'
        ]
      },
      {
        id: 249,
        title: 'Peruca model Luna',
        desc: 'Perucile Ellen Wille sunt unul dintre cele mai cunoscute mărci din lumea perucilor datorită femeii care se află în spatele gamei.  Versatilitatea este ceea ce determină această colecție, deoarece gama vastă de stiluri, croiuri și culori vă oferă libertatea de a purta frizuri inspirate ca la salon în orice zi a săptămânii.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolatemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightchampagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_pastelrose.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_pastellmint.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_rosewood.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Luna_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Luna_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Luna_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Luna_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Luna_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Luna_1.jpg'
        ]
      },
      {
        id: 250,
        title: 'Peruca cu par lung ondulat Raquel Welch, L.A. Mono',
        desc: 'Nu te-ai obișnuit încă sa fii răsfățată de cei dragi? Acum e timpul! Cât timp părul tău arata minunat, restul sunt amănunte…',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/l_a_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/LACE-WEFTED-MONO-PART.jpg'
        ]
      },
      {
        id: 251,
        title: 'Peruca par lung drept Cher',
        desc: 'Bretonul cucereste toate podiumurile internationale. Fii in moda cu aceasta peruca cu par lung si renunta la inhibitii. Fibra rezistenta termic permite schimbari de look. Grozav! O singura peruca, multe optiuni de coafare',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cher_futura_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cher_futura_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cher_futura_3.jpg'
        ]
      },
      {
        id: 252,
        title: 'Peruca monofilament pentru femei, Flair Mono',
        desc: 'Nu uita ca frumusetea nu are varsta. Acum a venit momentul sa te gandesti si la tine. Fa o alegere inteligenta: alege ceva frumos si bun! Fii o sursa de inspiratie pentru cei dragi.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/flair_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flair_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flair_mono_3.jpg'
        ]
      },
      {
        id: 253,
        title: 'Peruca lunga wavy Glow',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mangored-rooted.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/glow_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/glow_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/glow_3.jpg'
        ]
      },
      {
        id: 254,
        title: 'Peruca cu par scurt pentru femei Risk Sensitive',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_sensitive_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_sensitive_2.jpg'
        ]
      },
      {
        id: 255,
        title: 'Smile Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/smile_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smile_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smile_mono_3.jpg'
        ]
      },
      {
        id: 256,
        title: 'Peruca marime mica Fenja small',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/fenja_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fenja_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fenja_3_01.jpg'
        ]
      },
      {
        id: 257,
        title: 'Peruca cu par ondulat Aria',
        desc: 'Acest tip de peruca are in crestet pe carare  o zonă de extrem de naturală, unde fiecare fir de păr a fost țesut individual.  Peruca arată deosebit de realist în zonele în care pielea este vizibilă, deoarece parul perucii pare să creasca direct din propriul scalp, la fel ca in cazul creșterii naturale a părului. Restul acestei peruci constă în mare parte dintr-o montura standard în care părul este cusut pe benzi fine de bumbac. Aceste trese se adaptează foarte bine la forma capului și permit aerului să circule.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_1-1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_8.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_9.jpg'
        ]
      },
      {
        id: 258,
        title: 'Storyville',
        desc: 'Acum e momentul sa fii frumoasa! Incarca-te de energia acestui anotimp, alege din peruci modelul care iti place cel mai mult si radiaza! Fericirea e contagioasa',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/gingerblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/storyville_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/storyville_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/storyville_3.jpg'
        ]
      },
      {
        id: 259,
        title: 'Lucky Hi',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucky_hi_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucky_hi_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucky_hi_3.jpg'
        ]
      },
      {
        id: 260,
        title: 'Peruca bob fara breton Narano mono part',
        desc: 'Peruca fara breton Naranao de Ellen Wille prin Hermax e conceputa pentru a vă oferi un spor fabulos de încredere. Gama de peruci Modixx  este renumită pentru calitatea, confortul și stilul luxos, cu colecția sa modernă de stiluri și culori.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolatemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivorygrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightchampagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_8.jpg'
        ]
      },
      {
        id: 261,
        title: 'Peruca monofilament pentru femei, Flair Mono',
        desc: 'Nu uita ca frumusetea nu are varsta. Acum a venit momentul sa te gandesti si la tine. Fa o alegere inteligenta: alege ceva frumos si bun! Fii o sursa de inspiratie pentru cei dragi.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/flair_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flair_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flair_mono_3.jpg'
        ]
      },
      {
        id: 262,
        title: 'Peruca lunga wavy Glow',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mangored-rooted.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/glow_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/glow_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/glow_3.jpg'
        ]
      },
      {
        id: 263,
        title: 'Peruca cu par scurt pentru femei Risk Sensitive',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_sensitive_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_sensitive_2.jpg'
        ]
      },
      {
        id: 264,
        title: 'Smile Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/smile_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smile_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smile_mono_3.jpg'
        ]
      },
      {
        id: 265,
        title: 'Peruca marime mica Fenja small',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/fenja_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fenja_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fenja_3_01.jpg'
        ]
      },
      {
        id: 266,
        title: 'Peruca cu par ondulat Aria',
        desc: 'Acest tip de peruca are in crestet pe carare  o zonă de extrem de naturală, unde fiecare fir de păr a fost țesut individual.  Peruca arată deosebit de realist în zonele în care pielea este vizibilă, deoarece parul perucii pare să creasca direct din propriul scalp, la fel ca in cazul creșterii naturale a părului. Restul acestei peruci constă în mare parte dintr-o montura standard în care părul este cusut pe benzi fine de bumbac. Aceste trese se adaptează foarte bine la forma capului și permit aerului să circule.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_1-1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_8.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_9.jpg'
        ]
      },
      {
        id: 267,
        title: 'Storyville',
        desc: 'Acum e momentul sa fii frumoasa! Incarca-te de energia acestui anotimp, alege din peruci modelul care iti place cel mai mult si radiaza! Fericirea e contagioasa',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/gingerblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/storyville_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/storyville_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/storyville_3.jpg'
        ]
      },
      {
        id: 268,
        title: 'Lucky Hi',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucky_hi_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucky_hi_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucky_hi_3.jpg'
        ]
      },
      {
        id: 269,
        title: 'Peruca bob fara breton Narano mono part',
        desc: 'Peruca fara breton Naranao de Ellen Wille prin Hermax e conceputa pentru a vă oferi un spor fabulos de încredere. Gama de peruci Modixx  este renumită pentru calitatea, confortul și stilul luxos, cu colecția sa modernă de stiluri și culori.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolatemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivorygrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightchampagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_8.jpg'
        ]
      },
      {
        id: 270,
        title: 'Peruca par ondulat eleganta Grace',
        desc: 'Fii tu cea care dicteaza moda! Creeaza curentul si lasa-te urmata de cei care te admira. Diponibila in combinatii de culori extrem de vii sau foarte naturale, cu efecte spectaculoase, aceasta peruca propune coafura wavy, stilul de pe buzele tuturor',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/grace_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/grace_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/grace_3.jpg'
        ]
      },
      {
        id: 271,
        title: 'Peruca bob cu breton Monza',
        desc: 'Perucile bob de la binecunoscutul brand Ellen Wille, un brand dedicat inspirării femeilor din întreaga lume cu colecțiile lor de peruci și stilurile lor moderne. Colecția de peruci Modixx prezintă un stil fără efort pentru un aspect de zi cu zi, toate perucile noastre emanând un aspect frumos natural prin tonuri gradate și culori înrădăcinate care reproduc aspectul creșterii naturale a părului. Răsfoiți prin opțiunile minunate de culoare, stiluri și lungimi pentru a găsi o perucă care vi se potrivește. Deoarece aceste peruci au fost create pentru purtarea zilnică, întreaga colecție este minunat de ușoară și confortabilă. Indiferent dacă doriți un model in scari  sau poate ceva mai lung, Colecția Modixx are câteva opțiuni de stil minunate. Peruca bob Monza are un breton care poate sa ascunda anumite imperfectiuni si unde mai pui ca bretonul este si in moda. Peruca bob lung din par sintetic prezinta pentru prima oara niste culori ombre extrem de reusite. Vino sa le vezi in magazin. Peruca are tul frontal  din dantela pentru perosanele care vor sa isi lase si fruntea libera. Indrazniti, doamnelor, indrazniti!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_rubyred.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_6.jpg'
        ]
      },
      {
        id: 272,
        title: 'Peruca bob fara breton Elite',
        desc: 'Rafinament si eleganta -macestea sunt doar doua cuvinte care vin in minte cand te gandesti la peruca Elite. Personalitatea si charisma radiaza din interior si parul frumos se asaza ca o aura. Esti frumoasa ca un inger',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_3.jpg'
        ]
      },
      {
        id: 273,
        title: 'Code Mono',
        desc: 'Feminina si eleganta, aceasta peruca te va aseza in centrul atentiei. Usor asimetrica, breton franjurat, linii simple, par super drept,  efect placa',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/code_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/code_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/code_mono_3.jpg'
        ]
      },
      {
        id: 274,
        title: 'Peruca bob lung Drive',
        desc: 'Peruca Drive propune un stil extrem de actual. Fibra sintetică de înaltă calitate rezistentă termic permite realizarea unui aspect cât mai natural. Peruca nedetectabilă disoune de un tul frontal transparent și monofilament pe cărare, aceste caracteristici permițându-i posesoarei un  aspect realist. culoarea din imagine este pearlblonde',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/04/espresso_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/darkchocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/chocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/bernstein_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/sandyblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/pearlblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/cherryred_perucci20-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_8-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_9-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_6-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      },
      {
        id: 275,
        title: 'Peruca Raquel Welch, manuala, model Reno Hi Comfort',
        desc: '– Într-o lume in continua mișcare, alege sa fi neschimbata. Naturală si rafinată, aceasta coafura te protejează de privirile indiscrete. Perucă nedetectabila, părul este inserat fir cu fir manual într-o montură confortabilă, fără trese incomode.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/reno_hi_comfort_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/reno_hi_comfort_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/reno_hi_comfort_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/EXT-LACE-MONO-WEFT.jpg'
        ]
      },
      {
        id: 276,
        title: 'Peruca bob scurt din fibra termorezistenta Aletta',
        desc: 'Acest tip de peruca are in crestet sau o zonă de extrem de naturală, unde fiecare fir de păr a fost țesut individual.  Peruca arată deosebit de realist în zonele în care pielea este vizibilă, deoarece parul perucii pare să creasca direct din propriul scalp, la fel ca in cazul creșterii naturale a părului. Restul acestei peruci constă în mare parte dintr-o montura standard în care părul este cusut pe benzi fine de bumbac. Aceste trese se adaptează foarte bine la forma capului și permit aerului să circule.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_rubyred.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_03.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_04.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_05.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_06.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_07.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_08.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_09.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_10.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_11.jpg'
        ]
      },
      {
        id: 277,
        title: 'Peruca mono cu par scurt ondulat Bari Mono',
        desc: '',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Bari_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Bari_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Bari_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_Bari_3.jpg'
        ]
      },
      {
        id: 278,
        title: 'Peruca par mediu usor ondulat Stella Mono Part',
        desc: 'Peruca Stella este o peruca usoara, bine ventilata si disponibila in culori foarte moderne. Fibra termorezistenta din care este aceasta  peruca confectionata are efect de memorie si pastreaza coafura pana la restilizare. Este posibila coafarea cu ajutorul unor scule profesionale cu care puteti controla temperatura pana in 130 grade. Se face prima oara un test pe o portiune mica de la ceafa pentru a va deprinde cu procedura. Prin monofilasmentul situat pe carare se vede exact pielea capului creind un efect realist. Peruca este nedetectabila deoarece are un tul frontal in dreptul bretonului permitand coafarea in lateral sau chiar pe spate.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_rosewood.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_sw.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_10.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_09.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_08.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_07.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_06.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_05.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_04.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_03.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_01.jpg'
        ]
      },
      {
        id: 279,
        title: 'Peruca sintetica dama cu par scurt, Island Mono, Raquel Welch',
        desc: 'Un păr tuns scurt, băiețește este foarte practic si comod mai ales in zilele calde de vara. Iar iarna, o peruca scurta va rezista mult mai bine întrucât nu este expusa electrizării prin frecarea de gulerele înalte sau de fulare. Atunci când comoditatea este pe primul plan, alege sa  te joci cu nuanțele in degradeuri sau cu un mix de tonuri opuse si vei obține un par vesel care iți va lumina toata ținuta. O peruca cu par scurt este ideala pentru femeile active, mereu in acțiune.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearlblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/island_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/island_mono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/LACE-WEFTED-MONO-PART.jpg'
        ]
      },
      {
        id: 280,
        title: 'Peruca manuala cu par scurt Risk Comfort',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_comfort_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/risk_comfort_3.jpg'
        ]
      },
      {
        id: 281,
        title: 'Peruca monofilament Sabbia Soft',
        desc: 'Draga mea, sa iti fac o confidenta: ai o personalitate minunata. Dar nu personalitatea se vede de la distanta!!! Alege o peruca care sa  te reprezinte pe tine, cea care esti acum. Ai ocazie sa faci ce nu a facut natura: sa alegi ceva pe gustul tau.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ashgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_creamblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SabbiaSoft_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SabbiaSoft_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SabbiaSoft_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SabbiaSoft_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_SabbiaSoft_1.jpg'
        ]
      },
      {
        id: 282,
        title: 'Smoke Hi Mono',
        desc: 'O investitie buna in imaginea ta si in sanatate! Bucura-te de imaginea din oglinda! Peruca model carre este nelipsita din garderoba multor vedete. Daca ei au ales-o, fa-o si tu!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke_hi_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke_hi_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke_hi_mono_3.jpg'
        ]
      },
      {
        id: 283,
        title: 'Peruca par mediu fara breton Nola Mono part',
        desc: 'Peruca cu par ondulat Nola are monofilament pe carrare si tul frontal transparent in fata pentru a fi cat mai realista.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_05.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_07.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_06.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_05.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_04.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_03.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_01.jpg'
        ]
      },
      {
        id: 284,
        title: 'Peruca Germania par scurt Alba Comfort',
        desc: 'Frumusete clasica si eleganta indiferent de ocazie. O peruca pentru o adevarata doamna. Aceasta peruca este prevazuta cu un tiv fin de matase pe care este inserat fir cu fir parul, metoda revolutionara si de mare efect care creeaza un aspect cat se poate de natural.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/alba_comfort_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alba_comfort_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alba_comfort_3.jpg'
        ]
      },
      {
        id: 285,
        title: 'Talia Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/blackcherry-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-tipped-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/talia_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/talia_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/talia_mono_3.jpg'
        ]
      },
      {
        id: 286,
        title: 'Peruca par mediu ondulat pentru femei Maine Mono',
        desc: 'Ultimul model lansat de Raquel Welch este de departe preferatul meu. Bucle ușoare, coiffe-decoiffe spun francezii, efect wavy. Fibra folosita permite coafarea la cald cu ajutorul unei dispozitiv performant, profesional, la temperaturi nu foarte înalte. Frunte discretă, vedere la cărare, păr in degradeuri ce copiază extrem de bine naturalul, PERUCA ASTA ESTE O PIESĂ. Plus 10 nuanțe in care poate fi comandată. Ce mai, ai 10 motive sa suni!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lavender-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/rosewood-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/maine_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/maine_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      },
      {
        id: 287,
        title: 'Peruca bob texturat Fresh',
        desc: 'Esential feminina si lejera, aceasta peruca bob va ramane piesa forte din tinuta ta de azi. Modelul acesta de peruca iti subliniaza trasaturile armonioase si iti da multa liberate in miscare',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/fresh_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fresh_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fresh_3.jpg'
        ]
      },
      {
        id: 288,
        title: 'Peruca clasica pentru femei, monofilament, Apart Mono',
        desc: 'Pentru o femeie a carei frumusete nu are varsta, propunem o peruca distinsa si permanent seducatoare. Aceasta peruca este prevazuta cu un film din matase pentru a recrea cat mai aproape de realitate cresterea parul uman.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksmoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/apart_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/apart_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/apart_mono_3.jpg'
        ]
      },
      {
        id: 289,
        title: 'Rich Mono',
        desc: 'Eleganţa este echilibrul intre proporţie, emoţie şi surpriza. [Valentino Garavani]',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hothazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/rich_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/rich_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/rich_mono_3.jpg'
        ]
      },
      {
        id: 290,
        title: 'Peruca monofilament pentru femei Fiore Soft',
        desc: 'Peruca monofilament cu par scurt, coafura clasica model Fire Soft are intreaga zonă a coroanei este formată din monofilament – o plasă care pare a fi invizibilă pe piele și unde părul este țesut individual într-o măiestrie fină. Restul acestei peruci constă în mare parte dintr-o tesatura în care părul este cusut cu talent pe benzi fine de bumbac. Montura perucii se adaptează foarte bine formei capului, și are o circulație a aerului foarte bună. Pe zona cararii ușor de văzut, monofilamentul realizat manual vă oferă o naturalețe deosebită.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_greymulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_peppergrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_FioreSoft_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_FioreSoft_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_FioreSoft_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_FioreSoft_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_FioreSoft_1.jpg'
        ]
      },
      {
        id: 291,
        title: 'Peruca femei nedetectabila Ever Mono',
        desc: 'Draga mea, sa iti fac o confidenta: ai o personalitate minunata. Dar nu personalitatea se vede de la distanta!!! Alege o peruca care sa te reprezinte pe tine, cea care esti acum. Ai ocazie sa faci ce nu a facut natura: sa alegi ceva pe gustul tau.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ever_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ever_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ever_mono_3.jpg'
        ]
      },
      {
        id: 292,
        title: 'Peruca monofilament par scurt Lima Hi Mono',
        desc: 'Un aspect fresh, luminos care te complimenteaza. Culorile in care este disponibila aceasta peruca sunt indraznete, calde, vibrante. In sufletul tau va fi mereu primavera!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lima_hi_mono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lima_hi_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mono-wefted-lacefront-hh.jpg'
        ]
      },
      {
        id: 293,
        title: 'Peruca monofilament penrtu femei Rimini Mono',
        desc: 'Pot fi ancorate suplimentar si cu ajutorul sistemelor de siguranta, de fixare pe care noi le comercializam.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_coffeebrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivorygrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rimini_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rimini_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rimini_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rimini_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Rimini_1.jpg'
        ]
      },
      {
        id: 294,
        title: 'Peruca confectionata manual Joy',
        desc: 'Parul tau e rebel si greu de stapanit? O abundenta de produse speciale si o multime de bani cheltuiti fara sa rezolvi problema? Cunoastem. Avem solutia pentru tine: o peruca din colectia de lux de la Ellen Wille, un accesoriu care va fi nelipsit din orice tinuta. O coafura plina de charme, pretioasa, pentru ocazii in care vrei sa te simti speciala.', 
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/joy_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Joy_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/joy_01.jpg'
        ]
      },
      {
        id: 295,
        title: 'Peruca bob pentru femei, monofilament, Idaho Mono',
        desc: 'Peruca Idaho Mono de la Raquel Welch este o perucă bob scurtă. Datorită monofilamentului pe cărare, unde firele de păr sunt inserate manual, fir cu fir, aceasta perucă imită creșterea naturală a părului și se îmbină cu tonurile pielii oferindu-ți cel mai natural aspect. Coafura perucii Idaho Mono este elegantă și clasică, dar totuși la moda. Această perucă scurtă este croită, aranjată si gata de purtat. Pentru a o personaliza, puteți fila ușor bretonul sau îl puteți purta după ureche. Idaho Mono este disponibil în zece mixuri de culori, printre care si o frumoasă nuanță grizonată!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/idaho_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      },
      {
        id: 296,
        title: 'Beach Mono',
        desc: 'Un model in care se imbina fericit inovatia si inspiratia. Arati si te simti bine! Peruca Beach Mono face moda',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown_mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/beach_mono_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/beach_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/beach_mono_3.jpg'
        ]
      },
      {
        id: 297,
        title: 'Peruca monofilament par sintetic Ginger Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/coffeebrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger_2_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger_3.jpg'
        ]
      },
      {
        id: 298,
        title: 'Peruca par scurt monofilament Only Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/only_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/only_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/only_mono_3.jpg'
        ]
      },
      {
        id: 299,
        title: 'Peruca sintetica cu par scurt Smart Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/smart_mono_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smart_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smart_mono_2_01.jpg'
        ]
      },
      {
        id: 300,
        title: 'Peruca femei confectionata manual  Dolce Soft',
        desc: 'O peruca confectionata manual este ca o mangaiere delicata pe crestet. Odata aplicata pe cap, pielea capului se vede prin montura semitransparenta creind un efect realist. Peruca are tul frontal extins oferind discretia de care vrei sa te bucuri cand alegi o peruca scumpa.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolatemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_creamblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_DolceSoft_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_DolceSoft_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_DolceSoft_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_DolceSoft_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_DolceSoft_1.jpg'
        ]
      },
      {
        id: 301,
        title: 'Peruca Noblesse Soft',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_creamblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_metallicblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_09.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_08.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_07.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_06.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_05.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_04.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_03.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_11.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_10.jpg'
        ]
      },
      {
        id: 302,
        title: 'Peruca sintetica par scurt monofilament Carol Mono',
        desc: 'O tunsoare mereu in moda, peruca cu par scurt accentueaza trasaturile tale frumoase si creeaza un look rafinat',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/carol_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/carol_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/carol_3.jpg'
        ]
      },
      {
        id: 303,
        title: 'Peruca discreta Bahamas Mono Part fibra termo',
        desc: 'Peruca Raquel Welch coafura modern realizata din monofilament paertial pe carare si tul frontal. Peruca Raquerl Welch cu par din fibra termorezistenta',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightespresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_bahamas_mono_2_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_bahamas_mono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_bahamas_mono_2_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_bahamas_mono_1.jpg'
        ]
      },
      {
        id: 304,
        title: 'Peruca confectionata manual Desire',
        desc: 'Nu lasa nimic la voia intamplarii! Provoaca! Intra in joc! Nimeni nu va stii ca porti o peruca. Alege acesta gama, pozele sunt autentice, conforme cu realitatea. Peruca vine frumos aranjata in cutia ei originala, vei gasi tot aolo si instructiunile pentru ingrijire pentru a te bucura cat mai mult de ea.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Desire.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Desire_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Desire_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Desire_2-ink.jpeg'
        ]
      },
      {
        id: 305,
        title: 'Peruca par scurt monofilament Mia Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/mia_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mia_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mia_mono_3.jpg'
        ]
      },
      {
        id: 306,
        title: 'Talent Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-tipped-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/talent_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/talent_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/talent_mono_4.jpg'
        ]
      },
      {
        id: 307,
        title: 'Peruca cu volum fibra termo Grenada Mono Part',
        desc: 'Peruca moderna realizata cu monofilament pe carare si tul frontal transparent. Peruca Raquerl Welch cu par din fibra termorezistenta',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_grenada_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_grenada_mono_2_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_grenada_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_grenada_mono_3.jpg'
        ]
      },
      {
        id: 308,
        title: 'Peruca tul frontal, par scurt pentru femei, Nebraska Comfort',
        desc: 'Frumusetea adevarata se recunoaste de departe. Nimic nu ascunde chipul tau minunat. Coafura par scurt a devenit o alegere usoara pentru femeile mereu in miscare. Finisajul de tip Confort te face sa te simti in elemental tau indifferent de vreme. Oricat ar fi de cald afara, coafura ta rezista si tu te simti libera.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/nebraska_comfort_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nebraska_comfort_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/EXTEND-LACE-COMFORT.jpg'
        ]
      },
      {
        id: 309,
        title: 'Peruca Raquel Welch pentru femei, cu par sintetic scurt, monofilament Boston Mono',
        desc: '–“O femeie care isi schimba coafura este o femeie pregatita sa isi schimbe viata” spunea Coco Chanel. Si Chanel este printre putinele femei care le-au facut pe femei fericite. Un par frumos, robust, sanatos nu rezolva automat orice problema din viata ta dar este un inceput promitator. O peruca cu par scurt, monofilament, nedetectabila, disponibila in nuante si suvite de invidiat, ideal pentru o persoana dinamica.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcoffe-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/boston_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/boston_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mono-wefted-lacefront-hh.jpg'
        ]
      },
      {
        id: 310,
        title: 'Queen Comfort',
        desc: 'Frumusetea se vede cu ochiul liber dar calitatea se verifica in timp! Noi avem din toate. Alege o peruca de calitate Premium si ve intelege exact la ce ma refer',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/queen_comfort_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/queen_comfort_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/queen_comfort_4.jpg'
        ]
      },
      {
        id: 311,
        title: 'Peruca super usoara lux Fame',
        desc: 'Noi stim ca tu esti unica. Am pregatit ceva pentru tine. Peruca asta arata exact ca parul tau. Nimeni nu va face diferenta. Va ramane micul nostru secret.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Fame.jpg']
      },
      {
        id: 312,
        title: 'Peruca petru femei monofilament Date Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/date_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/date_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/date_4.jpg'
        ]
      },
      {
        id: 313,
        title: 'Peruca par scurt monofilament Flip Mono',
        desc: 'Eleganţa nu inseamna sa ieşi in evidenţa, ci sa intri in amintire. [Giorgio Armani]',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/flip_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flip_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flip_mono_3.jpg'
        ]
      },
      {
        id: 314,
        title: 'Shine Comfort',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/shine_comfort_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/shine_comfort_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/shine_comfort_3.jpg'
        ]
      },
      {
        id: 315,
        title: 'Peruca Raquel Welch import Germania model Aspen Mono',
        desc: 'O frizura pixie cu volum este foarte populara intrucat indulceste trasaturile si daca este combinata in 2 tonuri, aduce un plus de culoare oricarei tinute. Accesorizeaza-te cu o peruca blonda (sau de ce nu, roscata) si vei vedea diferenta. Modelul acesta reprezinta o peruca monofilament, ceeavce inseamna ca este mult mai bine ventilata, mai usoara si  mai confortabila decat varianta standard.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/aspen_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mono-wefted.jpg'
        ]
      },
      {
        id: 316,
        title: 'Peruca nedetectabila pentru femei Gemma Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/gemma_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/gemma_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/gemma_mono_3.jpg'
        ]
      },
      {
        id: 317,
        title: 'Peruca femei cu par scurt Run Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown_mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotcinnamon-mix.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/run_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/run_mono_1.jpg'
        ]
      },
      {
        id: 318,
        title: 'Peruca femei cu par scurt si breton asimetric Trend Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-tipped-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/trend_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/trend_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/trend_mono_3.jpg'
        ]
      },
      {
        id: 319,
        title: 'Peruca cu par scurt si breton Fair Mono',
        desc: 'Simplitatea este cheia eleganţei adevarate. [Coco Chanel]',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/fair_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fair_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fair_3.jpg'
        ]
      },
      {
        id: 320,
        title: 'Peruca scurta Raquel Welch, Peru Mono, tul frontal',
        desc: 'Păr scurt, culori calde, finisaj de calitate, colecție de peruci de brand…. Sa mai continui?! Lista e lungă. Toate acestea sunt motive sa alegi peruca monofilament Peru Mono',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksnow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/peru_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/peru_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mono-wefted-lacefront-hh.jpg'
        ]
      },
      {
        id: 321,
        title: 'Mega Mono',
        desc: 'Parul lung este atuul femeii care se trezeste cu zambetul pe buze, Alege sa iti faci un cadou tie de care sa se bucure deopotriva EL.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/mega_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mega_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mega_mono_3.jpg'
        ]
      },
      {
        id: 322,
        title: 'Peruca sintetica dama, par scurt, volum, Indiana Mono',
        desc: 'Aceasta este una din tunsorile must-have ale vârstei +50 . Alege sa fii centrul atenției! Bucură-te de CALITATEA monofilamentului si de o coafura top din colecția Raquel Welch. Contactează-ne și comandă! Timpul trece repede… tic-tac! Daca nu mai ai răbdare, dă o fuga pana la noi în magazin și încearcă-o. Vino și cu o prietena care să iți facă poze si  sa te susțină. Nu-i așa că arata chiar mai bine când o probezi?',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnutmix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/indiana_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/EXT-LACE-MONO-WEFT.jpg'
        ]
      },
      {
        id: 323,
        title: 'Peruca Raquel Welch cu par scurt, Panama Hi Mono',
        desc: 'Peruca asta de la Raquel Welch este pur si simplu MI-NU-NA-TA! Perucile monofilament sunt mult mai bine ventilate, discrete si fruntea din tul garanteaza efectul realist.  E usoara si pufoasa, degetele aluneca usor prin par, bretonul are un tangaj natural. Gama de culori este generoasa oferind o paleta versatila, de la saten inchis pana la grizon alb ca zapada. Modelul din imagine este in culoarea sandyblonde, o nuanta pe care greu reusesti sa o obtii in salon. Daca parul a fost intotdeauna o preocupare pentru tine, de ce ar fi diferit tocmai acum? “Frumusetea incepe in momentul in care ai hotarat sa fii tu insati” – Coco Chanel',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcoffee-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/panama_hi_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/panama_hi_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/EXT-LACE-MONO-WEFT.jpg'
        ]
      },
      {
        id: 324,
        title: 'Peruca par scurt nedetectabila mono Bo Mono',
        desc: 'Peruca cu par scurt cu breton – o coafura simpla cu care mergi la sigur. Peruca tip monofilament asigura o ventilatie suficienta pentru a te bucura chiar si in zilele calde de vara. Incearca!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotespresso-mix.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/bo_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bo_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bo_mono_3.jpg'
        ]
      },
      {
        id: 325,
        title: 'Marusha Mono',
        desc: 'Efect WoW garantat! Par lung si matasos, senzational. Traieste intens fiecare secunda si bucura-te de calitatea monofilament a acestui produs',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-lighted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/marusha_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/marusha_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/marusha_mono_3.jpg'
        ]
      },
      {
        id: 326,
        title: 'Peruca moderna par scurt Gilda Mono',
        desc: 'Peruca medicala confectionata pe baza de monofilament cu tul frontal pentru a fi cat mai discreta si realista.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_coffeebrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_iceblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Gilda_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Gilda_8.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Gilda_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Gilda_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Gilda_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Gilda_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Gilda_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Gilda_2.jpg'
        ]
      },
      {
        id: 327,
        title: 'Peruca de lux nedetectabila Cara 100 Deluxe',
        desc: 'Confort pur. Nu cauta mai departe! Daca linia tunsorii te reprezinta, daca nu esti timida si iti place sa fii in centrul atentiei, atunci aceasta peruca cu par scurt este  pentru tine!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cara_deluxe_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cara_deluxe_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cara_deluxe_3.jpg'
        ]
      },
      {
        id: 328,
        title: 'Peruca sintetica dama cu par mediu usor ondulat, California Mono',
        desc: 'Un best seller al colectiei Raquel Welch acum reinterpretat. Suvitele de par usor dezordonate, un look intentionat adolescentin amintesc de o vara fierbinte, mare, briza si cate si mai cate….Alege peruca California Mono si nu vei mai avea nevoie de filtre! Vei avea un selfie perfect. Si nu uita: Cand nu esti sigura, alege BLOND!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown_mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/california_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/california_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/california_mono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      },
      {
        id: 329,
        title: 'Peruca de lux pentru femei East Luxury',
        desc: 'V- am spus ca la Raquel Welch luxul este numitor comun? Perucile de calitate trebuie sa fie usoare, bine ventilate, discrete, cu par moale, matasos. Iar perucile de lux corespund celor mai exigente criterii. Perucile au cateva zeci de grame, sunt usoare si delicate. Nu trebuie sa pierdeti din vedere ca sunt un accesoriu si se comporta in timp direct proportional cu timpul, banii si atentia cu care v-ati ocupat de ea. Trebuie sa aveti asteptari realiste insa. Nu rezista unei torsiuni extreme, sunt ca o bijuterie, un décor de cap.   Este suficient spre exemplificare sa va ganditi la un ciorap fin de matase. Unul dintre multiplele avantaje ale monturii tip monofilament este ca se poate schimba cararea in fuctie de obisnuinta. Este dificil de inteles cat suntem tributari unui gest reflex de tipul alegerea cararii dar aceasta nu este deloc intamplatoare. Bretonul pe o parte (sau alta) poate ajuta la mascarea unei mici imperfectiuni (cicatrice) sau poate favoriza profilul nostru bun (stangul, de regula). Astfel, oprtunitatile de aranjare sunt multiple, putem alege chiar sa ne aranjam frumos parul dupa ureche sau cu ajutorul unei cleme, sa dam parul pe spate. Peruca bob al carei par se opreste exact la nivelul maxilarului este extrem de actuala, lasand gatul expus. In modul acesta, se evita frictiunile repetate cu textile (guler etc) parul nu se mai electrizeaza, eliminand riscul degradarii ei pe lungime atunci cand parul se mai incurca la spate. Perucile de lux fac priza perfecta cu scalpul, este indicat sa fie aplicate direct pe piele avand o marime mai mica decat perucile fashion. Culoarea din poza: sandyblonde.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/east_luxury.jpg'
        ]
      },
      {
        id: 330,
        title: 'Peruca de lux, brand international, Broadway Luxury',
        desc: 'Oricare dintre perucile Raquel Welch este sinonima cu Calitatea Premium dar acest model vizeaza excelenta. Cautai un accesoriu pentru o ocazie? Investeste in parul tau, este singurul accesoriul prezent in orice tinuta. Peruca monofilament realizata 100% manual are in dreptul fruntii un tul perfect transparent in care firele de par sunt inserate fir cu fir pentru un look cat mai aproape de original. Parul moale, fin, este tuns in scari, culoarea din imagine este lightcaramel rooted dar daca nu este culoarea ta, nu dispera, o gama  de culori naturaledar si nuante din cele mai vibrante te asteapta doar la un click distanta.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg'
        ],
        photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/broadway_luxury.jpg']
      },
      {
        id: 331,
        title: 'Peruca de lux par scurt ondulat Malibu Luxury',
        desc: 'Luxul in doze mici nu poate face rău nimănui, nu-i așa? Răsfață-te! De ce te-ai mulțumi cu puțin?! O perucă monofilament realizata integral manual, cu tul in fata pentru a putea să-ți aranjezi părul cum vrei, cu sau fără breton, in funcție de dispoziție. Părul este realizat dintr-o fibra sintetică de ultima tehnologie, calitate Germania. Oricine își permite ar alege colecția de peruci de lux de la Raquel Welch.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/malibu_luxury_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/malibu_luxury_2.jpg'
        ]
      },
      {
        id: 332,
        title: 'Peruca Piemonte Super',
        desc: 'Peruca de lux confectionata manual. Pentru a afla detalii despre acest tip de finisaj, accesati sectiunea dedicata din Sfaturi. Peruca bob cu breton lung franjurat montat  inn tul frontal din dantela.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolatemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_5.jpg'
        ]
      },
      {
        id: 333,
        title: 'Wave Deluxe',
        desc: 'Adevarata frumusete vine din interior. Asorteaza aceasta peruca unui caracter puternic si unui suflet frumos. Acum ai o tinuta princiara chiar!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/wave_deluxe_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wave_deluxe_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wave_deluxe_4.jpg'
        ]
      },
      {
        id: 334,
        title: 'Peruca monofilament, nedetectabila pentru femei Nebraska Mono',
        desc: 'Raquel Welch, star hollywoodian ce se identifica cu frumusetea, revine cu un model de peruca modern si propune o frizura scurta in trepte lasand linia gatului expusa, potrivita pentru femei sigure pe ele',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/nebraska_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mono-wefted-lacefront-hh.jpg'
        ]
      },
      {
        id: 335,
        title: 'Peruca lux London Super',
        desc: 'Aceasta peruca d elux este o opera de arta. fiecare fir de par este cusut manual pe o baza semitransparenta in asa fel incat pielea capului sa poata respira corespunzator  si peruca sa aiba un aspect cat mai realist. Peruca are densitatea corect distribuita similar unei persoane fara probleme. Parul aluneca matasos in culori vibrante, Ingrijit cum se cuvine , puteti sa va bucurati mult timp de aceasta peruca.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_LondonSuper_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_LondonSuper_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_LondonSuper_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_LondonSuper_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_LondonSuper_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_LondonSuper_1.jpg'
        ]
      },
      {
        id: 336,
        title: 'Peruca par lung monofilament Affair',
        desc: 'Timpul trece repede dar un singur moment poate face diferenta. Poate sa iti schimbe viata. Traieste frumos si alege sa iti faci viata cat mai buna. De parul tau ne ocupam  noi deocamdata.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Affair.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HairSociety_Affair_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Affair_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Affair_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Affair_1-ink.jpeg'
        ]
      },
      {
        id: 337,
        title: 'Peruca lux Bloom',
        desc: 'O coafura ampla, in scari, versatila. O peruca de 5*. Vino sa te convingi de calitatea Premium direct in magazin. Aceasta colectie intruneste toate conditiile: Fibra calitate lux, baza de monofilament in care parul este inserat fir cu fir, manual, pentru un tangaj firesc, parul se misca natural. Peruca este nedetectabila.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkpearl-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Bloom_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Bloom_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Bloom_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Bloom_3-ink.jpeg'
        ]
      },
      {
        id: 338,
        title: 'Peruca scurta breton asimetric lux First',
        desc: 'Alege sa fii in moda cu aceasta peruca cu breton lung, franjurat, asimteric. Pentru un selfie perfect, fara filtre.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_First_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_First_9-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_First_8-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_First_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_First_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_First_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_First_01.jpg'
        ]
      },
      {
        id: 339,
        title: 'Peruca nedetectabila de lux Icone',
        desc: 'Un moment de placere facut sa dureze cat vrei tu! Un bob delicat, culori naturale, peruca monofilament realizata integral manual, toate astea doar pentru tine. Pentru ca tu meriti ce e mai bun!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Icone_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Icone_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Icone_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Icone_4-ink.jpeg'
        ]
      },
      {
        id: 340,
        title: 'Peruca de lux Star',
        desc: 'O linie atent elaborata pentru un aspect cat mai natural. Confortabil. E timpul sa vezi diferenta. Calitatea germana nu este un mit. Nimeni nu va sti ca porti o peruca, pana si tu vei uita.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Star.jpg'
        ]
      },
      {
        id: 341,
        title: 'Peruca lux fibra termo Trinidad Luxury',
        desc: 'Peruca de lux realizata din monofilament integral manual. Peruca Raquerl Welch cu par din fibra termorezistenta',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_trinidad_luxury_1_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_trinidad_luxury_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_trinidad_luxury_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_trinidad_luxury_1_1.jpg'
        ]
      },
      {
        id: 342,
        title: 'Peruca par natural prime Amaze',
        desc: 'In urma unei atente selectii, peruca Amaze a fost desemnata castigatoare! Modern si sofisticat, aceasta frizura e un statement pentru orice femeie puternica. O recomanda atat calitatile finisajului interior cat si aspectul in trend.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/amaze_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/amaze_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/amaze_4.jpg'
        ]
      },
      {
        id: 343,
        title: 'Peruca par scurt dama Focus',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/naturewhite-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/06/focus_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/06/focus_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/06/focus_2.jpg'
        ]
      },
      {
        id: 344,
        title: 'Peruca scurta naturala Award',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/award_1_03.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/award_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/award_3_01.jpg'
        ]
      },
      {
        id: 345,
        title: 'Peruca dama bob Catch',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nutbrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/06/catch_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/06/catch_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/06/catch_3.jpg'
        ]
      },
      {
        id: 346,
        title: 'Peruca par natura prime Illusion',
        desc: 'Cuvintele sunt de prisos. Imaginea vorbeste de la sine. Un par lung asa cum ai visat, matasos si des, o senzatie de mangaiere calda pe umeri, o bucurie fara margini. Te incanta? Peruca Illusion vine la un pret atractiv azi, doar pentru tine.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/illusion.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/illusion_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/illusion_3.jpg'
        ]
      },
      {
        id: 347,
        title: 'Peruca par natural prime Spirit',
        desc: 'un bob jucaus, plete care zburda in jurul tau si te fac sa zambesti. Ai frumusetea la purtator! O peruca este nelipsita din garderoba unei femei! Nu cauta scuze sa te simti frumoasa si atragatoare',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/spirit.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spirit_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spirit_4.jpg'
        ]
      },
      {
        id: 348,
        title: 'Peruca naturala cu breton Flavour',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/flavour_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flavour_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flavour_3.jpg'
        ]
      },
      {
        id: 349,
        title: 'Peruca naturala par mediu Inspire',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/inspire_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/inspire_2.jpg'
        ]
      },
      {
        id: 350,
        title: 'Peruca naturala bob Wish',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearlgrey-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/wish_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wish_2.jpg'
        ]
      },
      {
        id: 351,
        title: 'Peruca naturala cu par lung Passion',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/passion_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/passion_2.jpg'
        ]
      },
      {
        id: 352,
        title: 'Peruca par natural european Juvia',
        desc: 'Peruca din par natural european calitate remy, confectionata pe o baza din monofilament, cu bordura de dantela transparenta- front lace.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/03/juvia-4-3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/juvia-4-3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/juvia-4-1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/juvia-4-2.jpg'
        ]
      },
      {
        id: 353,
        title: 'Peruca de lux din par natural Delicate Plus',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/delicate_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/delicate_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/delicate_3_01.jpg'
        ]
      },
      {
        id: 354,
        title: 'Peruca silicon din par natural remy Trinity Plus',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/softcopper-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/trinity_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/trinity_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/trinity_3_01.jpg'
        ]
      },
      {
        id: 355,
        title: 'Peruca remy naturala cu par lung Emotion',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/emotion_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/emotion_1_01.jpg'
        ]
      },
      {
        id: 356,
        title: 'Peruca cu silicon din  par natural remy Spectra Plus',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/spectra_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spectra_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spectra_3.jpg'
        ]
      },
      {
        id: 357,
        title: 'Peruca naturala cu par lung Cascade',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cascade_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cascade_2_01.jpg'
        ]
      },
      {
        id: 358,
        title: 'Peruca din par lung natural remy Obsession',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/obsession_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/obsession_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/obsession_3_05.jpg'
        ]
      },
      {
        id: 359,
        title: 'Peruca sintetica Bravo',
        desc: 'Acest tip de montura propune un finisaj realist prin efectul de vartej din crestetul capului.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/bravo_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bravo_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-carare.jpg'
        ]
      },
      {
        id: 360,
        title: 'Peruca sintetica bob scurt Game',
        desc: 'Daca te identifici cu stilul, reusesti sa fii in moda si sa fii mereu surprinzatoare.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/game_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/game_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/game_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/game_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      },
      {
        id: 361,
        title: 'Peruca moderna Hot',
        desc: 'O frizura moderna, usor extravaganta chiar, asimetrica, exact asa cum se poarta! Bretonul lung asimetric da senzualitate si mister unei siluete extrem de feminine.',      
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/hot_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hot_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hot_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      },
      {
        id: 362,
        title: 'Peruca par ondulat sintetic Shuffle',
        desc: 'Celebrele bucle lejere, cu un aspect relaxat si sexy, se mentin de multi ani in tendinte.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/shuffle_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/shuffle_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      },
      {
        id: 363,
        title: 'Peruca par scurt Snap',
        desc: 'Snap are o montura monofilament partial pentru o senzatie usoara si confortabila.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/snap_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snap_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snap_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      },
      {
        id: 364,
        title: 'Peruca artificiala de calitate Spark',
        desc: 'Perucile sintetice de la Ellen Wille sunt confectionate din fibre de cea mai inalta calitate, oferind un aspect mai fin, incredibil de realist si imita parul natural.',   
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/spark_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spark_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spark_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      },
      {
        id: 365,
        title: 'Peruca par scurt Step',
        desc: 'Step by Ellen Wille face parte din Colectia CHANGES din 2019.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/step_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/step_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/step_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/step_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      },
      {
        id: 366,
        title: 'Peruca par mediu mono Charisma',
        desc: 'Nu ai curaj sa comanzi online? Te asteptam in magazin. Aceasta colectie este destinata cunoscatorilor. Daca nu ai purtat niciodata o peruca de lux, cere detalii online sau telefonic. Model peruca de lux Charisma face parte din Colectia Hairsociety. Toate produsele din aceasta colectie sunt calificate ca dispozitive medicale. Peruca este foarte usoara, foarte bine ventilata.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/charisma.jpg'
        ]
      },
      {
        id: 367,
        title: 'Peruca par lung sintetic lux Fortune',
        desc: 'Clipa, opreste-te! Esti minunata! Parul tau nu iti va mai rezerva surprize. Avem noi grija de asta. Accesoriul tau preferat te asteapta!',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Fortune.jpg'
        ]
      },
      {
        id: 368,
        title: 'Peruca frizura garcon Next',
        desc: 'Perucile revin in forta! Peruci ieftine, peruci scumpe, peruci de calitate, peruci de lux sau peruci chinezesti, toate sunt in trend.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/next_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/next_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/next_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/next_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/next_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/next_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      }
    ],
    "Peruci Femei Par Lung":[
      {
        id: 369,
        title: 'Peruca par lung Impress',
        desc: 'Armele secrete ale vedetelor sunt acum la dispozitia tuturor! Vino sa probezi accesoriile de ultima ora care pana acum au fost rezervate doar celebritatilor! Perucile au coborat de pe podium direct in magazine.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ebony-black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/impress_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/impress_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/impress_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      }
      ,
      {
        id: 370,
        title: 'Peruca sintetica femei cu par lung, brand Ellen Wille, Carrie',
        desc: '„Singurul lucru constant din viata ta este schimbarea.Nu ai cum sa fugi de schimbare, te va gasi, iti va arunca in fata provocari si te va forta sa iti reconsideri modul in care iti traiesti viata”. Alege sa faci o schimbare in bine. Alege calitate: par lung, fin, rezistent in timp montat intr-o calota moale si usor de purtat. Noi te ajutam sa fii la fel de frumoasa! Sau si mai si!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ebony-black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/esspreso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Carrie_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Carrie_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Carrie_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Carrie_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Carrie_1-ink.jpeg'
        ]
      }
      ,
      {
        id: 371,
        title: 'Peruca ondulata Alive',
        desc: 'Perucile din par sintetic marca Ellen Wille sunt de cea mai buna calitate.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/alive_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alive_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alive_3_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-fara-tul.jpg'
        ]
      }
      ,
      {
        id: 372,
        title: 'Peruca păr lung fibră rezistentă termic Cloud',
        desc: 'Fibra rezistentă termic își păstrează forma inițială, dar, dacă se dorește, ea poate fi restilizată cu ajutorul plăcii de îndreptat sau a ondulatorului cu o temperatură de până la 140 de grade Celsius.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/04/espresso_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/darkchocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/chocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/sand_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/sandyblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/champagne_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/cherryred_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/pastelrose_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/rosewood_perucci20-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cloud_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cloud_6-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cloud_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cloud_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cloud_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cloud_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/10/mono-parti-mini-lace-wefted.jpg'
        ]
      }
      ,
      {
        id: 373,
        title: 'Naomi',
        desc: 'Nu ai nevoie de ocazii ca sa te rasfeti. Alege sa fii cocheta in fiecare zi, seducatoare si irezistibila. O peruca cu par lung poate face minuni in viata ta de cuplu',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/missing-picture-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mangored-rooted.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/naomi_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/naomi_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/naomi_3.jpg'
        ]
      }
      ,
      {
        id: 374,
        title: 'Pretty',
        desc: 'Alege sa fii diferita azi! Creder-ne, vei simti diferenta! Parul tau nou va intoarce capete si va suci mintile',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/pretty_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pretty_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pretty_3.jpg'
        ]
      }
      ,
      {
        id: 375,
        title: 'Vogue',
        desc: 'Un par mai lung in cateva secunde, o problema mai putin in fiecare zi.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand2tone-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/toffee2tone-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/vogue_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/vogue_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/vogue_3.jpg'
        ]
      }
      ,
      {
        id: 376,
        title: 'Pam Hi Tec',
        desc: 'Aceasta peruca reprezinta o piesa de rezistenta a acestei colectii. O peruca cu par drept, in scari, fin si sanatos, reaalizata din fibre atent selectionate.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/pam_hi_tec_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pam_hi_tec_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pam_hi_tec_3.jpg'
        ]
      }
      ,
      {
        id: 377,
        title: 'Ocean',
        desc: 'Nu exista pret prea mare pentru starea ta de bine. Nu iti mai tortura parul  doar de dragul schimbarilor. Alege o peruca cu par usor ondulat, super moderna si conserva-ti atuurile naturale',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ocean_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ocean_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ocean_3.jpg'
        ]
      }
      ,
      {
        id: 378,
        title: 'Peruca creata Lola more',
        desc: 'Parul lung de mult visat il poti avea acum. Surprinde-ti partenerul cu noua ta achizitie si fii cat mai sexy.Nu uita ca fetele buine ajung in rai iar fetele rele ajung unde vor….',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotcognac-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lola_more_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lola_more_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lola_more_1.jpg'
        ]
      }
      ,
      {
        id: 379,
        title: 'Peruca pentru femei cu par cret, pana la umeri, Hawaii',
        desc: 'O perucă fashion rămâne trucul tău secret în lupta cu capriciile vremii si trendurile momentului. Bucle ascultătoare, o cascadă de păr moale va garanta o prezență electrizantă. Nu vrei să treci neobservată? Alege modelul Hawaii – peruca cu par ondulat si vei arata de milioane!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/hawaii.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/WEFTED-LACE-FRONT.jpg'
        ]
      }
      ,
      {
        id: 380,
        title: 'Peruca din par sintetic lung pentru femei, mono part, tul frontal, Arrow',
        desc: 'Lasa-te inspirata de primavara si alege pentru tine o peruca cu par lung, spectaculoasa, usoara, cu par matasos. Viata noastra este in continua schimbare si nevoile noastre se schimba odata cu ea. E firesc sa cresti. Alege o peruca de colectie si uita de artificiile ieftine. Invata sa te raspecti!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-lighted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Arrow_mono_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Arrow_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Arrow_6-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Arrow_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Arrow_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Arrow_2_50-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Arrow_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/Arrow-3-ink.jpeg'
        ]
      }
      ,
      {
        id: 381,
        title: 'Peruca femei cu par drept, tuns in scari Michigan',
        desc: '– Tunsoarea in scări revine in forță in 2020. Fii o divă, trăiește din plin si nu te vei pierde niciodată in mulțime!.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown_mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/michigan_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/michigan_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/EXTENDED-LACE-MONOPART-WEFTED.jpg'
        ]
      }
      ,
      {
        id: 382,
        title: 'Peruca model Luna',
        desc: 'Perucile Ellen Wille sunt unul dintre cele mai cunoscute mărci din lumea perucilor datorită femeii care se află în spatele gamei.  Versatilitatea este ceea ce determină această colecție, deoarece gama vastă de stiluri, croiuri și culori vă oferă libertatea de a purta frizuri inspirate ca la salon în orice zi a săptămânii.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolatemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightchampagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_pastelrose.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_pastellmint.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_rosewood.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Luna_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Luna_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Luna_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Luna_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Luna_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Luna_1.jpg'
        ]
      }
      ,
      {
        id: 383,
        title: 'Peruca cu par lung ondulat Raquel Welch, L.A. Mono',
        desc: 'Nu te-ai obișnuit încă sa fii răsfățată de cei dragi? Acum e timpul! Cât timp părul tău arata minunat, restul sunt amănunte…',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/l_a_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/LACE-WEFTED-MONO-PART.jpg'
        ]
      }
      ,
      {
        id: 384,
        title: 'Peruca par lung drept Cher',
        desc: 'Bretonul cucereste toate podiumurile internationale. Fii in moda cu aceasta peruca cu par lung si renunta la inhibitii. Fibra rezistenta termic permite schimbari de look. Grozav! O singura peruca, multe optiuni de coafare',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cher_futura_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cher_futura_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cher_futura_3.jpg'
        ]
      }
      ,
      {
        id: 385,
        title: 'Peruca lunga wavy Glow',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mangored-rooted.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/glow_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/glow_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/glow_3.jpg'
        ]
      }
      ,
      {
        id: 386,
        title: 'Peruca cu par ondulat Aria',
        desc: 'Acest tip de peruca are in crestet pe carare  o zonă de extrem de naturală, unde fiecare fir de păr a fost țesut individual.  Peruca arată deosebit de realist în zonele în care pielea este vizibilă, deoarece parul perucii pare să creasca direct din propriul scalp, la fel ca in cazul creșterii naturale a părului. Restul acestei peruci constă în mare parte dintr-o montura standard în care părul este cusut pe benzi fine de bumbac. Aceste trese se adaptează foarte bine la forma capului și permit aerului să circule.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_1-1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_8.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AriaMono_9.jpg'
        ]
      }
      ,
      {
        id: 387,
        title: 'Storyville',
        desc: 'Acum e momentul sa fii frumoasa! Incarca-te de energia acestui anotimp, alege din peruci modelul care iti place cel mai mult si radiaza! Fericirea e contagioasa',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/gingerblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/storyville_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/storyville_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/storyville_3.jpg'
        ]
      }
      ,
      {
        id: 388,
        title: 'Code Mono',
        desc: 'Feminina si eleganta, aceasta peruca te va aseza in centrul atentiei. Usor asimetrica, breton franjurat, linii simple, par super drept, efect placa',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/code_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/code_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/code_mono_3.jpg'
        ]
      }
      ,
      {
        id: 389,
        title: 'Peruca dama par lung Glamour Mono',
        desc: 'Tocurile te ridica, cultura te inalţa, eleganţa te transforma. [Kathleen Tessaro]',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/glamour_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/glamour_mono_2.jpg'
        ]
      }
      ,
      {
        id: 390,
        title: 'Miley small Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightespresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/miley_small_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/miley_small_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/miley_small_3.jpg'
        ]
      }
      ,
      {
        id: 391,
        title: 'Peruca Noblesse Soft',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_creamblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_metallicblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_09.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_08.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_07.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_06.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_05.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_04.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_03.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_11.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NoblesseSoft_10.jpg'
        ]
      }
      ,
      {
        id: 392,
        title: 'Mega Mono',
        desc: 'Parul lung este atuul femeii care se trezeste cu zambetul pe buze, Alege sa iti faci un cadou tie de care sa se bucure deopotriva EL.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/mega_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mega_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mega_mono_3.jpg'
        ]
      }
      ,
      {
        id: 393,
        title: 'Marusha Mono',
        desc: 'Efect WoW garantat! Par lung si matasos, senzational. Traieste intens fiecare secunda si bucura-te de calitatea monofilament a acestui produs',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-lighted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/marusha_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/marusha_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/marusha_mono_3.jpg'
        ]
      }
      ,
      {
        id: 394,
        title: 'Peruca par lung monofilament Affair',
        desc: 'Timpul trece repede dar un singur moment poate face diferenta. Poate sa iti schimbe viata. Traieste frumos si alege sa iti faci viata cat mai buna. De parul tau ne ocupam noi deocamdata.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Affair.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HairSociety_Affair_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Affair_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Affair_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Affair_1-ink.jpeg'
        ]
      }
      ,
      {
        id: 395,
        title: 'Peruca lux fibra termo Trinidad Luxury',
        desc: 'Peruca de lux realizata din monofilament integral manual. Peruca Raquerl Welch cu par din fibra termorezistenta',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_trinidad_luxury_1_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_trinidad_luxury_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_trinidad_luxury_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2022/02/ew_rw_trinidad_luxury_1_1.jpg'
        ]
      }
      ,
      {
        id: 396,
        title: 'Peruca par natura prime Illusion',
        desc: 'Cuvintele sunt de prisos. Imaginea vorbeste de la sine. Un par lung asa cum ai visat, matasos si des, o senzatie de mangaiere calda pe umeri, o bucurie fara margini. Te incanta? Peruca Illusion vine la un pret atractiv  azi, doar pentru tine.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/illusion.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/illusion_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/illusion_3.jpg'
        ]
      }
      ,
      {
        id: 397,
        title: 'Peruca naturala cu par lung blond mono tul frontal Xenita HI',
        desc: '100% par natural. 100% femeie! O femeie adevarata in sensul cuvantului. Femeia cu par lung, suava, delicata, senzuala. Si tu poti fii asa!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/xenita_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Xenita_Hi_8-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Xenita_Hi_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Xenita_Hi_6-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Xenita_Hi_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mono-extend-lace-wefted-hh.jpg'
        ]
      }
      ,
      {
        id: 398,
        title: 'Peruca din par natural remy lung monofilament nedetectabila Zora',
        desc: '„Traim doar pentru a descoperi frumosul. Restul e un fel de asteptare.” – Khalil Gibran. Minunate vorbe despre frumusete. Alege sa fii inspiratie pentru alti. Frumoasa la superlativ, femeie prin definitie',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Zora_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_ZoraLight_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_ZoraLight_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_ZoraLight_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_ZoraLight_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_ZoraLight_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mono-wefted-lacefront-hh.jpg'
        ]
      }
      ,
      {
        id: 399,
        title: 'Peruca de lux pentru femei Image',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/06/image_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/06/image_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/06/image_1.jpg'
        ]
      }
      ,
      {
        id: 400,
        title: 'Peruca naturala cu par lung Passion',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/passion_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/passion_2.jpg'
        ]
      }
      ,
      {
        id: 401,
        title: 'Peruca silicon din par natural remy Trinity Plus',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/softcopper-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/trinity_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/trinity_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/trinity_3_01.jpg'
        ]
      }
      ,
      {
        id: 402,
        title: 'Peruca remy naturala cu par lung Emotion',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/emotion_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/emotion_1_01.jpg'
        ]
      }
      ,
      {
        id: 403,
        title: 'Peruca cu silicon din  par natural remy Spectra Plus',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/spectra_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spectra_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/spectra_3.jpg'
        ]
      }
      ,
      {
        id: 404,
        title: 'Peruca naturala cu par lung Cascade',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cascade_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cascade_2_01.jpg'
        ]
      }
      ,
      {
        id: 405,
        title: 'Peruca din par lung natural remy Obsession',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/obsession_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/obsession_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/obsession_3_05.jpg'
        ]
      }
      ,
      {
        id: 406,
        title: 'Peruca par ondulat sintetic Shuffle',
        desc: 'Celebrele bucle lejere, cu un aspect relaxat si sexy, se mentin de multi ani in tendinte.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/shuffle_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/shuffle_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      }
      ,
      {
        id: 407,
        title: 'Peruca par lung sintetic lux Fortune',
        desc: 'Clipa, opreste-te! Esti minunata! Parul tau nu iti va mai rezerva surprize. Avem noi grija de asta. Accesoriul tau preferat te asteapta!',
        flavours: [],
        photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Fortune.jpg']
      }
      ,
      {
        id: 408,
        title: 'Peruca manuala nedetectabila Splendid',
        desc: 'Numele acestei peruci spune totul. Splendid. Indescriptibil feminin. Magie la cutie. Culoarea dorita, mereu aceeasi, fara surprize neplacute. Daca vrei poate fi a ta!',
        flavours: [],
        photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/splendid_01.jpg']
      }
      ,
      {
        id: 409,
        title: 'Peruca cu par lung pentru femei Interest',
        desc: '',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/06/interest_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/06/interest_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/06/interest_3.jpg'
        ]
      }
      
    ],
    "Peruci Femei Par Mediu":[
      {
        id: 410,
        title: 'Peruca cu par creponat  Wiki, Ellen Wille indisponibil',
        desc: 'Sezonul acesta parul creponat este in mare voga!. Fii super cool cu peruca par creponat lungime bob scurt. O coafura sic si volum exploziv in cateva minute, fara sa iti agresezi parul cu placa.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Wiki_1_2016.jpg']
      }
      ,
      {
        id: 411,
        title: 'Peruca bob din par sintetic, Change',
        desc: 'Doar cu un gest simplu poți sa aduci primăvara in viața ta. Alege o peruca ușoară, delicată, modernă si fă diferența! Senzația unei schimbări totale este la un click distanță!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ebony-black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/aubergine-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Change_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Change_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Change_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Change_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Change_1-ink.jpeg'
        ]
      }
      ,
      {
        id: 412,
        title: 'Peruca bob scurt par drept Vista, Ellen Wille',
        desc: 'Adauga un strop de emotie in viata! Fa schimbarea la care te gandeai de mult. Perucile sunt ideale pentru tine daca nu esti pregatita sa faci o schimbare permanenta. Esti nehotarata, nu ai  vrea sa renunti la parul tau frumos lung dar arati perfect cu tunsoare bob si anul asta se mai si poarta?! Ai ajuns unde trebuie. Avem un raspuns pentru tine: Peruca model Vista',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Vista_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Vista_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Vista_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Vista_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Vista_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Vista_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-crowm.jpg'
        ]
      }
      ,
      {
        id: 413,
        title: 'Peruca dama din par sintetic drept, bob, Charlotte, Ellen Wille',
        desc: 'Energizeaza-te! E mult mai usor sa o faci acum. Casa germana Ellen Wille – cel mai mare producator de peruci din Europa, a adus special pentru tine o colectie de peruci frumoase, usoare, accesibile si in trend. O peruca de calitate presupune o schimbare mica cu rezultate foarte mari. Incearca!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Charlotte_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Charlotte_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Charlotte_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Charlotte_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Charlotte_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-crowm.jpg'
        ]
      }
      ,
      {
        id: 414,
        title: 'Peruca par mediu ondulat Turn',
        desc: 'Aceasta peruca este peruca mea favorita din colectia Changes.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/candyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkcherry-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/turn_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/turn_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/turn_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/turn_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-carare.jpg'
        ]
      }
      ,
      {
        id: 415,
        title: 'Peruca păr ondulat Movie Star',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Moviestar_8-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Moviestar_9-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Moviestar_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Moviestar_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Moviestar_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Moviestar_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monocrown-wefted-tulle.jpg'
        ]
      }
      ,
      {
        id: 416,
        title: 'Peruca bob pentru femei din par sintetic, monofilament, nedetectabil, Java, Ellen Wille',
        desc: 'Simte-te alta persoana, o femeie in moda, fresh, lipsita de complexe si inhibitii! O peruca moderna poate fi un aliat in lupta ta cu anii. Nu uita ca un nou anotimp incepe doar cu tine!',  
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamon-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platin-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Java_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Java_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Java_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Java_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Java_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Java_1-ink.jpeg'
        ]
      }
      ,
      {
        id: 417,
        title: 'Peruca Perla cu par mediu usor ondulat',
        desc: 'Peruca Perla este un model din cea mai noua colectie de la Ellen Wille.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_9.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_8.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Perla_5.jpg'
        ]
      }
      ,
      {
        id: 418,
        title: 'Peruca ondulata Alive',
        desc: 'Perucile din par sintetic marca Ellen Wille sunt de cea mai buna calitate.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/alive_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alive_2_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alive_3_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/wefted-mono-fara-tul.jpg'
        ]
      }
      ,
      {
        id: 419,
        title: 'Planet Hi',
        desc: 'E atatr de usor sa fii mai frumoasa! O peruca este un accesoriu secret pentru fiecare zi. Cu el castigi timp iar timpul tau e foooarte scump.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/planet_hi_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/planet_hi_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/planet_hi_3.jpg'
        ]
      }
      ,
      {
        id: 420,
        title: 'Peruca afro Disco',
        desc: 'Un păr moale și o coafură îndrăzneață, culori radiante, moderne, toate într-o coafură retro care a revenit în modă.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/04/espresso_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/darkchocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/chocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/hotmocca_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/lightbernstein_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/pearlblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/champagne_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/aubergine_perucci20-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_10-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_13-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_11-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_10-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_9-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_8-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_6-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Disco_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/10/wefted-tul.jpg'
        ]
      }
      ,
      {
        id: 421,
        title: 'Peruca cu volum pentru femei Alexis',
        desc: 'Dublu click si aceasta peruca poate fi a ta. E atat de simplu sa fii frumoasa! O echipa intreaga de specialisti s-a gandit la asta. Convinge-te singura!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightespresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/alexis_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alexis_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/alexis_3.jpg'
        ]
      }
      ,
      {
        id: 422,
        title: 'Peruca ondulata Jade',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/jade_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jade_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jade_3.jpg'
        ]
      }
      ,
      {
        id: 423,
        title: 'Peruca dama din par sintetic bob Echo, Ellen Wille',
        desc: 'Esti nevoita sa faci o schimbare? Alege o peruca de calitate de la Ellen Wille. Colectia Perucci aduce laolalta o serie restransa de modele, care mai de care mai frumoase si moderne, realizate dupa ultima tehnologie germana. Cu ajutorul designerilor germani si a celor mai priceputi stilisti, am pus la punct o colectie unica unde calitatea vine la pret moderat.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Echo_1_2016.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Echo_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Echo_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Echo_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Echo_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-carare.jpg'
        ]
      }
      ,
      {
        id: 424,
        title: 'Beam',
        desc: 'Un look so posh, o aparitie fina pentru toate serile calde de vara.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-lighted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/beam_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/beam_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/beam_3.jpg'
        ]
      }
      ,
      {
        id: 425,
        title: 'Peruca par sintetic lungime medie mono part, tul frontal, United de la Ellen Wille',
        desc: 'Coafurile cu par mediu au revenit anul acesta in tendinte. Extrem de populare intrucat sunt usor de ingrijirt si se potrivesc la aproape orice tinuta, perucile cu par pana la umeri reprezinta un compromis perfect intre lungimea parului sin timpul destinat ingrijirii lui. Fii rezonabila, alege inteligent!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chillibrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranbrown-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_United_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_United_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_United_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_United_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_United_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_United_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      }
      ,
      {
        id: 426,
        title: 'Pam Hi Tec',
        desc: 'Aceasta peruca reprezinta o piesa de rezistenta a acestei colectii. O peruca cu par drept, in scari, fin si sanatos, reaalizata din fibre atent selectionate.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/pam_hi_tec_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pam_hi_tec_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pam_hi_tec_3.jpg'
        ]
      }
      ,
      {
        id: 427,
        title: 'Peruca par sintetic wavy Touch',
        desc: 'Anul acesta coafurile tip bob sunt din ce in e mai populare.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/candyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cherry-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/touch_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/touch_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/touch_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      }
      ,
      {
        id: 428,
        title: 'Peruca cu par ondulat Cat',
        desc: 'Daca iti doresti o imagine lipsita de griji alege aceasta peruca wavy. O dezordine usoara, culori stralucitoare, toate degaja pofta de viata si bucuria momentului',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cat_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cat_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cat_3.jpg'
        ]
      }
      ,
      {
        id: 429,
        title: 'Ocean',
        desc: 'Nu exista pret prea mare pentru starea ta de bine. Nu iti mai tortura parul  doar de dragul schimbarilor. Alege o peruca cu par usor ondulat, super moderna si conserva-ti atuurile naturale',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ocean_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ocean_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ocean_3.jpg'
        ]
      }
      ,
      {
        id: 430,
        title: 'Perucă monofilament bob cu breton, model Cri',
        desc: 'Peruca este confecționată din fibre sintetice rezintente la căldură, de ultimă generație. Se comportă extrem de bine în timp și permite stilizări multiple. Vă puteți bucura de această culoare pentru o perioadă lungă.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/04/espresso_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/darkchocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/chocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/lightbernstein_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/sandyblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/champagne_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/cherryred_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/pastelrose_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/rosewood_perucci20-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cri_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cri_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Cri_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/10/mono-wefted.jpg'
        ]
      }
      ,
      {
        id: 431,
        title: 'Peruca bob, din par sintetic, monofilament, nedetectabil, Sunset, Ellen Wille',
        desc: 'Peruca bob fârâ breton cu un look modern, care te scoate din anonimat.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/platinblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti_mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Sunset_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Sunset_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Sunset_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Sunset_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Sunset_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      }
      ,
      {
        id: 432,
        title: 'Peruca Ava mono Part',
        desc: 'Peruca Ava are o coafura in tendinte foarte potrivita atat pentru zilele calduroase de vara cat si pentru sezonul rece cand gulerele inalte de la haine pot electriza parul de la spate. Tunsa scurt la spate, peruca este un bob scurt, filat care incadreaza frumos o fata rotunda.  Bretonul lung pierdut imbraca orice chip, indiferent de varsta. Peruca are monofilament pe carare si frunte  nedetectabila. Perucile cu fruntea de matase sunt mai realiste in detaliu, nelasand sa se vada o montura grosolana. Parul pare sa creasca direct din scalp, exact ca in cazul parului natural.',      
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AvaMono_8.jpg'
        ]
      }
      ,
      {
        id: 433,
        title: 'Peruca par mediu French',
        desc: 'Denumirea acestei peruci spune totul: model peruca French.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/french_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/french_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/french_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-carare.jpg'
        ]
      }
      ,
      {
        id: 434,
        title: 'Blues',
        desc: 'Cum ar fi sa arati tot timpul ca ‘scoasa din cutie’? O coafura impecabila, culori vibrante si totul doar cu un singur click… Alege!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cosmoblack-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotespresso-mix.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkcherry-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mangored-rooted.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/blues.jpg'
        ]
      }
      ,
      {
        id: 435,
        title: 'Peruca medie ondulata Girl Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hothazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/girl_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/girl_mono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/girl_mono_4.jpg'
        ]
      }
      ,
      {
        id: 436,
        title: 'Peruca Dance',
        desc: 'Peruca sintetică nedetectabilă cu monofilament pe cărare Dance asigură un stil estival ce amintește de vacanța la mare.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/04/espresso_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/chocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/mocca_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/lightbernstein_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/sand_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/sandyblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/lightchampagne_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/hotchilli_perucci20-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Dance_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Dance_5-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Dance_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Dance_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Dance_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      }
      ,
      {
        id: 437,
        title: 'Peruca par mediu tunsa in scari Ferrara Mono Part',
        desc: 'Peruca tunsa in scari este disponibila pe comanda intr-o gama larga de culori vii si moderne.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_black.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_granatred.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_nutmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Ferrara_1.jpg'
        ]
      }
      ,
      {
        id: 438,
        title: 'Peruca bob Flirt',
        desc: 'O peruca bob foarte bine realizata, o coafura in trend, si gata, ai accesoriul perfect.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safranbrown-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/flirt_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flirt_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flirt_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      }
      ,
      {
        id: 439,
        title: 'Casino More',
        desc: 'Daca nu esti pregatita sa renunti la parul lung, poti sa faci tranzitia cu un par usor in scari cu breton amplu care sigur iti va flata trasaturile. Peruca par mediu Casino More este unul dintre cele mai bine cotate',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/casino_more_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/casino_more_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/casino_more_3.jpg'
        ]
      }
      ,
      {
        id: 440,
        title: 'Peruca creata Jamila Plus',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/jamila_plus_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jamila_plus_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jamila_plus_4.jpg'
        ]
      }
      ,
      {
        id: 441,
        title: 'Limit',
        desc: 'Brandul Ellen Wille pune pret in mod special pe calitatea modelelor propuse. Se gandeste la confortul si la frumusetea ta. Tu de ce nu ai face-o?! Peruca par mediu Limit iti va veni bine cu siguranta',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/limit_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/limit_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/limit_3.jpg'
        ]
      }
      ,
      {
        id: 442,
        title: 'Peruca creata Lola more',
        desc: 'Parul lung de mult visat il poti avea acum. Surprinde-ti partenerul cu noua ta achizitie si fii cat mai sexy.Nu uita ca fetele buine ajung in rai iar fetele rele ajung unde vor….',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotcognac-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lola_more_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lola_more_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lola_more_1.jpg'
        ]
      }
      ,
      {
        id: 443,
        title: 'Peruca cu par drept, mediu, tuns in scari, mono part, tul frontal  Atlantic Mono',
        desc: 'RaQUEL Welch a fost si va ramane un model de frumusete. Ea a ales sa isi asocieze numele doar cu “frumusetea la superlativ”. Colectia ei de peruci este cunoscuta in toata lumea si apreciata de profesionistii din domeniu. In poza este prezentat modelul de peruca pe culoarea Bernstein rooted. Radacinile usor inchise sunt o tusa moderna ce confera naturalete. Parul incadreaza frumos fata, mangaie usor decolteul si sublimeaza o tinuta tanara. Peruca este confectionata din monofilament partial pe carare si frunte discreta. Nu te grabi, alege cu atentie! Daca timpul este dusmanul tau, da o fuga pana la magazin sa te convingi.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/flame-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/atlantic_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-carare.jpg'
        ]
      }
      ,
      {
        id: 444,
        title: 'Peruca pentru femei cu par cret, pana la umeri, Hawaii',
        desc: 'O perucă fashion rămâne trucul tău secret în lupta cu capriciile vremii si trendurile momentului. Bucle ascultătoare, o cascadă de păr moale va garanta o prezență electrizantă. Nu vrei să treci neobservată? Alege modelul Hawaii – peruca cu par ondulat si vei arata de milioane!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/hawaii.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/WEFTED-LACE-FRONT.jpg'
        ]
      }
      ,
      {
        id: 445,
        title: 'Peruca nedetectabila pentru femei, par sintetic, Lake Mono',
        desc: 'Fă din orice apariție o ocazie! Alege să strălucești! Niciodată nu te-au deranjat privirile indiscrete.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkcherry-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lake_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lake_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lake_mono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/LACE-WEFTED-MONO-PART.jpg'
        ]
      }
      ,
      {
        id: 446,
        title: 'Peruca creata Onda Mono part',
        desc: 'Un alt model care a cucerit deja doamnele este peruca carliontata Onda Mono. Cu o baza de monofilament partial pe carare prin care se vede efectiv parul cum creste direct din piele – efect  extrem de realist, aceasta peruca propune un aspect tineresc si proaspat.  Peruca are tul frontal transparent si este foarte discreta. Culorile sunt din noua paleta cromatica 2021. Alege sa fii in moda! Uita de parul trist si fara viata! Perucile sunt accesoriile anului!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_creamblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_nutmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_platinblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_plumred.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_8.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_OndaMono_1.jpg'
        ]
      }
      ,
      {
        id: 447,
        title: 'Peruca femei cu par drept, tuns in scari Michigan',
        desc: '– Tunsoarea in scări revine in forță in 2020. Fii o divă, trăiește din plin si nu te vei pierde niciodată in mulțime!.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown_mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/michigan_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/michigan_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/EXTENDED-LACE-MONOPART-WEFTED.jpg'
        ]
      }
      ,
      {
        id: 448,
        title: 'Lucky Hi',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucky_hi_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucky_hi_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lucky_hi_3.jpg'
        ]
      }
      ,
      {
        id: 449,
        title: 'Peruca bob fara breton Narano mono part',
        desc: 'Peruca fara breton Naranao de Ellen Wille prin Hermax e conceputa pentru a vă oferi un spor fabulos de încredere. Gama de peruci Modixx este renumită pentru calitatea, confortul și stilul luxos, cu colecția sa modernă de stiluri și culori.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolatemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivorygrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightchampagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_middlegrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Narano_8.jpg'
        ]
      }
      ,
      {
        id: 450,
        title: 'Young Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/young_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/young_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/young_mono_3.jpg'
        ]
      }
      ,
      {
        id: 451,
        title: 'Peruca par ondulat eleganta Grace',
        desc: 'Fii tu cea care dicteaza moda! Creeaza curentul si lasa-te urmata de cei care te admira. Diponibila in combinatii de culori extrem de vii sau foarte naturale, cu efecte spectaculoase, aceasta peruca propune coafura wavy, stilul de pe buzele tuturor',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/grace_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/grace_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/grace_3.jpg'
        ]
      }
      ,
      {
        id: 452,
        title: 'Peruca bob cu breton Monza',
        desc: 'Perucile bob de la binecunoscutul brand Ellen Wille, un brand dedicat inspirării femeilor din întreaga lume cu colecțiile lor de peruci și stilurile lor moderne. Colecția de peruci Modixx prezintă un stil fără efort pentru un aspect de zi cu zi, toate perucile noastre emanând un aspect frumos natural prin tonuri gradate și culori înrădăcinate care reproduc aspectul creșterii naturale  a părului. Răsfoiți prin opțiunile minunate de culoare, stiluri și lungimi pentru a găsi o perucă care vi se potrivește. Deoarece aceste peruci au fost create pentru purtarea zilnică, întreaga colecție este minunat de ușoară și confortabilă. Indiferent dacă doriți un model in scari  sau poate ceva mai lung, Colecția Modixx are câteva opțiuni de stil minunate. Peruca bob Monza are un breton care poate sa ascunda anumite imperfectiuni si unde mai pui ca bretonul este si in moda. Peruca bob lung din par sintetic prezinta pentru prima oara niste culori ombre extrem de reusite. Vino sa le vezi in magazin. Peruca are tul frontal din dantela pentru perosanele care vor sa isi lase si fruntea libera. Indrazniti, doamnelor, indrazniti!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darksand.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_rubyred.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Monza_6.jpg'
        ]
      }
      ,
      {
        id: 453,
        title: 'Peruca bob fara breton Elite',
        desc: 'Rafinament si eleganta -macestea sunt doar doua cuvinte care vin in minte cand te gandesti la peruca Elite. Personalitatea si charisma radiaza din interior si parul frumos se asaza ca o aura. Esti frumoasa ca un inger',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_3.jpg'
        ]
      }
      ,
      {
        id: 454,
        title: 'Peruca bob scurt pentru femei din par sintetic, Cape Mono',
        desc: 'Daca nu ai curajul sa te tunzi mai scurt dar vrei o frizura cu care sa mergi la sigur, un aspect ingrijit, cu linii curate care incadreaza fata ca o rama de tablou, peruca bob este o solutie. Feminina si rafinata, coafura bob avantajeaza un profil sobru, elegant. Sandyblonde rooted este culoarea aleasa in fotografie, dar',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/cape_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cape_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-carare.jpg'
        ]
      }
      ,
      {
        id: 455,
        title: 'Peruca bob lung Drive',
        desc: 'Peruca Drive propune un stil extrem de actual. Fibra sintetică de înaltă calitate rezistentă termic permite realizarea unui aspect cât mai natural. Peruca nedetectabilă disoune de un tul frontal transparent și monofilament pe cărare, aceste caracteristici permițându-i posesoarei un aspect realist. culoarea din imagine este pearlblonde',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/04/espresso_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/darkchocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/chocolate_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/bernstein_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/sandyblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/pearlblonde_perucci20-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/04/cherryred_perucci20-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_8-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_9-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_7-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_6-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_4-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_3-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_2-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Drive_1-ink.jpeg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      }
      ,
      {
        id: 456,
        title: 'Elite small',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_3_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_1_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/elite_2_01.jpg'
        ]
      }
      ,
      {
        id: 457,
        title: 'Peruca bob scurt din fibra termorezistenta Aletta',
        desc: 'Acest tip de peruca are in crestet sau o zonă de extrem de naturală, unde fiecare fir de păr a fost țesut individual.  Peruca arată deosebit de realist în zonele în care pielea este vizibilă, deoarece parul perucii pare să creasca direct din propriul scalp, la fel ca in cazul creșterii naturale a părului. Restul acestei peruci constă în mare parte dintr-o montura standard în care părul este cusut pe benzi fine de bumbac. Aceste trese se adaptează foarte bine la forma capului și permit aerului să circule.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernstein.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_rubyred.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_03.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_04.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_05.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_06.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_07.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_08.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_09.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_10.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/11/ew_modixx_AlettaMono_11.jpg'
        ]
      }
      ,
      {
        id: 458,
        title: 'Peruca par mediu usor ondulat Stella Mono Part',
        desc: 'Peruca Stella este o peruca usoara, bine ventilata si disponibila in culori foarte moderne. Fibra termorezistenta din care este aceasta peruca confectionata are efect de memorie si pastreaza coafura pana la restilizare. Este posibila coafarea cu ajutorul unor scule profesionale cu care puteti controla temperatura pana in 130 grade. Se face prima oara un test pe o portiune mica de la ceafa pentru a va deprinde cu procedura. Prin monofilasmentul situat pe carare se vede exact pielea capului creind un efect realist. Peruca este nedetectabila deoarece are un tul frontal in dreptul bretonului permitand coafarea in lateral sau chiar pe spate.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_rosewood.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_silkgrey.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_sw.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_10.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_09.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_08.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_07.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_06.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_05.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_04.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_03.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_Stella_01.jpg'
        ]
      }
      ,
      {
        id: 459,
        title: 'Sue Mono',
        desc: 'O tunsoare bob, o atitudine french, o imagine impecabila. Peruca bob este deja o alegere sigura, clasica. Calitatea o recomanda',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/sue_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sue_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sue_mono_4.jpg'
        ]
      }
      ,
      {
        id: 460,
        title: 'Smoke Hi Mono',
        desc: 'O investitie buna in imaginea ta si in sanatate! Bucura-te de imaginea din oglinda! Peruca model carre este nelipsita din garderoba multor vedete. Daca ei au ales-o, fa-o si tu!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/black-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke_hi_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke_hi_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/smoke_hi_mono_3.jpg'
        ]
      }
      ,
      {
        id: 461,
        title: 'Peruca par mediu fara breton Nola Mono part',
        desc: 'Peruca cu par ondulat Nola are monofilament pe carrare si tul frontal transparent in fata pentru a fi cat mai realista.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_redvino.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_05.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_07.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_06.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_05.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_04.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_03.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_02.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_NolaMono_01.jpg'
        ]
      }
      ,
      {
        id: 462,
        title: 'Talia Mono',
        desc: '',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/blackcherry-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-tipped-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hotflame-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/talia_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/talia_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/talia_mono_3.jpg'
        ]
      }
      ,
      {
        id: 463,
        title: 'Peruca par mediu ondulat pentru femei Maine Mono',
        desc: 'Ultimul model lansat de Raquel Welch este de departe preferatul meu. Bucle ușoare, coiffe-decoiffe spun francezii, efect wavy. Fibra folosita permite coafarea la cald cu ajutorul unei dispozitiv performant, profesional, la temperaturi nu foarte înalte. Frunte discretă, vedere la cărare, păr in degradeuri ce copiază extrem de bine naturalul, PERUCA ASTA ESTE O PIESĂ. Plus 10 nuanțe in care poate fi comandată. Ce mai, ai 10 motive sa suni!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lavender-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/rosewood-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/maine_mono_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/maine_mono_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      }
      ,
      {
        id: 464,
        title: 'Peruca bob texturat Fresh',
        desc: 'Esential feminina si lejera, aceasta peruca bob va ramane piesa forte din tinuta ta de azi. Modelul acesta de peruca iti subliniaza trasaturile armonioase si iti da multa liberate in miscare',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkauburn-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/fresh_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fresh_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/fresh_3.jpg'
        ]
      }
      ,
      {
        id: 465,
        title: 'Peruca bob pentru femei, monofilament, Idaho Mono',
        desc: 'Peruca Idaho Mono de la Raquel Welch este o perucă bob scurtă. Datorită monofilamentului pe cărare, unde firele de păr sunt inserate manual, fir cu fir, aceasta perucă imită creșterea naturală a părului și se îmbină cu tonurile pielii oferindu-ți cel mai natural aspect. Coafura perucii Idaho Mono este elegantă și clasică, dar totuși la moda. Această perucă scurtă este croită, aranjată si gata de purtat. Pentru a o personaliza, puteți fila ușor bretonul sau îl puteți purta după ureche. Idaho Mono este disponibil în zece mixuri de culori, printre care si o frumoasă nuanță grizonată!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/idaho_mono.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
        ]
      }
      ,
      ,
{
  id: 466,
  title: 'Beach Mono',
  desc: 'Un model in care se imbina fericit inovatia si inspiratia. Arati si te simti bine! Peruca Beach Mono face moda',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/cognac-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown_mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/beach_mono_1_01.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/beach_mono_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/beach_mono_3.jpg'
  ]
}
,
{
  id: 467,
  title: 'Peruca in scari Flash Mono',
  desc: 'Frumusetea ii da putere unei femei. ‘:E extraordinar ce poate sa faca: e o expresie a sinelui; sistem de comunicare; forma de protest; semnaleaza identitatea si determina schimbare.”',     
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg'
  ],
  photos: []
}
,
{
  id: 468,
  title: 'Peruca femei confectionata manual  Dolce Soft',
  desc: 'O peruca confectionata manual este ca o mangaiere delicata pe crestet. Odata aplicata pe cap, pielea capului se vede prin montura semitransparenta creind un efect realist. Peruca are tul frontal extins oferind discretia de care vrei sa te bucuri cand alegi o peruca scumpa.',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolatemulti.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_creamblonde.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_DolceSoft_3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_DolceSoft_4.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_DolceSoft_3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_DolceSoft_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_DolceSoft_1.jpg'
  ]
}
,
{
  id: 469,
  title: 'Peruca sintetica delicata Cesana Soft',
  desc: 'Peruca delicata confectionata manual model Cesana Soft. Exact asa cum ii spune si numele peruca are o captuseala moale, delicata potrivita pentru persoanele care au o sesibilitate cescuta la nivelul pielii. Textura moale atinge placut scalpul si nu irita intrucat fiecare fir de par este cusut manual. Peruca are o frunte discreta inbtrucat parul din dreptul bretonului este cusut pe o banda semi transparenta. Perucile realizate manual sunt bine ventilate, au o densitate naturala si lasa pielea scapului sa respire. Sunt ideale pe timpul verii sau pentru doamnele care au bufeuri. Peruca vine mulat pe pielea capului. Parul este realizat din fibre sintetice extrem de realist confectionate care isi pastreaza coafura si culoarea pe toata durata intrebuintarii. Daca se respecta intocmai instrucvtiunile, este facuta sa reziste in forma aleasa cca un an de purtare zilnica.',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ashgrey.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_beigemulti.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chestnut.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_ivoryblonde.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_pepperbrown.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_5.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_7.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_6.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_5.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_4.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_CesanaSoft_1.jpg'
  ]
}
,
{
  id: 470,
  title: 'Talent Mono',
  desc: '',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-tipped-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-mix-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/talent_mono_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/talent_mono_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/talent_mono_4.jpg'
  ]
}
,
{
  id: 471,
  title: 'Peruca ondulata cu chica Gina Mono',
  desc: '',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/gina_mono_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/gina_mono_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/gina_mono_3.jpg'
  ]
}
,
{
  id: 472,
  title: 'Queen Comfort',
  desc: 'Frumusetea se vede cu ochiul liber dar calitatea se verifica in timp! Noi avem din toate. Alege o peruca de calitate Premium si ve intelege exact la ce ma refer',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandmulti-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/safran-rooted-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/queen_comfort_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/queen_comfort_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/queen_comfort_4.jpg'
  ]
}
,
{
  id: 473,
  title: 'Shine Comfort',
  desc: '',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/shine_comfort_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/shine_comfort_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/shine_comfort_3.jpg'
  ]
}
,
{
  id: 474,
  title: 'Peruca par scurt nedetectabila mono Bo Mono',
  desc: 'Peruca cu par scurt cu breton – o coafura simpla cu care mergi la sigur. Peruca tip monofilament asigura o ventilatie suficienta pentru a te bucura chiar si in zilele calde de vara. Incearca!',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/hotespresso-mix.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/bo_mono_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bo_mono_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bo_mono_3.jpg'
  ]
}
,
{
  id: 475,
  title: 'Tempo 100 Deluxe',
  desc: 'Coco Chanel spunea ‘Pentru a fi de neinlocuit, trebuie sa fii intotdeauna diferita.’ Draga mea, nu e niciodata prea tarziu sa te faci indispensabila. Daca iti place aceasta peruca, cumpar-o. Simplu',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-tipped-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/coffeebrown-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/pearl-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/white-mix-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/tempo_100_deluxe_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/tempo_100_deluxe_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/tempo_100_deluxe_3.jpg'
  ]
}
,
{
  id: 476,
  title: 'Peruca sintetica dama cu par mediu usor ondulat, California Mono',
  desc: 'Un best seller al colectiei Raquel Welch acum reinterpretat. Suvitele de par usor dezordonate, un look intentionat adolescentin amintesc de o vara fierbinte, mare, briza si cate si mai cate….Alege peruca California Mono si nu vei mai avea nevoie de filtre! Vei avea un selfie perfect. Si nu uita: Cand nu esti sigura, alege BLOND!',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/hotmocca-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/cinnamonbrown_mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/california_mono.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/california_mono_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/california_mono_3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
  ]
}
,
{
  id: 477,
  title: 'Tempo Large Deluxe',
  desc: '',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-tipped-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/tempo_100_deluxe_2_01.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/tempo_100_deluxe_1_01.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/tempo_100_deluxe_3_01.jpg'
  ]
}
,
{
  id: 478,
  title: 'Peruca sintetica de lux Alexis Deluxe',
  desc: 'Esential feminina, aceasta peruca propune o tinuta lejera . Incarca-te de energia acestui anotimp si radiaza! Acum este timpul sa fii frumoasa! Aceasta peruca este lucrata in tehnologia revolutionara a monofilamentului pe baza de tul.',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/smoke-mix-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/alexis_deluxe_3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/alexis_deluxe_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/alexis_deluxe_2.jpg'
  ]
}
,
{
  id: 479,
  title: 'Peruca Raquel Welch, par sintetic, pana la umeri, monofilament Empire Mono',
  desc: 'O coafura ampla, volum natural, nimic excesiv, toate astea recomanda acesta peruca oricarei doamne care se vrea in centrul atentiei. Cine nu si-ar dori asemena coafura?! Din pacate, nu suntem toate niste norocoase. O coafura asa reusita se obtine cu greu fara un ajutor profesionist si chiar si atunci, coafura NU REZISTA. Pentru cineva care este pregatit pentru o relatie “pe termen lung” achizitionarea unei peruci de calitate in stilul preferat este o optiune sigura. De ce sa risti?! Suna, comanda si, daca nu iti place, o faci retur. Ai 15 zile sa te razgandesti. Dar ce sa nu iti placa? Peruca monofilament total, peruca nedetectabila, culori suvitate permanente, tinuta perfecta a buclelor, sincer, cand stau sa ma gandesc asa, nici nu pare atat de scumpa. Cine spune ca tu nu meriti?',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/empire_mono_01.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mono-extend-lace-wefted-hh.jpg'
  ]
}
,
{
  id: 480,
  title: 'Peruca de lux pentru femei East Luxury',
  desc: 'V- am spus ca la Raquel Welch luxul este numitor comun? Perucile de calitate trebuie sa fie usoare, bine ventilate, discrete, cu par moale, matasos. Iar perucile de lux corespund celor mai exigente criterii. Perucile au cateva zeci de grame, sunt usoare si delicate. Nu trebuie sa pierdeti din vedere ca sunt un accesoriu si se comporta in timp direct proportional cu timpul, banii si atentia cu care v-ati ocupat de ea. Trebuie sa aveti asteptari realiste insa. Nu rezista unei torsiuni extreme, sunt ca o bijuterie, un décor de cap. Este suficient spre exemplificare sa va ganditi la un ciorap fin de matase. Unul dintre multiplele avantaje ale monturii tip monofilament este ca se poate schimba cararea in fuctie de obisnuinta. Este dificil de inteles cat suntem tributari unui gest reflex de tipul alegerea cararii dar aceasta nu este deloc intamplatoare. Bretonul pe o parte (sau alta) poate ajuta la mascarea unei mici imperfectiuni (cicatrice) sau poate favoriza profilul nostru bun (stangul, de regula). Astfel, oprtunitatile de aranjare sunt multiple, putem alege chiar sa ne aranjam frumos parul dupa ureche sau cu ajutorul unei cleme, sa dam parul pe spate. Peruca bob al carei par se opreste exact la nivelul maxilarului este extrem de actuala, lasand gatul expus. In modul acesta, se evita frictiunile repetate cu textile (guler etc) parul nu se mai electrizeaza, eliminand riscul degradarii ei pe lungime atunci cand parul se mai incurca la spate. Perucile de lux fac priza perfecta cu scalpul, este indicat sa fie aplicate direct pe piele avand o marime mai mica decat perucile fashion. Culoarea din poza: sandyblonde.',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/hazelnut-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/silver-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg'
  ],
  photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/east_luxury.jpg']
}
,
{
  id: 481,
  title: 'Peruca de lux, brand international, Broadway Luxury',
  desc: 'Oricare dintre perucile Raquel Welch este sinonima cu Calitatea Premium dar acest model vizeaza excelenta. Cautai un accesoriu pentru o ocazie? Investeste in parul tau, este singurul accesoriul prezent in orice tinuta. Peruca monofilament realizata 100% manual are in dreptul fruntii un tul perfect transparent in care firele de par sunt inserate fir cu fir pentru un look cat mai aproape de original. Parul moale, fin, este tuns in scari, culoarea din imagine este lightcaramel rooted dar daca nu este culoarea ta, nu dispera, o gama de culori naturaledar si nuante din cele mai vibrante te asteapta doar la un click distanta.',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightcaramel-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg'
  ],
  photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/broadway_luxury.jpg']
}
,
{
  id: 482,
  title: 'Peruca Piemonte Super',
  desc: 'Peruca de lux confectionata manual. Pentru a afla detalii despre acest tip de finisaj, accesati sectiunea dedicata din Sfaturi. Peruca bob cu breton lung franjurat montat inn tul frontal din dantela.',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bahamabeige.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolatemulti.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkgrey.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_lightgrey.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_4.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_7.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_6.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_PiemonteSuper_5.jpg'
  ]
}
,
{
  id: 483,
  title: 'Wave Deluxe',
  desc: 'Adevarata frumusete vine din interior. Asorteaza aceasta peruca unui caracter puternic si unui suflet frumos. Acum ai o tinuta princiara chiar!',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/tobacco-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/wave_deluxe_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/wave_deluxe_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/wave_deluxe_4.jpg'
  ]
}
,
{
  id: 484,
  title: 'Amy Small Deluxe',
  desc: '',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darknougat-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightpearl_mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/amy_small_deluxe_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/amy_small_deluxe_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/amy_small_deluxe_3.jpg'
  ]
}
,
{
  id: 485,
  title: 'Peruca lux London Super',
  desc: 'Aceasta peruca d elux este o opera de arta. fiecare fir de par este cusut manual pe o baza semitransparenta in asa fel incat pielea capului sa poata respira corespunzator si peruca sa aiba un aspect cat mai realist. Peruca are densitatea corect distribuita similar unei persoane fara probleme. Parul aluneca matasos in culori vibrante, Ingrijit cum se cuvine , puteti sa va bucurati mult timp de aceasta peruca.',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_bernsteinmulti.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_champagne.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_chocolate.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkbrown.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_darkchocolate.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_saharabeige.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_sand.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_teakbrown.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_toffeebrown.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_LondonSuper_4.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_LondonSuper_5.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_LondonSuper_4.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_LondonSuper_3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_LondonSuper_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/12/ew_modixx_LondonSuper_1.jpg'
  ]
}
,
{
  id: 486,
  title: 'Peruca lux Bloom',
  desc: 'O coafura ampla, in scari, versatila. O peruca de 5*. Vino sa te convingi de calitatea Premium direct in magazin. Aceasta colectie intruneste toate conditiile: Fibra calitate lux, baza de monofilament in care parul este inserat fir cu fir, manual, pentru un tangaj firesc, parul se misca natural. Peruca este nedetectabila.',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkpearl-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Bloom_01.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Bloom_1-ink.jpeg',
    'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Bloom_4-ink.jpeg',
    'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Bloom_3-ink.jpeg'
  ]
}
,
{
  id: 487,
  title: 'Peruca nedetectabila de lux Icone',
  desc: 'Un moment de placere facut sa dureze cat vrei tu! Un bob delicat, culori naturale, peruca monofilament realizata integral manual, toate astea doar pentru tine. Pentru ca tu meriti ce e mai bun!',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/ginger-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lighthoney-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Icone_01.jpg',
    'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Icone_1-ink.jpeg',
    'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Icone_2-ink.jpeg',
    'https://www.hermax.ro/wp-content/uploads/2021/01/ew_HairSociety_Icone_4-ink.jpeg'
  ]
}
,
{
  id: 488,
  title: 'Peruca de lux Star',
  desc: 'O linie atent elaborata pentru un aspect cat mai natural. Confortabil. E timpul sa vezi diferenta. Calitatea germana nu este un mit. Nimeni nu va sti ca porti o peruca, pana si tu vei uita.',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darksand-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/snow-mix-640x640.jpg'
  ],
  photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Star.jpg']
}
,
{
  id: 489,
  title: 'Peruca par natural prime Adore',
  desc: 'Creatorii de modele de la Ellen Wille, cel mai mare producator de peruci din Europa, s-au gandit sa adune intr-un singur produs avantajele parului Prime, rezistent termic, si parului uman. Parul Prime simuleaza perefct naturalul, iar combinatia perfecta ofera satisfactie deplina cumparatorului sofisticat: volum si tinuta, suplete si rezistenta. Peruca Adore – sigur o vei iubi!',      
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/nutbrown-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/caramel-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/hotchilli-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/silverblonde-rooted-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/adore.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/adore_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/adore_3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/adore_4.jpg'
  ]
}
,
{
  id: 490,
  title: 'Peruca din par natural remy pentru femei, nedetectabila, monofilament model bob Yara',
  desc: 'Un bob lung, par natural, eleganta fara compromisuri, ideal pentru orice femeie care se vrea frumoasa si cocheta. Bucura-te de calitatea produselor Ellen Wille. Nu uita ca „fericirea este sanatatea sufletului.”',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chestnut-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/ew_Perucci_Yara_1_2016.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Yara_3-ink.jpeg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Yara_2-ink.jpeg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Yara_1-ink.jpeg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perucci2020_Yara_4-ink.jpeg',
    'https://www.hermax.ro/wp-content/uploads/2020/10/mono-wefted.jpg'
  ]
}
,
{
  id: 491,
  title: 'Peruca dama par natural mix Promise',
  desc: '',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/quotsand-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/03/pearl.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/06/promise_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/06/promise_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/06/promise_3.jpg'
  ]
}
,
{
  id: 492,
  title: 'Peruca dama bob Catch',
  desc: '',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/nutbrown-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/salt-pepper-mix-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/06/catch_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/06/catch_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/06/catch_3.jpg'
  ]
}
,
{
  id: 493,
  title: 'Peruca naturala dama model bob Prestige',
  desc: '',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/winered-rooted-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/prestige_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/prestige_2.jpg'
  ]
}
,
{
  id: 494,
  title: 'Peruca par natural prime Spirit',
  desc: 'un bob jucaus, plete care zburda in jurul tau si te fac sa zambesti. Ai frumusetea la purtator! O peruca este nelipsita din garderoba unei femei! Nu cauta scuze sa te simti frumoasa si atragatoare',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/auburn-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/spirit.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/spirit_3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/spirit_4.jpg'
  ]
}
,
{
  id: 495,
  title: 'Peruca par natural prime Mood',
  desc: 'O peruca din par natural calitate premium intr-un mix sofisticat cu par Prime rezistent termic. Rezultatul: O peruca bob cu par matasos si fin, o coafura rezistenta, cu tinuta, ideala pentru un brunch intr-o zi frumoasa de duminica. Bucura-te alaturi de familie de lucrurile simple. Pentru ca noi ne gandim la toate.',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/mood.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mood_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mood_3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mood_4.jpg'
  ]
}
,
{
  id: 496,
  title: 'Appeal',
  desc: '',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/winered-rooted-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/appeal_1_01.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/appeal_2_01.jpg'
  ]
}
,
{
  id: 497,
  title: 'Peruca naturala par mediu Inspire',
  desc: '',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/hotchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightbernstein-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/inspire_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/inspire_2.jpg'
  ]
}
,
{
  id: 498,
  title: 'Peruca naturala bob Wish',
  desc: '',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/pastelblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/pearlgrey-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sand-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/safranred-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/stonegrey-mix-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/wish_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/wish_2.jpg'
  ]
}
,
{
  id: 499,
  title: 'Peruca par remy european Sole',
  desc: 'Peruca din par uman remy de origine europeana, executata pe o baza din monofilament, cu tul invizibil in zona fruntii.',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/03/sole-6-3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/03/sole-6-2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/03/sole-6-1.jpg'
  ]
}
,
{
  id: 500,
  title: 'Peruca par natural european Juvia',
  desc: 'Peruca din par natural european calitate remy, confectionata pe o baza din monofilament, cu bordura de dantela transparenta- front lace.',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/mocca-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/03/juvia-4-3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/03/juvia-4-3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/03/juvia-4-1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/03/juvia-4-2.jpg'
  ]
}
,
{
  id: 501,
  title: 'Peruca par natural european Cosmo',
  desc: 'Aceasta peruca de lux reprezinta un varf de gama al tehnologiei germane, realizata integral manual, pe o baza de monofilament in nuanta pielii. Banda de tul invizibil completeaza efectul realist. Baza moale si fina, cu parul inserat 100% manual ofera un confort de neegalat. Parul natural remy este de provenienta europeana, fiind fin si matasos.',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/lightchampagne-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-mix-1-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/03/cosmo-2-1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/03/cosmo-2-1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/03/cosmo-2-2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/03/cosmo-2-3.jpg'
  ]
}
,
{
  id: 502,
  title: 'Peruca de lux din par natural Delicate Plus',
  desc: '',
  flavours: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/espresso-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/darkchocolate-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/chocolate-mix-1-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/bernstein-mix-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/champagne-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/nougat-rooted-640x640.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/sandyblonde-rooted-640x640.jpg'
  ],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/delicate_1_01.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/delicate_2_01.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/delicate_3_01.jpg'
  ]
}
,
{
  id: 503,
  title: 'Peruca sintetica bob scurt Game',
  desc: 'Daca te identifici cu stilul, reusesti sa fii in moda si sa fii mereu surprinzatoare.',
  flavours: [],
  photos: [
    'https://www.hermax.ro/wp-content/uploads/2020/02/game_1.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/game_2.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/game_3.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/game_4.jpg',
    'https://www.hermax.ro/wp-content/uploads/2020/02/monofilament-partial-carare-cu-extended-tule.jpg'
  ]
}
,
{
  id: 504,
  title: 'Peruca par mediu mono Charisma',
  desc: 'Nu ai curaj sa comanzi online? Te asteptam in magazin. Aceasta colectie este destinata cunoscatorilor. Daca nu ai purtat niciodata o peruca de lux, cere detalii online sau telefonic. Model peruca de lux Charisma face parte din Colectia Hairsociety. Toate produsele din aceasta colectie sunt calificate ca dispozitive medicale. Peruca este foarte usoara, foarte bine ventilata.',
  flavours: [],
  photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/charisma.jpg']
}
,
{
  id: 505,
  title: 'Peruca bob de lux Shape',
  desc: 'O tunosoare bob franjurat, nu foarte lung, exact cum se poarta, fix pana la linia maxilarului. Mereu ai vrut sa vezi cum iti sta dar nu ai avut curaj sa te tunzi. Acum ai ocazia. Vino! Alege.',
  flavours: [],
  photos: ['https://www.hermax.ro/wp-content/uploads/2020/02/ew_HS_Shape_01.jpg']
}
,

    ],
  },
  "Barbati":{
    "Peruci Barbati":[
      {
        id: 506,
        title: 'Peruca pentru barbati Brad',
        desc: 'Alege sa fii barbatul de care ea s-a indragostit. Da inapoi timpul si accepta provocarea. Tu fa doar primul pas: Comanda. Noi ne ocupam de restul.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m2s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m3s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m7s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m14s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m34s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m36s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m38s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m39s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m44s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m46s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m51s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m56s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/georg_5stars_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/georg_5stars_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/georg_5stars_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/georg_5stars_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/georg_5stars_1.jpg'
        ]
      }
      ,
      {
        id: 507,
        title: 'Peruci sintetice barbati nedetectabile Dave',
        desc: 'Esti un tip practic, sportiv si nu vrei sa te complici? Ok. Iti spun direct: Cu peruca barbateasca Dave mergi la sigur. Sunt femeie si stiu. Nu lasa timpul sa hotarasca pentru tine. Revino in joc!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m3s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m7s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m18s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m34s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m39s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m44s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m56s-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hairformance_dave_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hairformance_dave_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hairformance_dave_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hairformance_dave_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hairformance_dave_4.jpg'
        ]
      }
      ,
      {
        id: 508,
        title: 'Peruca barbateasca monofilament Bradford 2.0',
        desc: 'Fa din prezenta ta o APARITIE. Elegant, sobru, rafinat, exact cum vrei tu. Esti tot ce vor ele. Fa alegerea corecta. O peruca discreta, nedetectabila poate fi o solutie pentru tine. Acum',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m3s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m7s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m14s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m36s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m38s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m51s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m56s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/bradford_2_0.jpg'
        ]
      }
      ,
      {
        id: 509,
        title: 'Peruca monofilament nedetectabila barbati Justin 2.0',
        desc: 'O tinuta bussines sau casual, mereu in tendinte. Indiferent de situatie, cu aceasta peruca se va vedea ca nu iti este indiferent cum arati. Si crede-ma, nici tu nu vei lasa pe nimeni indiferent…a',  flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m3s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m7s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m14s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m36s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m38s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m44s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m46s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m51s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m56s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/justin_2_0.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/justin_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/justin_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/justin_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/justin_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/justin_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/justin_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/justin_7.jpg'
        ]
      }
      ,
      {
        id: 510,
        title: 'Peruca sintetica barbati George 5-Stars 2.0',
        desc: 'Un stil in care traditia conservatoare si invovatia in ultima tehnologie garanteaza succesul acestui model. O tinuta ingrijita va fi mereu apreciata si cautata. Accepta propunerea noastra. Cumpara o peruca barbateasca ultima tehnologie Germania. Nu uita: frumusetea nu este un atu eminamente feminin.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m3s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m7s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m14s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m34s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m36s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m38s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m44s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m46s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m51s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m56s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/george_5_stars_2_0.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/georg_5stars_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/georg_5stars_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/georg_5stars_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/georg_5stars_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/georg_5stars_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/georg_5stars_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/georg_5stars_7.jpg'
        ]
      }
      ,
      {
        id: 511,
        title: 'Peruca manuala barbati par sintetic Roger 5-Stars 2.0',
        desc: 'Vrei o aura de succes?! Invata sa investesti in imaginea ta si restul va veni de la sine. O frizura fara repros, calitate 5 *. O peruca pentru barbatii care au de ales dar nu se multumesc cu putin.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m3s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m7s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m14s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m34s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m36s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m44s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m46s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m51s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m56s-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/roger_5_stars_2_0.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/roger_5stars_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/roger_5stars_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/roger_5stars_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/roger_5stars_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/roger_5stars_7.jpg'
        ]
      }
      ,
      {
        id: 512,
        title: 'Peruca sintetica barbati mono nedetectabil Steven Sport',
        desc: 'Daca frumusetea este un obiectiv, incearca sa il atingi. Echipa de designeri de la Ellen Wille iti propun idealul masculin. Totul la cutie. Chiar maine poate fi a ta! Perucile barbatesti sunt dintotdeauna purtate si apreciate. Ce mai astepti?',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m3s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m7s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m14s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m34s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m36s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m38s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m44s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m46s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m51s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m56s-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/steven_sport_2_0.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/steven_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/steven_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/steven_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/steven_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/steven_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/steven_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/steven_7.jpg'
        ]
      }
      ,
      {
        id: 513,
        title: 'Peruca barbati monofilament Gary',
        desc: 'O atitudine masculina, plina de incredere degaja siguranta. Alege sa fii centrul atentiei. O peruca barbateasca comoda si discreta. Peruca monofilament foarte bine ventilata si usoara, exxtrem de realista. Prin monoifilament se vede pielea si firele de par par sa creasca direct din radacina.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m3s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m7s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m14s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m34s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m36s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m38s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m44s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m51s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m56s-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hairformance_gary_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hairformance_gary_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hairformance_gary_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hairformance_gary_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hairformance_gary_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hairformance_gary_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hairformance_gary_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hairformance_gary_2.jpg'
        ]
      }
      ,
      {
        id: 514,
        title: 'Peruci sintetic barbati Johnny',
        desc: 'un look de neegalat. Un aspect de ultima ora, masculin si sexy. Te mai indoiesti? De 50 de ani germanii de la Ellen Wille pun la punct acest brand. Exista o reteta de succes. Ei o impartasesc cu tine acum. Fii parte din poveste. Fii personajul principal.',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/johnny.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/johnny_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/johnny_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/johnny_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/johnny_4.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/johnny_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/johnny_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/johnny_7.jpg'
        ]
      }
      ,
      {
        id: 515,
        title: 'Peruca barbati par sintetic nedetectabila Tom Sport',
        desc: 'Acest model este perfect. E gandit pana in cele mai mici amanunte de o echipa intreaga. De barbati. Ei cunosc cel mai bine ce vrei tu, ce asteptari ai. Vorbiti aceeasi limba. Daca ai de ales si iti permiti, mergi la sigur. Comanda peruca barbateasca Tom Sport!',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/tom_sport.jpg'
        ]
      }
      ,
      {
        id: 516,
        title: 'Peruca par sintetic barbati Oliver',
        desc: 'O peruca barbateasca cum rar ai mai vazut. Sau poate niciodata. Nu incerca sa o compari cu ce ai mai vazut tu prin oras sau la TV. O coafura naturala, lejera te va convinge din prima.Da o fuga pana la noi la magazin. Ce zici? Am avut dreptate?!',
        flavours: [],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/oliver.jpg'
        ]
      }
      ,
      {
        id: 517,
        title: 'Peruca barbati nedetectabila Eric 2.0',
        desc: 'Colectia de peruci barbatesti Hairformance cucereste Europa! Fii si tu in trend. Efecct WOW! Schimbarile in bine nu si le permite orice. Fii tu cel de care vorbesc ei cu invidie.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m2s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m3s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m7s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m14s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m34s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m36s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m38s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m44s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m46s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m51s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m56s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/eric_2_0.jpg'
        ]
      }
    ],
    "Mese Barbati":[
      {
        id: 518,
        title: 'Jason Toupet',
        desc: 'Esti un barbat elegant si rafinat? Ai succes in afaceri dar te simti totusi nesigur?! Natura ti-a jucat o farsa si nu arati la fel de bine cum te simti. Noi avem solutia. O mesa de par pentru barbati e gandita special sa rezolve acest aspect. O mesa de volum garanteaza o tinuta profund masculina ce debordeaza de personalitate.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m2s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m3s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m7s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m14s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m36s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m38s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m44s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m51s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m56-60s-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m56s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/toupet_jason.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jason_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jason_3.jpg'
        ]
      }
      ,
      {
        id: 519,
        title: 'Mesa par barbati Jay',
        desc: 'O mesa de par asigura tinutei un aspect tineresc. Te preocupa imaginea ta? Daca esti la noi pe site nu e intamplator. Stii bine ca nu exista coincidente. Nu mai pierde timpul si comanda acum. Produsul poate fi usor personalizat ulterior.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m3s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m34s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m38s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m44s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m51s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m56-60s-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/toupet_jay.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jay_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jay_3.jpg'
        ]
      }
      ,
      {
        id: 520,
        title: 'Mesa de par barbateasca monofilament Jim',
        desc: 'Barbat cu chelie?! Nici o tragedie! Chiar daca cochetezi cu ideea sa iti faci odata implant, deocamdata nu te simti pregatit. Ai o solutie la indemana. Accesorizeaza-ti acum tinuta cu o mesa barbateasca pentru a avea un look activ, tineresc, dinamic. Mesa e pret-a-porter deci practic, odata atasata, poti sa iesi chiar diseara in oras, dar daca mai vrei sa faci unele retusuri, nici o problema. Produsul poate fi modificat si integrat perfect in parul tau natural. Mergi la hairstylistul tau, el cunoaste cel mai bine ce iti doresti.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m2s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m7s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m38s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m39s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m44s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m51s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m56s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/toupet_jim.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jim_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/jim_3.jpg'
        ]
      }
      ,
      {
        id: 521,
        title: 'Mesa de par sintetic pentru barbati Hanno',
        desc: 'Mesa barbateasca din par natural de cea mai buna calitate recomandata celor mai pretentiosi dintre barbati. Arul este inserat fir cu fir creind un aspect natural. Nu trebuie sa suferi pentru frumusete. Nemtii s-au gandit la toate. Ai un produs 2 in 1: Frumos si foarte comod de purtat.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m3s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m7s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m36s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/pepper-mix-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/toupet_hanno.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hanno_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/hanno_3.jpg'
        ]
      }
      ,
      {
        id: 522,
        title: 'Mesa par natural remy barbati Henry',
        desc: 'Echipa de stylisti de la brandul Ellen Wille au ales acest model din o suta. Este o frizura curata, ingrijita, masculina cu care mergi la sigur. Usor de purtat, va fi o bucurie pentru tine sa te uiti in oglinda.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/m2s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m5s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m7s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m17s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m34s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m39s-1-640x640.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/m44s-1-640x640.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/toupet_henry.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/henry_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/henry_3.jpg'
        ]
      }
    ],
    "Proteze Capilzare Barbati":[
      {
        id: 523,
        title: 'Calota pentru barbati Perma Class',
        desc: 'Proteza capilara Perma Class vine ca o solutie salvatoare pentru multi barbati care se confrunta cu problema calvitiei. Ea se aplica usor, confom instructiunilor si, daca se respecta tot protocolul si se folosesc produsele recomandate, rezultatul este imediat. Uita de costurile mari ale implantului de par sau de multele precautii, apeleaza la un specialist pentru a -ti aplica aceasta proteza capilara de par si in mai putin de 1 ora, vei avea parul de mult visat! Nimic mai simplu!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_17.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1720.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1810.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_19.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b10.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b20.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b50-1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b80.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_2020.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_210.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_22.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_250.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_310.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_320.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_350.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_410.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_520.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_550.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_620.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_740.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perma_class_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/perma_fine_montur.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/before_picture.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perma_class_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perma_class_1.jpg'
        ]
      }
      ,
      {
        id: 524,
        title: 'Calota barbati Perma Ray',
        desc: 'Colectia destinata exclusiv barbatilor a integrat acum, pentru prima oara in Romania, o proteza capilara. Aceasta vine ca o optiune suplimentara destinata satisfacerii nevoilor unui segment din ce in ce mai mare, alopecia androgenica in toate fazele ei, fiind extrem de frecvent intalnita in randul populatie masculine . Desi unanim acceptata de cutumele locale, inclusiv standardele de frumusete s-au modificat corespunzator, resemnarea in fata naturii nu mai este singura atitudine. Implantul de par implica foarte multe timp, riscuri si costuri mari fara ca efectul lui sa fie garantat.Acest sistem de protezare insa a fost perfectionat pentru a rezolva toate aceste aspecte:timp, pret, reusita. Manopera de aplicare dureaza cca 1 ora. Sistemul de par odata aplicat rezista aproximativ 2-4 luni, in functie de stilul de viata personal. Aceasta manevra necesita interventia unui profesionist. Cereti stilistului dvs detalii despre protezele capilare. Exista o gama completa de produse de fixare, curatare adecvate acestui sistem de protezare. Accesati link https://youtu.be/a0JkiKe_4ug',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_17.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1720.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1810.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_19.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b10.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b20.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b50-1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b80.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_2020.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_210.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_22.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_250.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_310.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_320.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_350.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_410.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_520.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_550.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_620.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_740.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perma_ray_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/perma_air_montur_01.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perma_ray_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perma_ray_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/before_picture.jpg'
        ]
      }
      ,
      {
        id: 525,
        title: 'Sistem de par Perma Solid',
        desc: 'Ultima fita in materie de par! Mai rapid decat implantul! Fara efecte secundare!',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_17.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1720.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1810.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_19.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b10.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b20.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b50-1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b80.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_2020.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_210.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_22.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_250.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_310.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_320.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_350.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_410.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_520.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_550.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_620.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_740.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perma_solid_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/perma_plus_montur.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perma_solid_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perma_solid_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_perma_solid_cap.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/before_picture-1.jpg'
        ]
      }
      ,
      {
        id: 526,
        title: 'Sistem de par Perma Tec',
        desc: 'Proteza capilara destinata personaelor cu alopecie androgenica reprezinta un raspuns favorabil obtinut in urma multor ani de cercetari in domeniu. Este o varianta mult mai accesibila decat implantul, mai rapida si sansele de reusita sunt garantate. Implica interventia unui profesionist sau macar a unei terte persoane cu experienta de acest fel. Toate componentele protezei, inclusiv gama de adezivi, corespund standardelor din domeniu. Produsul este unul inovator. Termenul estimat de purtare variaza de la 2 la 3 luni in functie de stilul de viata personal. Cereti detalii la salonul pe care il frecventati de obicei si interesati-va daca exista personal instruit in acest sens. Daca nu exista disponibilitate, va putem noi furniza o lista de parteneri.',
        flavours: [
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_17.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1720.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1810.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_19.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b10.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b20.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b50-1.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_1b80.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_2020.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_210.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_22.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_250.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_3.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_310.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_320.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_350.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_410.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_5.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_520.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_550.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_6.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_620.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_7.jpg',
          'https://www.hermax.ro/wp-content/uploads/2021/02/Permafit_740.jpg'
        ],
        photos: [
          'https://www.hermax.ro/wp-content/uploads/2020/02/perma_tec.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/perma_tec_montur.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/perma_tec_2.jpg',
          'https://www.hermax.ro/wp-content/uploads/2020/02/ew_hfm_perma_plus_and_tec_before.jpg'
        ]
      }
    ]
  }
}

function App() {
  return (
    <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route path="/categorii" element={<CategoryPage/>}/>
          <Route path="/produs" element={<ProductPage/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/politica-cookies" element={<PoliticaCookies/>}/>
        </Routes>
    </Router>
  );
}

export default App;
