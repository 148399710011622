import React,{useState,useEffect} from 'react';
import Footer from './Footer';
import NavBar from './NavBar';
import {
    BrowserRouter as Router,
    useLocation
} from "react-router-dom";
import {produse} from '../App';
import '../CSS/style_11.css';

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ProductPage = ()=>{
    useEffect(()=>{
        document.title = "Peruci Naturale"
    })
    
    let query = useQuery();
    const [productState,setProductState] = useState({
        exists: false,
        data:{
            title: '',
            desc: '',
            photos: [],
            flavours: []
        }
    })

    const [currentPhoto, setCurrentPhoto] = useState(null)
    const [currentFlavour, setCurrentFlavour] = useState(null);
   
    useEffect(()=>{
        extractProduct(produse)
    },[])
    useEffect(()=>{
        console.log("productState update:",productState)
    },[productState])

    let extractProduct = (source)=>{
        
        if(query.get('id') === null || query.get('id') === undefined) {
            return null
        }
         
        let found = null
        Object.keys(source).forEach((categoriiMari)=>{
            Object.keys(source[categoriiMari]).forEach((categorie)=>{
                source[categoriiMari][categorie].forEach((produs)=>{
                    if(query.get('id') === produs.id.toString())
                    {
                        found = {...produs}
                    }
                })
            })
        })
        
        if(found !== null)
        {
            setProductState({
                exists: true,
                data:{...found}
            })
            setCurrentPhoto(found.photos[0])
            setCurrentFlavour(found.flavours[0])
        }
        return found
    }
    return(
        <>
        <NavBar/>
        {
            productState.exists === false ?
            <p>Produsul nu exista</p>
            :
            <>
        <div class="section-11">
            <div class="section-11-header">
                <span>Produs</span>
            </div>
            <div class="section-11-content">
                <div class="section-11-content-left">
                    <div  class="section-11-content-left-image">
                        <img id="section-11-product-zoom" src={currentPhoto}/>
                    </div>
                    <div class="section-11-content-left-values">
                        {
                            productState.data.photos.map((photoEl)=>{
                                return(
                                    <div 
                                        class="section-11-content-left-values-elem"
                                        onClick={()=>{
                                            setCurrentPhoto(photoEl)
                                        }}
                                        >
                                            <img src={photoEl}/>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                </div>
                <div class="section-11-content-right">
                    <div class="section-11-content-right-top">
                        <div class="section-11-content-right-top-title">
                            <span>{productState.data.title}</span>
                        </div>
                        {/* <div class="section-11-content-right-top-headline">
                            <span>1.929,00 lei</span>
                        </div> */}
                        <div class="section-11-content-right-top-text">
                        <span>{productState.data.desc}</span>
                        </div>
                    </div>
                    <div class="section-11-content-right-bot">
                        <img 
                            src={currentFlavour}
                            style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover'
                            }}
                            />
                        <div 
                            id="section-11-product-flavor"
                            class="section-11-content-right-bot-absolute" 
                            >
                                
                                {
                                    productState.data.flavours.map((flavourEl)=>{
                                        return(
                                            <div 
                                                onClick={()=>{
                                                    setCurrentFlavour(flavourEl)
                                                }}
                                                class="section-11-content-right-bot-absolute-item"
                                            >
                                            <img src={flavourEl}/>
                                            </div>
                                        )
                                    })
                                }
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </>
        }
        <Footer/>
        </>
    )
}
export default ProductPage;
