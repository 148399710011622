import React, {useState, useEffect} from 'react';
import Footer from "./Footer"
import NavBar from "./NavBar"
import '../CSS/style_1.css';
import '../CSS/style_2.css';
import '../CSS/style_3.css';
import '../CSS/style_4.css';
import '../CSS/style_5.css';
import '../CSS/style_6.css';
import '../CSS/style_7.css';
import '../CSS/style_8.css';
import '../CSS/style_9.css';
import '../CSS/style_10.css';
import '../CSS/style_11.css';
import '../CSS/style_12.css';
import '../CSS/style_13.css';

import PhoneIcon from '../media/icons/phone-call.png';
import ClockIcon from '../media/icons/clock.png';
import EmailIcon from '../media/icons/email.png';
import LocationIcon from '../media/icons/maps-and-flags.png';

const Contact = ()=>{
    useEffect(()=>{
        document.title = "Peruci Naturale - Contact"
    })
    return(
       <>
        <NavBar/>
        <div class="section-7">
            <div class="section-7-header">
                <span>Contact</span>
            </div>
        <div class="section-7-content">
            <div class="section-7-content-left">
                <div class="section-7-content-left-core">
                    <div class="section-7-content-left-core-top">
                        <div class="section-7-content-left-core-top-elem">
                            <div class="section-7-content-left-core-top-elem-header">
                                <img src={PhoneIcon} />
                                <span>Numar telefon</span>
                            </div>
                            <div class="section-7-content-left-core-top-elem-content">
                                <p>Telefon: +40 799 263 734</p>
                            </div>
                        </div>
                        {/* <div class="section-7-content-left-core-top-elem">
                            <div class="section-7-content-left-core-top-elem-header">
                                <img src={ClockIcon}/>
                                <span>Working hours</span>
                            </div>
                            <div class="section-7-content-left-core-top-elem-content">
                                <p>Luni-Vineri: 08:00 - 18:00</p>
                            </div>
                        </div> */}

                        <div class="section-7-content-left-core-top-elem">
                            <div class="section-7-content-left-core-top-elem-header">
                                <img src={EmailIcon}/>
                                <span>Email</span>
                            </div>
                            <div class="section-7-content-left-core-top-elem-content">
                                <p>perucinaturale@gmail.com</p>
                            </div>
                        </div>

                        <div class="section-7-content-left-core-top-elem">
                            <div class="section-7-content-left-core-top-elem-header">
                                <img src={LocationIcon}/>
                                <span>Locatie</span>
                            </div>
                            <div class="section-7-content-left-core-top-elem-content">
                                <p>Strada Lascar Catargi, nr.42 Iasi</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="section-7-content-right">
                <iframe 
                style={{
                    filter: "invert(90%)",
                    border: '0',
                    width: '100%',
                    height: '100%'
                }}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1356.0610723660584!2d27.576263796997196!3d47.17504505404062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cafb6209a53a17%3A0x6ecd90f67999f93!2sStrada%20Lasc%C4%83r%20Catargi%2042%2C%20Ia%C8%99i%20700107!5e0!3m2!1sro!2sro!4v1665494377397!5m2!1sro!2sro"
                    width="100%" 
                    height="100%" 
                    allowFullScreen="" 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade"
                >
                </iframe>
            </div>
            
        </div>
        </div>
        <Footer/>
       </>
    )
}   
export default Contact