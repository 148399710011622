import React,{useState, useEffect} from 'react';

import {useQuery} from './ProductPage';
import {produse} from '../App';
import NavBar from './NavBar';
import Footer from './Footer';
import '../CSS/style_8.css';
import '../CSS/style_10.css';
import '../CSS/style_13.css';
import {useNavigate} from 'react-router-dom';

import BgIntroBarbati from '../media/bg/bg-intro-barbati.jpg';
import BgIntroFemei from '../media/bg/bg-intro-femei.jpg';

const CategoryPage = ()=>{
    
    useEffect(()=>{
        document.title = "Peruci Naturale"
    })
    
    const history =  useNavigate();
    let redirectToProduct =(customId)=>{
        console.log("redirect to product:", customId)

       history(`/produs?id=${customId}`);
    }

    let query = useQuery();

    useEffect(()=>{
        extractCateggory()
    },[])

    let [categorieMare, setCategorieMare] = useState(null);
    let [categorieMica, setCategorieMica] = useState(null);
    let [categoriiMici, setCategoriiMici] = useState([]);

    useEffect(()=>{
        console.log("final:",categorieMica,categorieMare,categoriiMici)
    },[categorieMare,categorieMica,categoriiMici])
    

    let extractCateggory = ()=>{
        console.log("category")

        if(query.get('categorie-id') === null || query.get('id') === undefined) {
            return null
        }

        let queryToKey = (querySource)=>{
            return querySource.replaceAll('-',' ')
        }
        // Search if category exists in config
        let categorieMareFound = null;
        let categorieMicaFound = null;

        Object.keys(produse).forEach((categorieMare)=>{
            
            if(categorieMicaFound !== null) return
            categorieMareFound = categorieMare

            Object.keys(produse[categorieMare]).forEach((categorie)=>{
                if(categorie === queryToKey(query.get('categorie-id')))
                    categorieMicaFound = categorie
                    return 
            })
        })
        
        if(categorieMicaFound === null)
        {
            //categoria mica nu exists, selecteaza by default toate
            if(categorieMareFound === null)
            {
                //pagina invalida
            }
            else 
            {
                setCategoriiMici([...Object.keys(produse[categorieMareFound])])
                setCategorieMica([...Object.keys(produse[categorieMareFound])][0])
                setCategorieMare(categorieMareFound)
            }
        }
        else 
        {
            setCategorieMare(categorieMareFound)
            setCategorieMica(categorieMicaFound)
            setCategoriiMici([...Object.keys(produse[categorieMareFound])])
        }
    }

    const redirectToCategorieMica = (categorieMicaParam)=>{
        history(`/categorii?categorie-id=${categorieMicaParam.replaceAll(' ','-')}`)
        setCategorieMica(categorieMicaParam)
    }
    return(
        <>
            <NavBar/>
            <div class="section-8">
                <div class="section-8-left">
                    <span>Colectii - {categorieMare} </span>
                </div>
                <div class="section-8-right">
                    <img src={categorieMare==='Femei'?BgIntroFemei:BgIntroBarbati} />
                </div>
            </div>
            <div class="section-13" id="section-13-categories-nodes">
                <div class="section-13-header">
                <span>Selecteaza o categorie:</span>
                </div>
                <div class="section-13-content">
                {
                    categoriiMici.map((categorieMicaEl)=>{
                        console.log("cevaaaa:",categorieMicaEl)
                        return(
                            <div 
                                onClick={()=>{redirectToCategorieMica(categorieMicaEl)}}
                                class={categorieMicaEl === categorieMica ? "section-13-item section-13-item-selected":"section-13-item"}>
                                <span>{categorieMicaEl}</span>
                            </div>  
                        )
                    })
                }
                </div>
            </div>

            <div class="section-10" id="section-10-root">
                {
                    (categorieMare !== null && categorieMica !== null)?
                    <>
                        {
                            produse[categorieMare][categorieMica].map((itemProduct)=>{
                                return(
                                    <div
                                        onClick={()=>redirectToProduct(itemProduct.id)} 
                                        class="section-5-items-elem">
                                        <div class="section-5-items-elem-image">
                                        <img src={itemProduct.photos[0]} />
                                        </div>
                                        <div class="section-5-items-elem-details">
                                            <div class="section-5-items-elem-details-title">
                                                <span>{itemProduct.title}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                    :
                    null
                }
            </div>
            <Footer/>
        </>
    )
}
export default CategoryPage;
