import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import '../CSS/politicaCookies.css';

const PoliticaCookies = ()=>{
    React.useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    return (
        <>
            <NavBar/>
            <div className="politica-cookies-container">
                <h1>Politica Cookies</h1>
                <p>Document de informare a utilizatorilor despre prezența cookie-urilor pe site-ul web <strong>www.perucinaturalero.ro</strong> Informațiile prezentate în continuare au scopul de a aduce la cunoștința utilizatorului mai multe detalii despre plasarea, utilizarea și administrarea cookie-urilor utilizate de site-ul <strong>www.perucinaturalero.ro</strong>.</p>
                    <br/>
                    <p><strong>Ce este un cookie?</strong></p>
                    <p>Un cookie este un fișier text care conține informații descărcate pe dispozitivul dumneavoastră atunci când vizitați (pentru prima dată) un site web. Acel cookie este trimis înapoi la fiecare vizită ulterioară către site-ul web de origine sau către alt site web care îl recunoaște. Cookie-urile sunt utile deoarece permit unui site web să recunoască un dispozitiv și vă oferă o experiență mai eficientă și personalizată.</p>
                    <br/>
                    <p><strong>Ce NU este un cookie?</strong></p>
                    <p>Cookie-urile NU sunt viruși! Ele folosesc formate tip plain text. Nu sunt alcătuite din bucăți de cod, așa că nu pot fi executate și nici nu pot auto-rula. În consecință, nu se pot duplica sau replica pe alte rețele pentru a se rula sau replica din nou.</p>
                    <br/>
                    <p><strong>Ce tipuri de cookie sunt utilizate?</strong></p>
                    <p><strong>www.perucinaturalero.ro</strong> folosește pe site-urile sale atât cookie-uri proprietare cât și cookie-uri terțe.</p>
                    <p>Cookie-urile proprietare sunt cookie-uri utilizate de <strong>www.perucinaturalero.ro</strong> când vizitați unul dintre site-urile noastre web și sunt de următoarele tipuri: tehnice, de sesiune, persistente și funcționale:</p>
                    <ul>
                    <li>Cookie-urile tehnice sunt esențiale pentru funcționarea corectă a site-ului web. Aceste cookie-uri vă permit să navigați între diferite secțiuni ale site-ului web și să utilizați funcții specifice.</li>
                    <li>Cookie-urile de sesiune sunt cookie-uri temporare care vă permit să navigați simplu și rapid pe site.</li>
                    <li>Cookie-urile persistente sau ‘cookie-urile de urmărire’ durează mai multe sesiuni și rămân în browser o perioadă de timp după încheierea sesiunii (dacă nu le ștergeți).</li>
                    <li>Cookie-urile funcționale monitorizează funcționarea corectă a site-ului web și îi permit acestuia să țină minte opțiunile dumneavoastră (de ex., limba, numele de utilizator sau regiunea). Acestea oferă funcții îmbunătățite și personale, care vă ajută să nu mai selectați opțiunile de fiecare dată când vizitați site-ul web.</li>
                    </ul>
                    <p>Cookie-urile terțe sunt module cookie care sunt utilizate de<strong> www.perucinaturalero.ro</strong> când vizitați site-ul nostru și sunt de următoarele tipuri:</p>
                    <ul>
                    <li>Cookie-urile de performanță (Google Analytics): colectează informații anonime și centralizate despre comportamentul dumneavoastră online (tipul de browser, adresa IP, sistemul de operare utilizat, numele domeniului site-ului pe care l-ați vizitat și momentul părăsirii site-ului, data și ora la care ați vizitat un site web, etc.) în scopuri statistice și pentru generarea profilurilor vizitatorilor.</li>
                    <li>Cookie-urile pe care la folosim noi nu colectează date care să vă dezvăluie identitatea și, de aceea, nu vă putem identifica cu ajutorul lor. Site-ul nostru web poate conține link-uri către alte site-uri web care nu sunt deținute/administrate de <strong>www.perucinaturalero.ro</strong> (conținut terț, linkuri și plug-in-uri).<strong>www.perucinaturalero.ro</strong> nu își asumă responsabilitatea pentru practicile de confidențialitate aplicate de aceste site-uri web.</li>
                    </ul>
                    <p><strong>Cum respingeți cookie-urile?</strong></p>
                    <p>Puteți să vă retrageți în orice moment acordul ștergând cookie-urile din browser.</p>
                    <p>Aceste setări se găsesc de obicei în meniul ‘opțiuni’ sau ‘preferințe’ din browser. Pentru a înțelege aceste setări, ar putea fi utile următoarele linkuri (sau accesați opțiunea ‘Ajutor’ din <a href="https://www.mosaicresidence.ro/utilizare-cookies/" target="_blank" rel="noopener noreferrer">https://www.mosaicresidence.ro/utilizare-cookies/</a>browser pentru mai multe detalii):</p>
                    <ul>
                    <li><a href="https://support.microsoft.com/en-us/help/196955" target="_blank" rel="noopener noreferrer">Setările pentru cookie din Internet Explorer</a></li>
                    <li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies" target="_blank" rel="noopener noreferrer">Setările pentru cookie din Firefox</a></li>
                    <li><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noopener noreferrer">Setările pentru cookie din Chrome</a></li>
                    <li><a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener noreferrer">Setările pentru cookie din Safari</a></li>
                    </ul>
            </div>
            <Footer />
        </>
    )
}
export default PoliticaCookies
