import React,{useEffect,useState} from 'react';
import '../CSS/style_1.css';
import '../CSS/style_2.css';
import { Link } from "react-router-dom";

let ceva = null;

const NavBar = ()=>{

    console.log("here")

    useEffect(()=>{
        console.log("use effect")

        let menuIcon = document.querySelector('.menuIcon');
        let nav = document.querySelector('.overlay-menu');

        menuIcon.addEventListener('click', () => {
            console.log("click")

            if (nav.style.transform != 'translateX(0%)') {
                console.log("case 1")
                nav.style.transform = 'translateX(0%)';
                nav.style.transition = 'transform 0.2s ease-out';
            } else { 
                console.log("case 2")
                nav.style.transform = 'translateX(-100%)';
                nav.style.transition = 'transform 0.2s ease-out';
            }
        });

        // Toggle Menu Icon ========================================
        let toggleIcon = document.querySelector('.menuIcon');

        toggleIcon.addEventListener('click', () => {
            if (toggleIcon.className != 'menuIcon toggle') {
                toggleIcon.className += ' toggle';
            } else {
                toggleIcon.className = 'menuIcon';
            }
        });

    },[])

    const[menuOpen, setMenuOpen] = useState(false);

    const MobileMenuToggle = ()=>{
        console.log("mobile mneu toggle")
        setMenuOpen((prev)=>{
            if(prev === false)
            {
                // open menu
                window.scrollTo(0,0);
            }
            return !prev
        })
    }
    return(
        <>
            <nav id="navbar" class="">
           <div class="nav-wrapper">
               <div class="logo" >
                   <div class="logo-text" >
                   <a href="/">Peruci Naturale</a>
                   </div>
                   
               </div>
       
               <ul id="menu">
                   <Link to="/"><li><a>Acasa</a></li></Link>
                   <li><a href="/categorii?categorie-id=Peruci-Coafuri-Clasice">Femei</a></li>
                <li><a href="/categorii?categorie-id=Mese-Barbati">Barbati</a></li>
                   <Link to="/contact"><li><a>Contact</a></li></Link>
               </ul>
               </div>
           </nav>
           <div 
                class={menuOpen ? 'menuIcon toggle':'menuIcon'}
                onClick={MobileMenuToggle}
                >
               <span class="icon icon-bars"></span>
               <span class="icon icon-bars overlay"></span>
           </div>
           <div 
                class="overlay-menu"
                style={{
                    transform: menuOpen ? 'translateX(0%)':'translateX(-100%)',
                    transition: 'transform 0.2s ease-out'
                }}
                >
                <ul id="menu">
                <Link to="/"><li><a>Acasa</a></li></Link>
                <li><a href="/categorii?categorie-id=Peruci-Coafuri-Clasice">Femei</a></li>
                <li><a href="/categorii?categorie-id=Mese-Barbati">Barbati</a></li>
                <Link to="/contact"><li><a>Contact</a></li></Link>
               </ul>
           </div>
        </>
    )
}

export default NavBar;
