import React, { useEffect } from 'react';
import '../CSS/style_2.css';
import '../CSS/style_4.css';
import '../CSS/style_5.css';
import '../CSS/homepagelocation.css';

import NavBar from './NavBar';
import IntroSectionBg from '../media/bg/section-2.jpg'
import ShopIcon from '../media/icons/store.png';
import MapIcon from '../media/icons/maps-and-flags.png'
import Footer from './Footer';
import{useNavigate  } from 'react-router-dom';

import {produse} from '../App';

const Home = ()=>{

    useEffect(()=>{
        document.title = "Peruci Naturale"
    })

    const history =  useNavigate();
    let redirectToProduct =(customId)=>{
        console.log("redirect to product:", customId)

       history(`/produs?id=${customId}`);
    }
    return(
        <>
            <NavBar/>
            <div id="section-2-id" class="section-2-container">
                <div class="section-2-container-top">
                    <span>Mai frumoasa in fiecare zi</span>
                </div>
                <div class="section-2-container-center">
                    <span>Reda frumusetea chipului tau cu perucile noastre naturale</span>
                </div>
                <div class="custom-button">
                <a href="#section-4-id">
                            <button>
                            DESCOPERA
                        
                        </button>
                        </a>

                </div>
            </div>

            <div class="homepage-location-section">
                <div class="homepage-location-section-icon">
                    <img src={ShopIcon} alt="shop"/>
                </div>
                <div class="homepage-location-section-text">
                    <span>Magazin situat vis a vis de <b>Institutul Oncologic Iasi</b></span>
                </div>
                <div class="homepage-location-section-icon">
                    <img src={MapIcon} alt="map"/>
                </div>
            </div>

            <div class="section-4" id="section-4-id">
                <div class="section-4-left">
                    <div class="section-4-left-center">
                        <div class="section-4-left-center-headline">
                        <span>Design la cele mai inalte standarde</span>
                        </div>
                        <div class="section-4-left-center-title">
                        <span>Alege Originalul! Par natural prin excelenta</span>
                        </div>
                        <div class="section-4-left-center-desc">
                        <span>Magazine specializate in peruci pentru femei, barbati si copii, calote volum si proteze de par si  produse de intretinere pentru peruci Bucuresti, Cluj, Iasi </span>
                        </div>
                    </div>
                </div>
                <div class="section-4-right">
                    <img src={IntroSectionBg}/>
                </div>
            </div>
            <>
            {
                Object.keys(produse).map((categoriiMari)=>{
                    return Object.keys(produse[categoriiMari]).map((categorie)=>{
                        console.log("categorie:",categorie)
                        
                        return(
                            <div class="section-5" id="first-product-section">
                            <div class="section-5-title">
                              <div class="section-5-title-content">
                                <span>{categorie}</span>
                              </div>
                              <div class="section-5-title-link">
                                <a href={`/categorii?categorie-id=${categorie.replaceAll(" ","-")}`}>Vezi mai multe</a>
                              </div>
                              <div class="section-5-title-separator">
                              </div>
                            </div>
                            <div class="section-5-items">
                            {
                                produse[categoriiMari][categorie].slice(0,6).map((produs)=>{
                                    return(
                                        // <Link 
                                        //     style={{

                                        //     }}
                                        // to={`produs?id=${produs.id}`}>
                                            <div
                                                onClick={()=>redirectToProduct(produs.id)} 
                                                class="section-5-items-elem" >
                                                <div class="section-5-items-elem-image">
                                                <img src={produs.photos[0]} />
                                                </div>
                                                <div class="section-5-items-elem-details">
                                                    <div class="section-5-items-elem-details-title">
                                                        <span>{produs.title}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        // </Link>
                                       
                                    )  
                                })
                            }
                            </div>
                            </div>
                        )
                    })
                })
            }
            </>
            <Footer/>
        </>
    )
}
export default Home;
